import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { SRLWrapper } from "simple-react-lightbox";
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";
import QrCodeGenerator from "../scanner/qrCodeGenerator";

let guestName = "";
let kepada = "";
let sesi = "10:00 - 11:00";
let params = "";
const gbUrl = "https://sheet.best/api/sheets/eecb0773-f8f8-4829-b687-b33b7d7d79e3";
const gbKey = "16DKJcTmIfhvKpBTxRPXUunClsHSOLYr27gV50K7gzw0";

function VikihHanna() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useEffect(() => {
        if (!showGbMsg) requestGuestBook();
    })

    useState(() => {
        let search = window.location.search;
        params = new URLSearchParams(search);
        kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
        if (kepada != null) setShowQrCode(true);

        let sesiParam = params.get('q');
        if (sesiParam === "2") sesi = "11:00 - 12:00"
        else if (sesiParam === "3") sesi = "12:00 - 13:00"
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Vikih & Hanna</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="assets/clients/vikihhanna/images/groombride.jpg" />
                <link rel="apple-touch-icon" href="assets/clients/vikihhanna/images/groombride.jpg" />
                <link rel="stylesheet" href="assets/clients/vikihhanna/assets/css/main.css" />
                {/* <script src="assets/clients/vikihhanna/assets/js/jquery.min.js"></script> */}
                {/* <script src="assets/clients/vikihhanna/assets/js/browser.min.js"></script> */}
                {/* <script src="assets/clients/vikihhanna/assets/js/breakpoints.min.js"></script> */}
                {/* <script src="assets/clients/vikihhanna/assets/js/util.js"></script> */}
                {/* <script src="assets/clients/vikihhanna/assets/js/main.js"></script> */}

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" />
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <script src="/assets/clients/vikihhanna/assets/js/countdown.js" date="2021-07-25 08:00"></script>
                <script src="/assets/global/yt-no-repeat.js"></script>
                <script src="/assets/global/cursor.js"></script>
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="Yiruma, (이루마) - River Flows in You.mp3" />
            {/* <CovidModal addText="Tanpa makan di tempat (dine in), hanya diperbolehkan untuk dibawa pulang (take away)."/> */}
            <div className="cursor"></div>
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>Vikih & Hanna</h1>
                                    <p>{guestName}</p>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Undangan</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Lokasi</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Guestbook</button></li>
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                <h2 className="major">Kami yang Berbahagia</h2>
                                <div className="text-center">

                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing"   >Hanna Fauzia</h2>
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri ketiga dari<br /><strong>Bp. Ghozi & <br />Ibu Puji Handayani</strong> <br /><em>Sidayu, Gresik</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">Vikih Kurniawan</h2>
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra pertama dari<br /><strong>Bp. (alm) Joko Wahyudi & <br />Ibu Mu’anifah</strong> <br /><em>Kepanjen, Malang</em></p>
                                    <hr />
                                    <p className="prayer">Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.<br /><em>QS Ar-Rum:21</em></p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                <h2 className="major">Turut Mengundang</h2>
                                <div className="text-center">
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Akad</h1>
                                    <h3 className="">Minggu <br />25 / Juli / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/vikihhanna/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">08:00 - 09:00 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/vikihhanna/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Jl. Raya Lasem, RT 01/ RW 01, Ds. Lasem, Kec. Sidayu, Kab. Gresik<br />(Kediaman Mempelai Wanita)</h4>
                                    <br />
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <a href="https://www.google.com/calendar/render?action=TEMPLATE&text=Akad+Nikah+Vikih+dan+Hanna&details=Detail+selengkapnya+bisa+dilihat+di+https%3A%2F%2Falbum.love%2FHannaVikihMengundang&location=-6.991250%2C+112.511972&dates=20210725T010000Z%2F20210725T020000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Resepsi</h1>
                                    <h3 className="">Minggu <br />25 / Juli / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/vikihhanna/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">{sesi} WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/vikihhanna/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Jl. Raya Lasem, RT 01/ RW 01, Ds. Lasem, Kec. Sidayu, Kab. Gresik<br />(Kediaman Mempelai Wanita)</h4>
                                    <br />
                                    <img src="assets/clients/vikihhanna/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Resepsi+Pernikahan+Vikih+dan+Hanna&details=Detail+selengkapnya+bisa+dilihat+di+https://album.love/HannaVikihMengundang&location=-6.991250,+112.511972&dates=20210725T020000Z/20210725T040000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    {
                                        (showQrCode) ?
                                            <>
                                                <QrCodeGenerator
                                                    value={kepada}
                                                    bgColor="#ffffff"
                                                    fgColor="#000000"
                                                    size={256}
                                                />
                                                <hr />
                                            </>
                                            : null
                                    }
                                    <p className="sumbangan">Tanpa mengurangi rasa hormat sedikit pun, kami tidak menerima sumbangan dalam bentuk apapun.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                <div className="text-center">
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1980.0761284788912!2d112.5120125509262!3d-6.9913411661038785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwNTknMjguNSJTIDExMsKwMzAnNDMuMSJF!5e0!3m2!1sen!2sid!4v1621314260872!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://goo.gl/maps/SXN8RorTBLi81uSd7" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major">RSVP & Guestbook</h2>
                                <div className="text-center">
                                    {
                                        (gbSent) ?
                                            <>
                                                <br />
                                                <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                            </>
                                            :
                                            <>
                                                <p   >Kami memohon kesediaannya untuk mengisi buku tamu di bawah ini 😊</p>
                                                <form onSubmit={((e) => gbSubmitHandler(e))}>
                                                    <fieldset>
                                                        <div >
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div >
                                                                <select onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                                    <option value="" disabled selected>Apakah bisa hadir?</option>
                                                                    <option value="yes">Ya, hadir</option>
                                                                    <option value="no">Maaf, tidak bisa hadir</option>
                                                                    <option value="maybe">Belum tahu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div >
                                                            {
                                                                (gbAtt === "yes") ?
                                                                    <>
                                                                        <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                            <option value="1" selected>1 orang</option>
                                                                            <option value="2-4">2-4 orang</option>
                                                                            <option value=">5">{">"} 5 orang</option>
                                                                        </select>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div >
                                                            <div >
                                                                <textarea disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <br />
                                                                <button disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </>
                                    }
                                    <br />&nbsp;
                                    <div className="gb-wrap">
                                        {messageList}
                                    </div>
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }



                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation" target="_blank" rel="noreferrer">Vanilatte.com</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default VikihHanna;
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import Rellax from "rellax";

import Audio from '../../components/audio';
import Covidmodal from '../../components/covidModal';
import { SRLWrapper } from 'simple-react-lightbox';

let guestName = "";
const gbUrl = "https://sheet.best/api/sheets/38ffa5b8-f680-417c-8900-127a8db4ac37";
const gbKey = "1yiO5E72VwrQ2FHRVGCOJ7cTlTgDO2ZY7D-apwy1e7-Q";


function Gendhis() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    useEffect(() => {
        new Rellax(".rellax", {})
    })
    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>Gendhis | Vanilatte</title>
                <meta name="description" content="Gendhis Premium Wedding Invitation by Vanilatte" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link href="/assets/template/gendhis/css/main.css" rel="stylesheet" />
                <script src="/assets/global/countdown.js" date="2022-1-1 08:00"></script>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Great+Vibes&amp;display=swap" />
                <link href="https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap" rel="stylesheet" />
                {/* <link href="/assets/template/gendhis/css/font-awesome/css/all.min.css" rel="stylesheet" /> */}
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link href="/assets/template/gendhis/css/bootstrap.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <script src="/assets/global/cursor.js"></script>
                <link href="/assets/global/cursor.css" rel="stylesheet" />

                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />

                <script src="https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js"></script>
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            {/* <Covidmodal /> */}
            <div className="cursor"></div>
            <div id="notification-bar">
                <div className="notification-container">
                    <p>Gendhis</p>
                    <a className="btn-action" href="/order" target="_blank" rel="noreferrer"><span className="lnr lnr-cart">&nbsp;Order</span></a>
                </div>
            </div>
            <main className="page-content">
                <section className="ww-home-page" id="home">
                    <div className="ww-wedding-announcement d-flex align-items-center justify-content-start">
                        <div className="container ww-announcement-container">
                            <p animation-delay="200" animation-type="fadeInUp" className="wedding-of">The Wedding Of</p>
                            <p animation-delay="400" animation-type="fadeInUp" className="ww-couple-name">Bandung & Roro</p>
                            <p animation-delay="600" animation-type="fadeInUp" className="kepada">{guestName}</p><br /><br />
                            {/* <p><strong>Sabtu, 1 Januari 2022</strong></p> */}
                        </div>
                        <div className="custom-shape-divider-bottom" style={{ "transform": "scaleY(-1) scaleX(-1)" }}>
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                            </svg>
                        </div>
                    </div>
                </section>
                <section className="ww-section bg-dark" id="couple">
                    <div className="container first-container">
                        <p className="text-center"><img animation-delay="800" animation-type="fadeInDown" src="assets/template/gendhis/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="300" animation-type="zoomIn" >Kami yang Berbahagia</span>
                        </h2>
                        <p className="text-center"><img animation-delay="400" animation-type="fadeInUp" src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                        <br />&nbsp;
                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="mt-3"><LazyLoadImage animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="assets/template/gendhis/images/groom.jpg" alt="Groom" />
                                    <br />&nbsp;
                                    <h3 animation-delay="400" animation-type="fadeInUp" className="h2 ww-title">Bandung Bondowoso</h3>
                                    <p animation-delay="800" animation-type="zoomIn" className="pt-3 text-center text-muted">Putra pertama dari<br /><em>Prabu Damar Moyo & Ny. Damar Moyo</em><br />Kerajaan Pengging</p>
                                    <a animation-delay="1000" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-instagram"></i></a> &nbsp;
                                    <a animation-delay="1200" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-twitter"></i></a> &nbsp;
                                    <a animation-delay="1400" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp"><i className="fa fa-whatsapp"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mt-3"><LazyLoadImage animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="assets/template/gendhis/images/bride.jpg" alt="Bride" />

                                    <br />&nbsp;
                                    <h3 animation-delay="600" animation-type="fadeInUp" className="h2 ww-title">Roro Jonggrang</h3>
                                    <p animation-delay="1200" animation-type="zoomIn" className="pt-3 text-center text-muted">Putri ketiga dari<br /><em>Prabu Boko & Ny. Boko</em><br />Kerajaan Boko</p>
                                    <a animation-delay="1600" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-instagram"></i></a> &nbsp;
                                    <a animation-delay="1800" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-twitter"></i></a> &nbsp;
                                    <a animation-delay="2000" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp"><i className="fa fa-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>
                        &nbsp;
                        <p className="text-center"><img animation-delay="1200" animation-type="zoomIn" src="assets/template/gendhis/images/grass.png" className="" alt="Separator" /></p>
                        <div className="width-limiter">
                            <p animation-delay="300" animation-type="rotateInUpRight" className="pt-5 text-center text-muted prayer">Maha Suci Tuhan yang telah menciptakan pasangan-pasangan semuanya, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka maupun dari apa yang tidak mereka ketahui.</p>
                            <p animation-delay="600" animation-type="rotateInUpLeft" className="ww-title text-center">QS Yasin: 36</p>
                        </div>
                    </div>
                </section>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder-right.png" />
                </div>
                <div class="svg-divider-triangle-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="events">
                    <div className="container ww-wedding-event">
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <h2 className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="100" animation-type="zoomIn" >Turut Mengundang</span>
                        </h2>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada resepsi pernikahan kami yang akan diselenggarakan pada:</p>
                        <br />
                        <br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h4 text-center ww-sub-title">Sabtu</p>
                        <p animation-delay="300" animation-type="fadeInDown" className="h3 text-center ww-sub-title">1 / Januari / 2022</p>
                        <p animation-delay="300" animation-type="zoomIn" className="text-center"><img src="assets/template/gendhis/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="zoomIn" className="h3 text-center ww-sub-title">10:00 - 12:00</p>
                        <p animation-delay="300" animation-type="fadeInUp" className="text-center"><img src="assets/template/gendhis/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="fadeInUp" className="h4 text-center ww-sub-title">Gd. Pertemuan Candi Prambanan, <br />Yogyakarta</p>
                        <br />
                        <br />
                        <small animation-delay="300" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Tanpa mengurangi rasa hormat sedikit pun, acara resepsi pernikahan akan dilakukan dengan tetap mengikuti protokol kesehatan sesuai anjuran pemerintah.</small>
                        <br />&nbsp;
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" target="_blank" rel="noreferrer" href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Pernikahan+Bandung+Bondowoso+%26+Roro+Jonggrang&dates=20220101T080000/20220101T120000&details=Detail+acara+bisa+dilihat+di+https://vanilatte.com/&location=Prambanan+Temple,+Jl.+Raya+Solo+-+Yogyakarta+No.16,+Kranggan,+Bokoharjo,+Kec.+Prambanan,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55571,+Indonesia&pli=1&sf=true"><i className="fa fa-calendar-o"></i> &nbsp; Simpan di Google Calendar</a>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-dark" id="people">
                    <div className="container text-center">
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/template/gendhis/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >Lokasi Acara</h2>
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami.</p>
                        <br />
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.355933734574!2d110.48927335094578!3d-7.752020594385398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5ae3dbd859d1%3A0x19e7a03b25955a2d!2sPrambanan%20Temple!5e0!3m2!1sen!2sid!4v1615047731484!5m2!1sen!2sid" width="90%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <br />
                        <br />
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" href="https://goo.gl/maps/Uoow54CkgxprQenc7" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah</a>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleX(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="people">
                    <div className="container text-center">

                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="zoomIn" className="h1 ww-title text-center">Menuju Hari Bahagia</p>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                        <ul className="countdown text-muted">
                            <li animation-delay="200" animation-type="zoomIn" ><span id="days"></span>hari</li>
                            <li animation-delay="400" animation-type="zoomIn" ><span id="hours"></span>jam</li>
                            <li animation-delay="600" animation-type="zoomIn" ><span id="minutes"></span>menit</li>
                            <li animation-delay="800" animation-type="zoomIn" ><span id="seconds"></span>detik</li>
                        </ul>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-dark" >
                    <div className="container text-center">
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="zoomIn" className="h1 ww-title text-center">Our Love Story</p>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">You come to love not by finding the perfect person, but by seeing an imperfect person perfectly.</p>
                        <br />&nbsp;
                        <br />&nbsp;
                    </div>
                    <div className="timeline-container">
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; Juli 2015</span> <br />Sejak pertama kali aku memandangnya di kejauhan, ada sesuatu yang mengatakan padaku bahwa dia tercipta untukku.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; September 2015</span> <br />Malam minggu itu, aku bertanya apakah dia bersedia menemaniku ke acara ulang tahun sahabatku. Saat itu hanya aku saja yang tidak punya pasangan.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; November 2015</span> <br />Masih kuingat dengan jelas merah pipinya ketika kutanya apakah dia mau jadi kekasihku.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; Januari 2016</span> <br />Aku tidak ingat bagaimana jalan cerita film bioskop yang kutonton waktu itu, karena pandanganku selalu tertuju padanya.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; Februari 2018</span> <br />Aku sudah memikirkannya matang-matang, aku berharap semoga dia adalah tambatan terakhir bagiku.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; Maret 2018</span> <br />Ketika aku bertanya, <em>"maukah kamu menikah denganku?"</em> tidak perlu waktu lebih dari sedetik baginya untuk menjawab, <em>"Ya!"</em></p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; Desember 2019</span> <br />Kudatangi orang tuanya dan kukatakan dengan lantang bahwa aku akan menjaganya sepanjang sisa hidupku.</p>
                        <p animation-delay="200" animation-type="fadeInUp" className="timeline-item"><span className="timeline-item-title"><i className="fa fa-clock-o"></i> &nbsp; 1 Januari 2022</span> <br />Kami sepakat untuk memilih hari ini sebagai hari bahagia kami berdua.</p>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleX(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-light" id="gallery">
                    <div className="ww-photo-gallery">
                        <div className="container">
                            <p className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                            <div className="h1 text-center pb-3 ww-title" >Galeri</div>
                            <p className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                            <br />
                            <div className="ww-gallery" >
                                <div className="card-columns">
                                    <SRLWrapper>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-1.jpg" alt="Gallery Pic 1" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-2.jpg" alt="Gallery Pic 2" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-3.jpg" alt="Gallery Pic 3" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-4.jpg" alt="Gallery Pic 4" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-5.jpg" alt="Gallery Pic 5" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-6.jpg" alt="Gallery Pic 6" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-7.jpg" alt="Gallery Pic 7" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="assets/template/gendhis/images/gallery-pic-8.jpg" alt="Gallery Pic 8" /></div>
                                    </SRLWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/template/gendhis/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-dark" id="gallery">
                    <div className="ww-photo-gallery">
                        <div className="gb-container">
                            <p className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                            <div className="h1 text-center pb-3 ww-title" >RSVP & Guestbook</div>
                            <p className="text-center"><img src="assets/template/gendhis/images/divider.png" className="divider" alt="Separator" /></p>
                            <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Leave us your wishes</p>

                            <br />
                            {
                                (gbSent) ?
                                    <>
                                        <br />
                                        <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                    </>
                                    :
                                    <>

                                        <form onSubmit={((e) => gbSubmitHandler(e))}>
                                            <fieldset>
                                                <div >
                                                    <div >
                                                        <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div >
                                                        <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div >
                                                        <select animation-delay="300" animation-type="fadeInUp" onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                            <option value="" disabled selected>Apakah bisa hadir?</option>
                                                            <option value="yes">Ya, hadir</option>
                                                            <option value="no">Maaf, tidak bisa hadir</option>
                                                            <option value="maybe">Belum tahu</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div >
                                                    {
                                                        (gbAtt === "yes") ?
                                                            <>
                                                                <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                    <option value="1" selected>1 orang</option>
                                                                    <option value="2-4">2-4 orang</option>
                                                                    <option value=">5">{">"} 5 orang</option>
                                                                </select>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>

                                                <div >
                                                    <div >
                                                        <textarea animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div >
                                                        <br />
                                                        <button animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} id="singlebutton" className="gb-btn" name="singlebutton" type="submit">Kirim</button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </>
                            }
                            <div>
                                <br />&nbsp;
                                <button className="gb-btn"
                                    animation-delay="300" animation-type="fadeInUp"
                                    onClick={((e) => {
                                        e.preventDefault();
                                        setGbWaiting(true);
                                        setShowGbMsg(!showGbMsg);
                                        if (!showGbMsg) requestGuestBook();
                                    })} >{(showGbMsg) ? "Tutup" : "Lihat"} Semua Pesan</button>
                            </div>
                            {
                                (showGbMsg) ?
                                    <>
                                        {
                                            (gbWaiting) ?
                                                <>
                                                    <br />&nbsp;
                                                    <p style={{ "textAlign": "center" }}>Mohon tunggu...</p>
                                                </>
                                                :
                                                <div className="gb-wrap">
                                                    {messageList}
                                                </div>
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    </div>
                </section>

                <footer className="ww-footer bg-dark">
                    <div className="container text-center py-4">
                        <p className="my-0">Gendhis &middot; Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Vanilatte Wedding e-Invitation Service">Vanilatte.com</a></p>
                        &nbsp;
                    </div>
                </footer>
            </main>

        </>
    )
}

export default Gendhis;

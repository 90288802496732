import { useEffect, useState } from "react";
import Fade from '@material-ui/core/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";
import Covidmodal from "../../components/covidModal";
import { convertTime, formatCounter, prayer } from "../../util";
import Instagram from "../../components/instagram";
import Audio from "../../components/audio";

function Kinanthi(props) {
    const [guestName, setGuestName] = useState("")

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let kepada = params.get('kepada');
        if (kepada != null) setGuestName(kepada);

    }, [])

    // useEffect(() => {
    //     console.log(props);
    // }, [])
    // return <div><h1 id="text01">{convertTime(props.weddingData['16'])}</h1></div>

    if (props)
        return (
            <>
                <Helmet>
                    <link rel="stylesheet" href="/assets/global/wedding/kinanthi.css" />
                    <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                    <script src="/assets/global/wedding/countdown.js" date={formatCounter(props.weddingData['16']) + "__" + formatCounter(props.weddingData['17'])}></script>
                    <link rel="stylesheet" href="/assets/global/animation.css" />
                    <script src="/assets/global/animation.js"></script>
                    <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>
                {
                    (props.weddingData['33'].toLowerCase() != 'active') ?
                        <Covidmodal nama={props.weddingData['1']} />
                        : null
                }
                <Audio music="Yiruma, (이루마) - River Flows in You.mp3" />
                <Fade
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 1000 } : {})}
                >
                    <div className="is-loading">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" display="none" width={0} height={0}>
                                <symbol id="icon-903" viewBox="0 0 40 40">
                                    <path d="M25.5,14.7c0-1.5-0.5-2.7-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.7,0.5-3.8,1.6c-1,1-1.6,2.3-1.6,3.8s0.5,2.7,1.6,3.8 c1,1,2.3,1.6,3.8,1.6c1.5,0,2.7-0.5,3.8-1.6C25,17.4,25.5,16.1,25.5,14.7z M30.9,14.7c0,1.5-0.2,2.8-0.7,3.7l-7.6,16.2 c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.8-0.6-1-1.1l-7.6-16.2c-0.5-1-0.7-2.2-0.7-3.7 c0-3,1-5.5,3.1-7.6S17.2,4,20.2,4c3,0,5.5,1,7.6,3.1S30.9,11.7,30.9,14.7L30.9,14.7z" />
                                </symbol>
                            </svg>
                            <div id="wrapper">
                                <div id="main">
                                    <div className="inner">
                                        <div id="container01" className="container default full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    <div id="image01" className="image"><LazyLoadImage src="assets/global/wedding/header-wedding.svg" alt="" /></div>
                                                    <h3 className="fadeIn animated" id="text03">THE WEDDING OF</h3>
                                                    <h1 id="text01">{props.weddingData['1']}</h1>
                                                    {
                                                        (guestName) ?
                                                            <>
                                                                <p id="text04" style={{ color: '#fff', marginTop: '3em' }}>Kepada Yth.</p>
                                                                <p id="text01" style={{ marginTop: '-10px', fontSize: '2em' }}>{guestName}</p>
                                                            </>
                                                            : null
                                                    }
                                                    <p id="text02" style={{ fontSize: '1.2em', marginTop: '3em' }}>We invite you to share with us a celebration of love</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="container02" className="container columns full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    <div>
                                                        <h3 id="text07" style={{ fontSize: '2em' }}>{props.weddingData['5']}</h3>
                                                        <p id="text08" style={{ fontSize: '1em' }}>putri {(props.weddingData['6']).toLowerCase()} dari</p>
                                                        <p id="text09" style={{ fontSize: '1.2em' }}><span>{props.weddingData['7']} &amp;</span><br /> <span>{props.weddingData['8']}</span></p>
                                                        <p id="text05" style={{ fontSize: '1em' }}>{props.weddingData['9']}</p>
                                                        <p id="text22" style={{ fontSize: '2em', paddingTop: '1em' }}>&amp;<br />&nbsp;</p>
                                                    </div>
                                                    <div>
                                                        <h3 id="text04" style={{ fontSize: '2em' }}>{props.weddingData['10']}</h3>
                                                        <p id="text10" style={{ fontSize: '1em' }}>putra {(props.weddingData['11']).toLowerCase()} dari</p>
                                                        <p id="text06" style={{ fontSize: '1.2em' }}><span>{props.weddingData['12']} &amp;</span><br /> <span>{props.weddingData['13']}</span></p>
                                                        <p id="text11" style={{ fontSize: '1em' }}>{props.weddingData['14']}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div id="container04" className="container default full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    {
                                                        (props.weddingData['4'] !== "Tidak Memakai Do'a/Ayat") ?
                                                            <>
                                                                <h3 id="text20" style={{ fontSize: '1.2em' }}><span>{prayer[props.weddingData['4']]} </span></h3>
                                                                <br /><br />
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div id="container03" className="container default full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    <h2 id="text12" style={{ fontSize: '2em' }}>TURUT MENGUNDANG</h2>
                                                    <p id="text19" style={{ fontSize: '1em' }}>Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                                    <hr id="divider01" />
                                                    <br />
                                                    <h3 id="text17" style={{ fontSize: '1.5em' }}>{props.weddingData['15']}:</h3>
                                                    <p id="text18" style={{ fontSize: '1em' }}><span>{convertTime(props.weddingData['16'])}</span><br /> <span>{convertTime(props.weddingData['17'])} - {convertTime(props.weddingData['18'])}</span><br /> <span>{props.weddingData['19']}</span></p>
                                                    {
                                                        ((props.weddingData['20']).includes("https://") || (props.weddingData['20']).includes("http://")) ?
                                                            <div className="center-button">
                                                                <a href={props.weddingData['20']} target="_blank" rel="noreferrer" style={{ fontSize: '1em' }}><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                            </div>
                                                            : null
                                                    }

                                                    <br />
                                                    <div id="image01" className="image"><LazyLoadImage style={{ "maxWidth": "70px" }} className="grass" src="assets/global/wedding/grass.png" alt="" /></div>
                                                    <br />
                                                    <h3 id="text17" style={{ fontSize: '1.5em' }}>{props.weddingData['21']}:</h3>
                                                    <p id="text16" style={{ fontSize: '1em' }}><span>{convertTime(props.weddingData['22'])}</span><br /> <span>{convertTime(props.weddingData['23'])} - {convertTime(props.weddingData['24'])}</span><br /> <span>{props.weddingData['25']}</span></p>

                                                    {
                                                        ((props.weddingData['26']).includes("https://") || (props.weddingData['26']).includes("http://")) ?
                                                            <div className="center-button">
                                                                <a href={props.weddingData['26']} target="_blank" rel="noreferrer" style={{ fontSize: '1em' }}><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                            </div>
                                                            : null
                                                    }

                                                    <br />
                                                    {
                                                        (props.weddingData['27'] != '-') ?
                                                            <div>
                                                                <hr id="divider01" />
                                                                <h2 id="text12" style={{ marginBottom: '20px', fontSize: '2em' }}>GALERI</h2>
                                                                <p id="text19" style={{ fontSize: '1em' }}>Serangkaian perjalanan hidup yang telah membawa kami pada momen bahagia ini.</p>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
                                                                    <Instagram link={props.weddingData['27'].replace('utm_source=ig_web_copy_link', 'utm_source=ig_embed')} />
                                                                </div>
                                                                {/* <hr id="divider01" /> */}
                                                            </div>
                                                            : null
                                                    }
                                                    <br />
                                                    {
                                                        (props.weddingData['32'] != '-') ?
                                                            <div>
                                                                <hr id="divider01" />
                                                                <h2 id="text12" style={{ marginBottom: '20px', fontSize: '2em' }}>BINGKISAN</h2>
                                                                <p id="text19" style={{ fontSize: '1em' }}>Bagi kerabat & saudara yang ingin memberikan bingkisan kepada kami, bisa dikirimkan melalui:</p>
                                                                <p id="text19" style={{ fontSize: '1em', whiteSpace: 'pre-wrap', marginTop: '2em' }}>{props.weddingData['32']}</p>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (props.weddingData['30'] != '-' || (props.weddingData['31']) != '-') ?
                                                            <div>
                                                                <hr id="divider01" />
                                                                <h2 id="text12" style={{ marginBottom: '20px', fontSize: '2em' }}>UCAPAN & DOA</h2>
                                                                <p id="text19" style={{ fontSize: '1em' }}>Sampaikan ucapan & doa langsung kepada kami dengan klik tombol di bawah ini.</p>
                                                                {
                                                                    (props.weddingData['30']) ?
                                                                        <div className="center-button" style={{ marginTop: '20px' }}>
                                                                            <a href={'https://wa.me/' + '+62' + props.weddingData['30'].slice(1)} target="_blank" rel="noreferrer" id="text15"><i className="fa fa-whatsapp"></i> &nbsp; Sampaikan ke mempelai wanita</a>
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    (props.weddingData['31']) ?
                                                                        <div className="center-button" style={{ marginTop: '30px' }}>
                                                                            <a href={'https://wa.me/' + '+62' + props.weddingData['31'].slice(1)} target="_blank" rel="noreferrer" id="text15"><i className="fa fa-whatsapp"></i> &nbsp; Sampaikan ke mempelai pria</a>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <hr id="divider01" style={{ paddingTop: '1em' }} />
                                                            </div>
                                                            : null
                                                    }
                                                    <h2 id="text12" style={{ fontSize: '2em' }}>MENUJU HARI BAHAGIA</h2>
                                                    <div className="parent-timer light-clr">
                                                        <div id="timer01" className="timer">
                                                            <ul />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="container05" className="container default full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    <p id="text21" style={{ fontSize: '1em' }}>Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="container06" className="container default full">
                                            <div className="wrapper">
                                                <div className="inner">
                                                    <p id="text14" style={{ fontSize: '0.9em' }}>Buat Undangan Nikah Hanya 1 Menit di <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer">Vanilatte Wedding</a><br /><br /><br />
                                                        {/* <a href="https://vanilatte.com/buat-undangan" target="_blank" rel="noreferrer"><i className="fa fa-globe"></i></a> &nbsp;
                                                        <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a> &nbsp;
                                                        <a href="https://vanilatte.com/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </>
        )
}

export default Kinanthi
import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import { useState } from "react";
import uuid from "react-uuid";
import axios from "axios";
import moment from 'moment';
import { RWebShare } from "react-web-share";

import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";
import Footer from "./footer";
import Testimonial from "./testimonial";

const formUrl = "https://sheet.best/api/sheets/e4149303-9d4f-46e1-8d8b-08cb30637f69";

const uniqueCode = (uuid().replace("-").slice(-5));

const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];


let payload = {
    "tanggal_pembuatan": new Date(),
    "kode_unik": uniqueCode,

    "nama_panggilan": "-",

    "jumlah_tamu": "300 (default)",
    "doa": "",
    "jenis_acara": "",

    "tema_undangan": "kinanthi",

    "pemesan_nama": "-",
    "pemesan_telp": "-",
    "pemesan_instagram": "-",
    "pemesan_sebagai": "-",

    "mempelai_pria_nama": "-",
    "mempelai_pria_anak_ke": "-",
    "mempelai_pria_ayah": "-",
    "mempelai_pria_ibu": "-",
    "mempelai_pria_alamat": "-",

    "mempelai_wanita_nama": "-",
    "mempelai_wanita_anak_ke": "-",
    "mempelai_wanita_ayah": "-",
    "mempelai_wanita_ibu": "-",
    "mempelai_wanita_alamat": "-",

    "akad_tanggal": moment().add(1, 'days').format("yyyy-MM-DD"),
    "akad_waktu_mulai": "08:00",
    "akad_waktu_selesai": "09:00",
    "akad_lokasi": "-",
    "akad_gmaps": "-",

    "resepsi_tanggal": moment().add(1, 'days').format("yyyy-MM-DD"),
    "resepsi_waktu_mulai": "10:00",
    "resepsi_waktu_selesai": "12:00",
    "resepsi_lokasi": "-",
    "resepsi_gmaps": "-",
}

const defaultImgPreview = "https://via.placeholder.com/700?text=Preview+Undangan";


function FreeInvitationOrder() {
    const [prayerTxt, setPrayerTxt] = useState("");
    const [formFilled, setFormFilled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [weddingData, setWeddingData] = useState("");
    const [imgPreview, setImgPreview] = useState(defaultImgPreview)
    const [imgPreviewLink, setImgPreviewLink] = useState("#");

    const submitHandler = ((e) => {
        setWeddingData(payload);
        setIsSending(true)
        e.preventDefault();
        axios.post(formUrl, payload)
            .then(response => {
                setIsSending(false)
                setFormFilled(!formFilled);
            })
    })

    const payloadHandler = ((name, value) => {
        payload[name] = value;
    })

    const imgPreviewHandler = ((name) => {
        if (name === "") {
            setImgPreviewLink("#")
            setImgPreview(defaultImgPreview)
        }
        else {
            setImgPreviewLink("https://album.love/wedding/" + name)
            setImgPreview("/assets/home/img/template/" + name + "-square.png")
        }
    })

    const prayerHandler = ((txt) => {
        if (txt.includes("0")) setPrayerTxt(prayer[0])
        if (txt.includes("1")) setPrayerTxt(prayer[1])
        if (txt.includes("2")) setPrayerTxt(prayer[2])
        if (txt.includes("3")) setPrayerTxt(prayer[3])
        if (txt.includes("4")) setPrayerTxt(prayer[4])
        if (txt.includes("5")) setPrayerTxt(prayer[5])
        if (txt.includes("6")) setPrayerTxt(prayer[6])
        if (txt.includes("7")) setPrayerTxt(prayer[7])
        if (txt.includes("8")) setPrayerTxt(prayer[8])
        if (txt.includes("9")) setPrayerTxt(prayer[9])
        if (txt.includes("10")) setPrayerTxt(prayer[10])
        if (txt.includes("11")) setPrayerTxt(prayer[11])
        if (txt.includes("12")) setPrayerTxt(prayer[12])
        if (txt.includes("13")) setPrayerTxt(prayer[13])
        if (txt.includes("14")) setPrayerTxt(prayer[14])
        if (txt.includes("x")) setPrayerTxt("")
    })

    return (
        <>
            <Helmet>
                <title>Buat Undangan Gratis - Premium Wedding Invitation</title>
                <meta name="description" content="Free Wedding Invitation by Vanilatte" />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">


                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        <Navbar showLogo={true} showMenu={true} />

                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                {(formFilled) ?
                                    <>
                                        {/* <div className="row justify-content-center text-center">
                                            <div className="col-lg-6">
                                                <div className="sectionHeading -md">
                                                    <p className="sectionHeading__subtitle">Finished</p>
                                                    <h2 className="sectionHeading__title"><span className="text-accent">Selamat!</span> <br />Undangan Sudah Aktif</h2>
                                                    <p className="text-lg sm:text-base mt-24">Gunakan alamat web di bawah ini untuk membagikan undangan pernikahanmu. Jangan lupa screenshot dan simpan alamat web undangan pernikahan yang sudah dibuat. Jika lupa alamat web undangan, silakan hubungi kami atau kamu bisa buat undanganmu kembali.</p>
                                                    <br />&nbsp;
                                                <h2 className="sectionHeading__title">Alamat Undangan:</h2>
                                                    <br />&nbsp;
                                                <input type="text" value={"https://album.love/wedding/" + uniqueCode} className="uniqueCodeInput invitation-address" id="kode_undangan" readOnly rows="3"></input>
                                                    <br />&nbsp;
                                                <br />&nbsp;
                                                <br />&nbsp;
                                                <div className="teamCard__social mt-12 justify-content-center">
                                                        <div className="teamCard__social__item">
                                                            <div class="tooltip"><button className="button -sm -outline-black text-black" onClick={copyCode}><i className="fa fa-copy"></i> &nbsp; Salin Alamat <span class="tooltiptext" id="myTooltip">Salin Alamat Web</span></button></div>
                                                        </div>
                                                        <div className="teamCard__social__item">
                                                            <div><a className="button -sm -outline-black text-black" href={"https://album.love/wedding/" + uniqueCode} target="_blank" rel="noreferrer"><i className="fa fa-eye"></i> &nbsp; Buka Undangan</a></div>
                                                        </div>
                                                        <div className="teamCard__social__item">
                                                            <div><RWebShare
                                                                data={{
                                                                    text: "Hai, datang ya ke pernikahanku! Lihat di sini ya detail undangannya 😊",
                                                                    url: "https://album.love/wedding/" + uniqueCode,
                                                                    title: "Undangan Pernikahan",
                                                                }}
                                                                sites={[
                                                                    "whatsapp",
                                                                    "telegram",
                                                                    "twitter",
                                                                    "facebook",
                                                                ]}
                                                            >
                                                                <button className="button -sm -outline-black text-black"><i className="fa fa-chain"></i> &nbsp; Bagikan</button>
                                                            </RWebShare></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row justify-content-center" style={{ "marginTop": "-10rem" }}>
                                            <div className="col-lg-12">
                                                <div className="teamCard__social mt-12 justify-content-center">
                                                    <Testimonial weddingData={weddingData} url={"https://album.love/wedding/" + uniqueCode} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    (
                                        (isSending) ?
                                            <div className="row justify-content-center text-center">
                                                <div className="col-lg-6">
                                                    <div className="sectionHeading -md">
                                                        <p className="sectionHeading__subtitle">Please Wait</p>
                                                        <h2 className="sectionHeading__title"><span className="text-accent">Sending</span> Data</h2>
                                                        <p className="text-lg sm:text-base mt-24">Mohon tunggu, kami sedang memproses form pemesanan yang Anda kirim.</p>
                                                        <br />&nbsp;
                                                        </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-6">
                                                        <div className="sectionHeading -md">
                                                            <p className="sectionHeading__subtitle">GRATIS</p>
                                                            <h2 className="sectionHeading__title"><span className="text-accent">Undangan</span> Pernikahan</h2>
                                                            <p className="text-lg sm:text-base mt-24">Silakan isi form di bawah ini, maka kamu bisa memiliki undangan pernikahan secara gratis dan langsung bisa diakses.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />&nbsp;
                                <div className="row justify-content-center">
                                                    <div className="col-xl-10 mt-48 sm:mt-32">
                                                        <div className="contact-form -type-1">
                                                            <form onSubmit={((e) => submitHandler(e))} className="row x-gap-40 y-gap-32 js-ajax-form" data-message-success="Your message has been sent! We will reply you as soon as possible." data-message-error="Something went wrong. Please contact us directly.">
                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Mempelai</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Nama Panggilan
                                                                        <input onChange={((e) => payloadHandler("nama_panggilan", e.target.value))} type="text" name="callsign" required placeholder="Misal: Bandung & Roro" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Desain Undangan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Jumlah tamu undangan
                                                                        <input onChange={((e) => payloadHandler("jumlah_tamu", e.target.value))} type="text" name="callsign" required placeholder="Misal: 500" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Tema Undangan (Klik untuk <a href="/catalogue" target="_blank" rel="noreferrer"><span className="text-accent">Lihat Semua Tema</span></a>)<br />&nbsp;
                                                        <select onChange={((e) => { imgPreviewHandler(e.target.value); payloadHandler("tema_undangan", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih tema undangan</option>
                                                                            <option value="kinanthi">Kinanthi</option>
                                                                            <option value="maharani">Maharani</option>
                                                                            <option value="agna">Agna</option>
                                                                            <option value="gayatri">Gayatri</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 center-preview">
                                                                    <p>Pilih salah satu tema di atas  dan klik gambar berikut ini untuk melihat preview undangan<br />&nbsp;</p>
                                                                    {
                                                                        (imgPreviewLink === "#") ?
                                                                            <img className="" src={imgPreview} alt="Preview Undangan" />
                                                                            :
                                                                            <a href={imgPreviewLink} target="_blank" rel="noreferrer">
                                                                                <img className="" src={imgPreview} alt="Preview Undangan" />
                                                                            </a>
                                                                    }
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Doa/Ayat Pernikahan<br />&nbsp;
                                                        <select onChange={((e) => { prayerHandler(e.target.value); payloadHandler("doa", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih doa/ayat pernikahan</option>
                                                                            <optgroup label="Al-Qur'an">
                                                                                <option value="0">QS Yasin: 36</option>
                                                                                <option value="1">QS An-Nahl: 72</option>
                                                                                <option value="2">QS Ar-Rum:21</option>
                                                                                <option value="3">QS Adz-Dzariyat:49</option>
                                                                                <option value="4">QS An-Nur: 32</option>
                                                                            </optgroup>
                                                                            <optgroup label="Alkitab">
                                                                                <option value="5">Yakobus 2:2</option>
                                                                                <option value="6">Matius 19:6</option>
                                                                                <option value="7">Kolese 3:14</option>
                                                                                <option value="8">Markus 10:9</option>
                                                                                <option value="9">2 Korintus 13:11</option>
                                                                            </optgroup>
                                                                            <optgroup label="Love Quote">
                                                                                <option value="10">Love Quote #1</option>
                                                                                <option value="11">Love Quote #2</option>
                                                                                <option value="12">Love Quote #3</option>
                                                                                <option value="13">Love Quote #4</option>
                                                                                <option value="14">Love Quote #5</option>
                                                                            </optgroup>
                                                                            <optgroup label="Lainnya">
                                                                                <option value="x">Tidak perlu memakai doa/ayat</option>
                                                                            </optgroup>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12"><em>
                                                                    {
                                                                        (prayerTxt !== "") ?
                                                                            prayerTxt
                                                                            : null
                                                                    }</em>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Mempelai Wanita</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_nama", e.target.value))} type="text" name="bride-name" required placeholder="Nama lengkap & gelar mempelai wanita" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Anak ke-
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_anak_ke", e.target.value))} type="text" name="daughter-no" placeholder="Pertama, kedua, ketiga, dst." required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ayah Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_ayah", e.target.value))} type="text" name="groom-fater" required placeholder="Nama lengkap & gelar ayah mempelai wanita" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ibu Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_ibu", e.target.value))} type="text" name="groom-mother" placeholder="Nama lengkap & gelar ibu mempelai wanita" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Domisili Asal
                                                                        <textarea onChange={((e) => payloadHandler("mempelai_wanita_alamat", e.target.value))} name="groom-address" rows="2" required placeholder="Misal: Sleman, Yogyakarta"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Mempelai Pria</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_nama", e.target.value))} type="text" name="groom-name" required placeholder="Nama lengkap & gelar mempelai pria" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Anak ke-
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_anak_ke", e.target.value))} type="text" name="son-no" placeholder="Pertama, kedua, ketiga, dst." required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ayah Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_ayah", e.target.value))} type="text" name="groom-fater" required placeholder="Nama lengkap & gelar ayah mempelai pria" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ibu Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_ibu", e.target.value))} type="text" name="groom-mother" placeholder="Nama lengkap & gelar ibu mempelai pria" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Domisili Asal
                                                                        <textarea onChange={((e) => payloadHandler("mempelai_pria_alamat", e.target.value))} name="groom-address" rows="2" required placeholder="Misal: Kota Yogyakarta, Yogyakarta"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Pernikahan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Jenis Acara<br />&nbsp;
                                                        <select onChange={((e) => { payloadHandler("jenis_acara", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih Jenis Acara</option>
                                                                            <option value="Akad">Akad</option>
                                                                            <option value="Pemberkatan">Pemberkatan</option>
                                                                            <option value="Janji Pernikahan">Janji Pernikahan</option>
                                                                            <option value="Janji Suci">Janji Suci Pernikahan</option>
                                                                            <option value="Ikrar Perkawinan">Ikrar Perkawinan</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="js-input-group">Tanggal Pelaksanaan
                                                                        <input onChange={((e) => payloadHandler("akad_tanggal", e.target.value))} type="date" name="akad-date" defaultValue={moment().add(1, 'days').format("yyyy-MM-DD")} required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Mulai)
                                                                        <input onChange={((e) => payloadHandler("akad_waktu", e.target.value))} type="time" name="akad-time" defaultValue="08:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Selesai)
                                                                        <input onChange={((e) => payloadHandler("akad_waktu", e.target.value))} type="time" name="akad-time" defaultValue="09:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Lokasi Acara
                                                        <input onChange={((e) => payloadHandler("akad_lokasi", e.target.value))} type="text" name="akad-location" required placeholder="Lokasi acara akad/pemberkatan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Google Maps
                                                        <input onChange={((e) => payloadHandler("akad_gmaps", e.target.value))} type="text" name="akad-gmaps" placeholder="Link Google Maps acara akad/pemberkatan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Resepsi</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="js-input-group">Tanggal Pelaksanaan
                                                                        <input onChange={((e) => payloadHandler("resepsi_tanggal", e.target.value))} type="date" name="akad-date" defaultValue={moment().add(1, 'days').format("yyyy-MM-DD")} required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Mulai)
                                                                        <input onChange={((e) => payloadHandler("resepsi_waktu", e.target.value))} type="time" name="akad-time" defaultValue="10:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Selesai)
                                                                        <input onChange={((e) => payloadHandler("resepsi_waktu", e.target.value))} type="time" name="akad-time" defaultValue="12:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Lokasi Acara
                                                        <input onChange={((e) => payloadHandler("resepsi_lokasi", e.target.value))} type="text" name="resepsi-location" required placeholder="Lokasi acara resepsi pernikahan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Google Maps
                                                        <input onChange={((e) => payloadHandler("resepsi_gmaps", e.target.value))} type="text" name="resepsi-gmaps" placeholder="Link Google Maps acara resepsi pernikahan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Pemesan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Pemesan
                                                        <input onChange={((e) => payloadHandler("pemesan_nama", e.target.value))} type="text" name="order-name" required placeholder="Nama pemesan undangan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">No Telp/WA
                                                        <input onChange={((e) => payloadHandler("pemesan_telp", e.target.value))} type="text" name="resepsi-time" placeholder="No Telp/WA yang bisa dihubungi" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Akun Instagram
                                                        <input onChange={((e) => payloadHandler("pemesan_instagram", e.target.value))} type="text" name="ig" required placeholder="@username" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Membuat Undangan Sebagai?<br />&nbsp;
                                                    <select onChange={((e) => payloadHandler("pemesan_sebagai", e.target.value))} name="choose-design" required>
                                                                            <option value="">— Pilih salah satu</option>
                                                                            <option value="mempelai_pria">Mempelai Pria</option>
                                                                            <option value="mempelai_wanita">Mempelai Wanita</option>
                                                                            <option value="keluarga">Keluarga</option>
                                                                            <option value="panitia">Panitia/Wedding Organizer</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12 ajax-form-alert js-ajax-form-alert">
                                                                    <div className="ajax-form-alert__content">
                                                                    </div>
                                                                </div>

                                                                <br />&nbsp;
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input type="checkbox" required /> &nbsp; Dengan ini saya menyetujui <span className="text-accent"><a href="/terms-conditions" target="_blank" rel="noreferrer">Syarat & Ketentuan</a></span> yang berlaku.
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <br />&nbsp;

                                                                <div className="col-12">
                                                                    <button type="submit" className="button -md -black text-white">Buat Undangan Gratis</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                            </div>
                        </section>

                        <Footer />
                    </main>
                </div>
            </div>
        </>
    )
}

export default FreeInvitationOrder;
import { Helmet } from "react-helmet";

function HelmetTemplate(props) {
    return (
        <>
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/home/css/vendors.css" />
                <link rel="stylesheet" href="/assets/home/css/main.css" />
                <script src="/assets/home/js/vendors.js"></script>
                <script src="/assets/home/js/main.js"></script>
                {
                    (!props.useDisabler) ?
                        null
                        :
                        <script src="/assets/global/disabler.js"></script>
                }
            </Helmet>
        </>
    )
}

export default HelmetTemplate;
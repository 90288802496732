import { Helmet } from "react-helmet";
import QrReader from "react-qr-reader";
import { useState } from "react";
import CryptoJS from "crypto-js";
import moment from "moment";
import axios from "axios";
import { detect } from "detect-browser";

import "./style.css"

const sheetUrl = "https://sheet.best/api/sheets/";
const browser = detect();

const statusStr = {
    "ok": "✅ Berhasil menyimpan",
    "fail": "⛔ Gagal menyimpan",
    "failConnection": "⛔ Gagal menyimpan - Tidak ada koneksi internet",
    "empty": "❗ QR Code belum discan",
    "saving": "🕓 Mohon tunggu...",
    "standby": "⛄ Standby",
}

function Scanner() {
    const [guestName, setGuestName] = useState("...");
    const [scanResult, setScanResult] = useState("");
    const [status, setStatus] = useState(statusStr.standby);
    const [noIdError, setNoIdError] = useState(false);
    const [sheetId, setSheetId] = useState("");
    const [isSending, setIsSending] = useState(false);

    useState(() => {
        let path = (window.location.pathname).split("/")[(window.location.pathname).split("/").length - 1];
        if ((path === "scanner") || (path === "") || (path === " ")) {
            setNoIdError(true)
        } else if (path.length <= 10) {
            setNoIdError(true)
        } else {
            setSheetId(path);
        }
    })

    const handleSubmit = (() => {
        if (guestName === "...") {
            setStatus(statusStr.empty)
            setStatus(statusStr.empty + " (3)");
            setTimeout(() => {
                setStatus(statusStr.empty + " (2)");
            }, 1000);
            setTimeout(() => {
                setStatus(statusStr.empty + " (1)");
            }, 2000);
            setTimeout(() => {
                setStatus(statusStr.standby);
            }, 3000);
        } else {
            setStatus(statusStr.saving)
            setIsSending(true)
            // let now = moment.utc(new Date());
            var date = moment(new Date()).local();
            const payload = {
                log: new Date(),
                tanggal_scan: moment(date).format('Do MMMM YYYY'),
                waktu_scan: moment(date).format('HH:mm'),
                nama_tamu: guestName,
                scanner: browser.name + " " + browser.version + " on " + browser.os
            }

            axios.post((sheetUrl + sheetId + "/tabs/Scanner"), payload)
                .then(response => {
                    setIsSending(false)
                    if (response.status === 200) {
                        setStatus(statusStr.ok + " (3)");
                        setTimeout(() => {
                            setStatus(statusStr.ok + " (2)");
                        }, 1000);
                        setTimeout(() => {
                            setStatus(statusStr.ok + " (1)");
                        }, 2000);
                        setTimeout(() => {
                            setGuestName("...");
                            setStatus(statusStr.standby);
                        }, 3000);
                    }
                    else setStatus(statusStr.fail);
                })
                .catch(error => {
                    setIsSending(false)
                    setStatus(statusStr.failConnection);
                })
        }
    })

    const handleError = (() => {
        // currently, do nothing
    })

    const handleScan = ((data) => {
        if (typeof data === "string") {
            if (data.length > 1) {
                setScanResult(data);
                parseGuestName(data);
            }
        }
    })

    const parseGuestName = ((data) => {
        var bytes = CryptoJS.AES.decrypt(data, 'vanilatte');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        setGuestName(originalText)
    })

    const handleClear = (() => {
        setGuestName("...");
        setStatus(statusStr.standby)
    })

    return (
        <>
            <Helmet>
                <title>Vanilatte Guest Scanner</title>
                <meta name="description" content="Scanner Pengganti Buku Tamu" />
            </Helmet>
            {
                (noIdError) ?
                    <div className="scanner-wrapper">
                        <h1>❌ Error ❌</h1>
                        <p>Aplikasi scanner tamu tidak bisa digunakan, silakan hubungi kami.</p>
                    </div>
                    :
                    <div className="scanner-wrapper">
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                            className="qr-reader"
                        />
                        <p className="guest-name" ><strong>{guestName}</strong></p>
                        <hr/>
                        <p className="status" >{status}</p>
                        <div className="save-wrapper">
                            <button className="save-button" onClick={handleSubmit} disabled={isSending}><i className="fa fa-save" ></i> &nbsp; Simpan</button>
                            <br />&nbsp;
                <button className="delete-button" onClick={handleClear}><i className="fa fa-trash" ></i> &nbsp; Bersihkan</button>
                        </div>
                    </div>
            }
        </>
    )
}

export default Scanner;
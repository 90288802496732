import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import { useState } from "react";
import uuid from "react-uuid";
import axios from "axios";
import moment from 'moment';

import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import NumberWithComma from "../../util/NumberWithComma";
import Navbar from "./navbar";
import Footer from "./footer";
import Testimonial from "./testimonial";
import Tabletop from "tabletop";
import { useEffect } from "react";

const days = [
    "SEN",
    "SEL",
    "RAB",
    "KAM",
    "JUM",
    "SAB",
    "MIN"
];

const formUrl = "https://sheet.best/api/sheets/c9ac3e47-4a7d-4d03-84e0-6ec0182b35c0";

const voucherID = "1DLTOG9BvYhTqy5C7OdoNiIKb0WEcaaenMKoyB-cLZDM";

const dayName = days[(new Date()).getDay() - 1];
const uniqueCode = (uuid().replace("-").slice(-7));

const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];

const pricing = {
    // design
    "artavika": 375000,
    "gendhis": 350000,
    "nawala": 300000,
    "dalu": 375000,
    "kinanthi": 0,
    "agna": 0,
    "maharani": 0,
    "gayatri": 0,

    // features
    "guestName": 20000,
    "coverPhoto": 30000,
    "rsvpOnly": 30000,
    "rsvpReport": 50000,
    "socialMedia": 35000,
    "countDown": 0,
    "videoGallery": 35000,
    "backSound": 35000,
    "loveStory": 40000,
    "qrCode": 20000,
    "googleCalendar": 30000,
    "liveStreaming": 30000,
    "barCode": 20000,
    "photoSpace": 50000,
    "customColor": 200000,
    "session": 40000,
};

let payload = {
    "tanggal_pemesanan": new Date(),
    "kode_unik": dayName + uniqueCode,

    "username": "-",
    "nama_panggilan": "-",

    "jumlah_tamu": "0",
    "tema_undangan": "-",
    "doa": "-",

    "mempelai_pria_nama": "-",
    "mempelai_pria_anak_ke": "-",
    "mempelai_pria_ayah": "-",
    "mempelai_pria_ibu": "-",
    "mempelai_pria_alamat": "-",

    "mempelai_wanita_nama": "-",
    "mempelai_wanita_anak_ke": "-",
    "mempelai_wanita_ayah": "-",
    "mempelai_wanita_ibu": "-",
    "mempelai_wanita_alamat": "-",

    "jenis_acara": "",
    "akad_tanggal": moment().add(1, 'days').format("yyyy-MM-DD"),
    "akad_waktu_mulai": "08:00",
    "akad_waktu_selesai": "09:00",
    "akad_lokasi": "-",
    "akad_gmaps": "-",

    "jenis_acara_resepsi": "-",
    "resepsi_tanggal": moment().add(1, 'days').format("yyyy-MM-DD"),
    "resepsi_waktu_mulai": "10:00",
    "resepsi_waktu_selesai": "12:00",
    "resepsi_lokasi": "-",
    "resepsi_gmaps": "-",

    "pemesan_nama": "-",
    "pemesan_telp": "-",
    "pemesan_email": "-",
    "pemesan_instagram": "-",
    "pemesan_kontak": "-",
    "pemesan_sebagai": "-",


    "galeri_foto": "-",
    "galeri_video": "-",

    "fitur_guest_name": "-",
    "fitur_cover_photo": "-",
    "fitur_rsvp_only": "-",
    "fitur_rsvp_excel": "-",
    "fitur_media_sosial": "-",
    "fitur_countdown": "-",
    "fitur_galeri_video": "-",
    "fitur_backsound": "-",
    "fitur_love_story": "-",
    "fitur_cashless": "-",
    "fitur_gcalendar": "-",
    "fitur_live_stream": "-",
    "fitur_barcode": "-",
    "fitur_kuota_galeri": "-",
    "fitur_custom_warna": "-",
    "fitur_sesi": "-",

    "catatan": "-",
    "kode_voucher": "-",

    "total_harga": "(Gunakan Voucher Lembayung)",
}
let kodeVoucher = "";
let tempPrice = 0;
const defaultImgPreview = "https://via.placeholder.com/700?text=Preview+Undangan";

function CreateInvitationWithLimit() {
    const [prayerTxt, setPrayerTxt] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [formFilled, setFormFilled] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [imgPreview, setImgPreview] = useState(defaultImgPreview)
    const [imgPreviewLink, setImgPreviewLink] = useState("#");
    const [weddingData, setWeddingData] = useState("");
    const [quotaFull, setQuotaFull] = useState(false);
    const [voucherList, setVoucherList] = useState([]);
    const [voucherStatus, setVoucherStatus] = useState("")

    useEffect(() => {
        Tabletop.init({
            key: voucherID,
            callback: googleData => {
                setVoucherList(googleData)
            },
            simpleSheet: true
        })
    })

    const voucherBtnHandler = (() => {
        let flag = false;
        setVoucherStatus("Mengecek voucher...")
        for (let i = 0; i < voucherList.length; i++) {
            if (String(voucherList[i].kode_voucher).toLowerCase() === String(kodeVoucher).toLowerCase()) {
                flag = true
            }
        }

        if (flag) {
            setVoucherStatus("Voucher berhasil digunakan!");
            submitData();
        }
        else {
            setVoucherStatus("Kode voucher salah!");
        }
    })

    const submitData = (() => {
        setWeddingData(payload);
        setIsSending(true)
        axios.post(formUrl, payload)
            .then(response => {
                setIsSending(false)
                setFormFilled(!formFilled);
            })
    })

    const submitHandler = ((e) => {
        e.preventDefault();
        if (totalPrice === 0) {
            setQuotaFull(true)
        } else {
            submitData();
        }
    })

    const imgPreviewHandler = ((name) => {
        if (name === "") {
            setImgPreviewLink("#")
            setImgPreview(defaultImgPreview)
        }
        else {
            setImgPreview("/assets/home/img/template/" + name + "-square.png")
            if ((name === "artavika") ||
                (name === "gendhis") ||
                (name === "dalu") ||
                (name === "nawala"))
                setImgPreviewLink("https://vanilatte.com/template/" + name)
            else
                setImgPreviewLink("https://vanilatte.com/wedding/" + name)
        }
    })

    const payloadHandler = ((name, value) => {
        payload[name] = value;
    })

    const updatePrice = ((tempPrice) => {
        let tempTotalPrice = 0;
        if (tempPrice <= 0) {
            tempTotalPrice = totalPrice + tempPrice;
        }
        else {
            tempTotalPrice = totalPrice + tempPrice;
        }
        if (tempTotalPrice <= 0) tempTotalPrice = 0;

        setTotalPrice(tempTotalPrice);
        payload.total_harga = tempTotalPrice;
    })

    const templateDesignHandler = ((txt) => {

        let templatePrice = 0;
        if (txt.includes("gendhis")) {
            templatePrice = pricing.gendhis
        }
        else if (txt.includes("artavika")) {
            templatePrice = pricing.artavika
        }
        else if (txt.includes("nawala")) {
            templatePrice = pricing.nawala
        }
        else if (txt.includes("dalu")) {
            templatePrice = pricing.dalu
        }

        if (totalPrice > 0) {
            updatePrice(templatePrice - tempPrice);
        } else {
            updatePrice(templatePrice);
        }

        tempPrice = templatePrice;
    })


    const guestNameHandler = ((txt) => {
        if (txt) updatePrice(pricing.guestName);
        else updatePrice(-pricing.guestName);
    })

    const coverPhotoHandler = ((txt) => {
        if (txt) updatePrice(pricing.coverPhoto);
        else updatePrice(-pricing.coverPhoto);
    })

    const rsvpOnlyHandler = ((txt) => {
        if (txt) updatePrice(pricing.rsvpOnly);
        else updatePrice(-pricing.rsvpOnly);
    })

    const rsvpReportHandler = ((txt) => {
        if (txt) updatePrice(pricing.rsvpReport);
        else updatePrice(-pricing.rsvpReport);
    })

    const socialMediaHandler = ((txt) => {
        if (txt) updatePrice(pricing.socialMedia);
        else updatePrice(-pricing.socialMedia);
    })

    const countDownHandler = ((txt) => {
        if (txt) updatePrice(pricing.countDown);
        else updatePrice(-pricing.countDown);
    })

    const videoGalleryHandler = ((txt) => {
        if (txt) updatePrice(pricing.videoGallery);
        else updatePrice(-pricing.videoGallery);
    })

    const backSoundHandler = ((txt) => {
        if (txt) updatePrice(pricing.backSound);
        else updatePrice(-pricing.backSound);
    })

    const loveStoryHandler = ((txt) => {
        if (txt) updatePrice(pricing.loveStory);
        else updatePrice(-pricing.loveStory);
    })

    const qrCodeHandler = ((txt) => {
        if (txt) updatePrice(pricing.qrCode);
        else updatePrice(-pricing.qrCode);
    })

    const googleCalendarHandler = ((txt) => {
        if (txt) updatePrice(pricing.googleCalendar);
        else updatePrice(-pricing.googleCalendar);
    })

    const liveStreamingHandler = ((txt) => {
        if (txt) updatePrice(pricing.liveStreaming);
        else updatePrice(-pricing.liveStreaming);
    })

    const barCodeHandler = ((txt) => {
        if (txt) updatePrice(pricing.barCode);
        else updatePrice(-pricing.barCode);
    })

    const photoSpaceHandler = ((txt) => {
        if (txt) updatePrice(pricing.photoSpace);
        else updatePrice(-pricing.photoSpace);
    })

    const customColorHandler = ((txt) => {
        if (txt) updatePrice(pricing.customColor);
        else updatePrice(-pricing.customColor);
    })

    const sessionHandler = ((txt) => {
        if (txt) updatePrice(pricing.session);
        else updatePrice(-pricing.session);
    })


    const prayerHandler = ((txt) => {
        if (txt.includes("0")) setPrayerTxt(prayer[0])
        if (txt.includes("1")) setPrayerTxt(prayer[1])
        if (txt.includes("2")) setPrayerTxt(prayer[2])
        if (txt.includes("3")) setPrayerTxt(prayer[3])
        if (txt.includes("4")) setPrayerTxt(prayer[4])
        if (txt.includes("5")) setPrayerTxt(prayer[5])
        if (txt.includes("6")) setPrayerTxt(prayer[6])
        if (txt.includes("7")) setPrayerTxt(prayer[7])
        if (txt.includes("8")) setPrayerTxt(prayer[8])
        if (txt.includes("9")) setPrayerTxt(prayer[9])
        if (txt.includes("10")) setPrayerTxt(prayer[10])
        if (txt.includes("11")) setPrayerTxt(prayer[11])
        if (txt.includes("12")) setPrayerTxt(prayer[12])
        if (txt.includes("13")) setPrayerTxt(prayer[13])
        if (txt.includes("14")) setPrayerTxt(prayer[14])
        if (txt.includes("menyusul")) setPrayerTxt("(Doa/ayat menyusul kemudian)")
        if (txt.includes("x")) setPrayerTxt("(Tidak perlu menggunakan doa/ayat)")
    })

    const copyCode = (() => {
        /* Get the text field */
        let copyText = document.getElementById("kode_undangan");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        // alert("Kode berhasil disalin: " + copyText.value);
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Kode unik tersalin: " + copyText.value;
    })

    return (
        <>
            <Helmet>
                <title>Buat Undangan Pernikahan - Premium Wedding Invitation</title>
                <meta name="description" content="Undangan pernikahan terbaik hanya di sini!" />
            </Helmet>
            <HelmetTemplate useDisabler={false} />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">


                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        <Navbar showLogo={true} showMenu={true} />

                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                {(formFilled) ?
                                    <>
                                        {
                                            (totalPrice > 0) ?

                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-6">
                                                        <div className="sectionHeading -md">
                                                            <p className="sectionHeading__subtitle">Finished</p>
                                                            <h2 className="sectionHeading__title"><span className="text-accent">Selamat!</span> <br />Undangan Sedang Disiapkan</h2>
                                                            <p className="text-lg sm:text-base mt-24">Silakan screenshot halaman ini dengan kode di bawah ini terlihat dengan jelas (atau salin/copy dengan menekan tombol "Salin Kode"), lalu kirimkan kode pada kami dengan menekan tombol "Kirim Kode".</p>
                                                            <br />&nbsp;
                                                <h2 className="sectionHeading__title">Kode:</h2>
                                                            <input type="text" value={dayName + uniqueCode} className="uniqueCodeInput" id="kode_undangan" readOnly></input>
                                                            <br />&nbsp;
                                                <h2 className="sectionHeading__title">Total:</h2>
                                                            <input type="text" value={"Rp" + NumberWithComma(totalPrice)} className="uniqueCodeInput" readOnly></input>
                                                            <br />&nbsp;
                                                <br />&nbsp;
                                                <br />&nbsp;
                                                <div className="teamCard__social mt-12 justify-content-center">
                                                                <div className="teamCard__social__item">
                                                                    <div class="tooltip"><button className="button -sm -outline-black text-black" onClick={copyCode}><i className="fa fa-copy"></i> &nbsp; Salin Kode <span class="tooltiptext" id="myTooltip">Salin Kode Unik</span></button></div>
                                                                </div>
                                                                <div className="teamCard__social__item">
                                                                    <a href={"/ask?text=Hi, Vanilatte! Saya baru saja melakukan pemesanan undangan pernikahan dengan kode pemesanan: " + dayName + uniqueCode} target="_blank" rel="noreferrer" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Kirim Kode</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row justify-content-center" style={{ "marginTop": "-10rem" }}>
                                                    <div className="col-lg-12">
                                                        <div className="teamCard__social mt-12 justify-content-center">
                                                            <Testimonial weddingData={weddingData} url={"https://album.love/wedding/" + dayName + uniqueCode} />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>

                                    :
                                    (
                                        (isSending) ?
                                            <div className="row justify-content-center text-center">
                                                <div className="col-lg-6">
                                                    <div className="sectionHeading -md">
                                                        <p className="sectionHeading__subtitle">Please Wait</p>
                                                        <h2 className="sectionHeading__title"><span className="text-accent">Sending</span> Data</h2>
                                                        <p className="text-lg sm:text-base mt-24">Mohon tunggu, kami sedang memproses form pemesanan yang Anda kirim.</p>
                                                        <br />&nbsp;
                                                        </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-6">
                                                        <div className="sectionHeading -md">
                                                            <p className="sectionHeading__subtitle">Yuk Buat</p>
                                                            <h2 className="sectionHeading__title"><span className="text-accent">Undangan</span> Pernikahanmu</h2>
                                                            <p className="text-lg sm:text-base mt-24">Silakan isi form ini untuk membuat undangan pernikahan. Bagian yang bertanda khusus (⭐) merupakan tema atau fitur <strong>premium</strong>. </p>
                                                            <hr />
                                                            <p className="text-lg sm:text-base mt-24"><strong>Catatan khusus untuk pengguna Voucher Lembayung:</strong><br /><br />
                                                            - Pastikan total biaya (di bagian bawah) selalu bertuliskan <strong>Total Biaya: <span className="text-accent">(Gunakan Voucher Lembayung)</span></strong> atau voucher yang sudah dimiliki tidak akan bisa dipakai.<br /><br />
                                                            - Voucher Lembayung hanya bisa digunakan untuk memilih tema dan fitur yang tidak memiliki tanda khusus premium.<br /><br />
                                                            - Kode voucher hanya akan bisa dimasukkan jika Total Biaya bertuliskan <strong>Total Biaya: <span className="text-accent">(Gunakan Voucher Lembayung)</span></strong>. Kemudian klik tombol "Buat Undangan".<br /><br />
                                                            - Untuk mengaktifkan kode voucher, masukkan kode voucher di kolom berwarna abu-abu, lalu klik tombol Hapus Limit Kuota.<br /><br />
                                                            - Masih bingung atau ada pertanyaan? <a className="text-accent" href="/ask?text=Hai+Vanilatte,+saya+ada+pertanyaan+terkait+pengisian+form+undangan" target="_blank" rel="noreferrer">Klik tulisan merah ini untuk menghubungi kami melalui WhatsApp</a>.</p>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />&nbsp;
                                <div className="row justify-content-center">
                                                    <div className="col-xl-10 mt-48 sm:mt-32">
                                                        <div className="contact-form -type-1">
                                                            <form onSubmit={((e) => submitHandler(e))} className="row x-gap-40 y-gap-32 js-ajax-form" data-message-success="Your message has been sent! We will reply you as soon as possible." data-message-error="Something went wrong. Please contact us directly.">
                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Username</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">⭐ Username &middot; <strong>album.love</strong><em>/username</em> <br /><br /><em>(Catatan: username akan diberikan secara gratis jika mengambil fitur premium)</em>
                                                                        <input onChange={((e) => { payloadHandler("username", e.target.value) })} type="text" name="username" placeholder="Misal: BandungRoroMenikah" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Nama Panggilan
                                                                        <input onChange={((e) => payloadHandler("nama_panggilan", e.target.value))} type="text" name="callsign" required placeholder="Misal: Bandung & Roro" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Desain Undangan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Perkiraan jumlah tamu undangan
                                                                        <input onChange={((e) => payloadHandler("jumlah_tamu", e.target.value))} type="text" name="callsign" required placeholder="Misal: 500" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Tema Undangan (Klik untuk <a href="/catalogue" target="_blank" rel="noreferrer"><span className="text-accent">Lihat Semua Tema</span></a>)<br />&nbsp;
                                                                <select onChange={((e) => { imgPreviewHandler(e.target.value); templateDesignHandler(e.target.value); payloadHandler("tema_undangan", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih desain undangan pernikahan</option>
                                                                            <option value="artavika">⭐ Artavika</option>
                                                                            <option value="gendhis">⭐ Gendhis</option>
                                                                            <option value="nawala">⭐ Nawala</option>
                                                                            <option value="dalu">⭐ Dalu</option>
                                                                            <option value="kinanthi">Kinanthi</option>
                                                                            <option value="maharani">Maharani</option>
                                                                            <option value="agna">Agna</option>
                                                                            <option value="gayatri">Gayatri</option>
                                                                            <option value="larasati">Larasati</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12 center-preview">
                                                                    <p>Pilih salah satu tema di atas  dan klik gambar berikut ini untuk melihat preview undangan<br />&nbsp;</p>
                                                                    {
                                                                        (imgPreviewLink === "#") ?
                                                                            <img className="" src={imgPreview} alt="Preview Undangan" />
                                                                            :
                                                                            <a href={imgPreviewLink} target="_blank" rel="noreferrer">
                                                                                <img className="" src={imgPreview} alt="Preview Undangan" />
                                                                            </a>
                                                                    }
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Doa/Ayat Pernikahan<br />&nbsp;
                                                        <select onChange={((e) => { prayerHandler(e.target.value); payloadHandler("doa", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih doa/ayat pernikahan</option>
                                                                            <optgroup label="Al-Qur'an">
                                                                                <option value="0">QS Yasin: 36</option>
                                                                                <option value="1">QS An-Nahl: 72</option>
                                                                                <option value="2">QS Ar-Rum:21</option>
                                                                                <option value="3">QS Adz-Dzariyat:49</option>
                                                                                <option value="4">QS An-Nur: 32</option>
                                                                            </optgroup>
                                                                            <optgroup label="Alkitab">
                                                                                <option value="5">Yakobus 2:2</option>
                                                                                <option value="6">Matius 19:6</option>
                                                                                <option value="7">Kolese 3:14</option>
                                                                                <option value="8">Markus 10:9</option>
                                                                                <option value="9">2 Korintus 13:11</option>
                                                                            </optgroup>
                                                                            <optgroup label="Love Quote">
                                                                                <option value="10">Love Quote #1</option>
                                                                                <option value="11">Love Quote #2</option>
                                                                                <option value="12">Love Quote #3</option>
                                                                                <option value="13">Love Quote #4</option>
                                                                                <option value="14">Love Quote #5</option>
                                                                            </optgroup>
                                                                            <optgroup label="Lainnya">
                                                                                <option value="menyusul">⭐ Disusulkan kemudian</option>
                                                                                <option value="x">Tidak perlu memakai doa/ayat</option>
                                                                            </optgroup>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12"><em>
                                                                    {
                                                                        (prayerTxt !== "") ?
                                                                            prayerTxt
                                                                            : null
                                                                    }</em>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Mempelai Wanita</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_nama", e.target.value))} type="text" name="bride-name" required placeholder="Nama lengkap & gelar mempelai wanita" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Anak ke-
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_anak_ke", e.target.value))} type="text" name="daughter-no" placeholder="Pertama, kedua, ketiga, dst." required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ayah Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_ayah", e.target.value))} type="text" name="groom-fater" required placeholder="Nama lengkap & gelar ayah mempelai wanita" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ibu Mempelai Wanita
                                                                        <input onChange={((e) => payloadHandler("mempelai_wanita_ibu", e.target.value))} type="text" name="groom-mother" placeholder="Nama lengkap & gelar ibu mempelai wanita" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Alamat Asal
                                                                        <textarea onChange={((e) => payloadHandler("mempelai_wanita_alamat", e.target.value))} name="groom-address" rows="2" required placeholder="Misal: Jl. Gejayan CT X, Caturtunggal, Sleman, Yogyakarta"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Mempelai Pria</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_nama", e.target.value))} type="text" name="groom-name" required placeholder="Nama lengkap & gelar mempelai pria" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Anak ke-
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_anak_ke", e.target.value))} type="text" name="son-no" placeholder="Pertama, kedua, ketiga, dst." required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ayah Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_ayah", e.target.value))} type="text" name="groom-fater" required placeholder="Nama lengkap & gelar ayah mempelai pria" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Ibu Mempelai Pria
                                                                        <input onChange={((e) => payloadHandler("mempelai_pria_ibu", e.target.value))} type="text" name="groom-mother" placeholder="Nama lengkap & gelar ibu mempelai pria" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Alamat Asal
                                                                        <textarea onChange={((e) => payloadHandler("mempelai_pria_alamat", e.target.value))} name="groom-address" rows="2" required placeholder="Misal: Jl. Kusumanegara, Muja Muju, Kota Yogyakarta, Yogyakarta"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Pernikahan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Jenis Acara<br />&nbsp;
                                                        <select onChange={((e) => { payloadHandler("jenis_acara", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih Jenis Acara</option>
                                                                            <option value="Akad">Akad</option>
                                                                            <option value="Pemberkatan">Pemberkatan</option>
                                                                            <option value="Janji Pernikahan">Janji Pernikahan</option>
                                                                            <option value="Janji Suci">Janji Suci Pernikahan</option>
                                                                            <option value="Ikrar Perkawinan">Ikrar Perkawinan</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="js-input-group">Tanggal Pelaksanaan
                                                                        <input onChange={((e) => payloadHandler("akad_tanggal", e.target.value))} type="date" name="akad-date" required defaultValue={moment().add(1, 'days').format("yyyy-MM-DD")} autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Mulai)
                                                                        <input onChange={((e) => payloadHandler("akad_waktu_mulai", e.target.value))} type="time" name="akad-time" defaultValue="08:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Selesai)
                                                                        <input onChange={((e) => payloadHandler("akad_waktu_selesai", e.target.value))} type="time" name="akad-time" defaultValue="09:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Lokasi Acara
                                                        <input onChange={((e) => payloadHandler("akad_lokasi", e.target.value))} type="text" name="akad-location" required placeholder="Lokasi acara pernikahan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Link Google Maps (Undangan Premium Bisa Menyusul)
                                                        <input onChange={((e) => payloadHandler("akad_gmaps", e.target.value))} type="text" name="akad-gmaps" placeholder="Harus diawali 'https://' dan tanpa nama lokasi/tempat" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Resepsi</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">Jenis Acara<br />&nbsp;
                                                        <select onChange={((e) => { payloadHandler("jenis_acara_resepsi", e.target.value) })} name="choose-design" required>
                                                                            <option value="">— Pilih Jenis Acara</option>
                                                                            <option value="Resepsi">Resepsi</option>
                                                                            <option value="Pesta Pernikahan">Pesta Pernikahan</option>
                                                                            <option value="Tasyakuran Pernikahan">Tasyakuran Pernikahan</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label className="js-input-group">Tanggal Pelaksanaan
                                                        <input onChange={((e) => payloadHandler("resepsi_tanggal", e.target.value))} type="date" name="resepsi-date" required defaultValue={moment().add(1, 'days').format("yyyy-MM-DD")} autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Mulai)
                                                        <input onChange={((e) => payloadHandler("resepsi_waktu_mulai", e.target.value))} type="time" name="resepsi-time" defaultValue="10:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Waktu Pelaksanaan (Selesai)
                                                        <input onChange={((e) => payloadHandler("resepsi_waktu_selesai", e.target.value))} type="time" name="resepsi-time" defaultValue="12:00" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Lokasi Acara
                                                        <input onChange={((e) => payloadHandler("resepsi_lokasi", e.target.value))} type="text" name="resepsi-location" required placeholder="Lokasi acara resepsi" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Link Google Maps (Undangan Premium Bisa Menyusul)
                                                        <input onChange={((e) => payloadHandler("resepsi_gmaps", e.target.value))} type="text" name="resepsi-gmaps" placeholder="Harus diawali 'https://' dan tanpa nama lokasi/tempat" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>


                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Pemesan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Nama Pemesan
                                                        <input onChange={((e) => payloadHandler("pemesan_nama", e.target.value))} type="text" name="order-name" required placeholder="Nama pemesan undangan" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">No Telp/WA
                                                        <input onChange={((e) => payloadHandler("pemesan_telp", e.target.value))} type="text" name="resepsi-time" placeholder="No Telp/WA yang bisa dihubungi" required autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Akun Instagram
                                                        <input onChange={((e) => payloadHandler("pemesan_instagram", e.target.value))} type="text" name="text" required placeholder="@username" autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label className="js-input-group">Membuat Undangan Sebagai?<br />&nbsp;
                                                    <select onChange={((e) => payloadHandler("pemesan_sebagai", e.target.value))} name="choose-design" required>
                                                                            <option value="">— Pilih salah satu</option>
                                                                            <option value="mempelai_pria">Mempelai Pria</option>
                                                                            <option value="mempelai_wanita">Mempelai Wanita</option>
                                                                            <option value="keluarga">Keluarga</option>
                                                                            <option value="panitia">Panitia/Wedding Organizer</option>
                                                                        </select>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Data Galeri</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">⭐ Galeri foto (bisa menyusul, kuota 6 foto)
                                                        <input onChange={((e) => payloadHandler("galeri_foto", e.target.value))} type="text" name="photo-gallery" placeholder="Share foto berupa link Google Drive, Dropbox, dll." autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">⭐ Galeri Video (bisa menyusul, kuota 1 video jika memilih fitur video)
                                                        <input onChange={((e) => payloadHandler("galeri_video", e.target.value))} type="text" name="video-galeri" placeholder="Share video berupa link Youtube, Google Drive, Dropbox, dll." autoComplete="off" />
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Pilihan Fitur Tambahan</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { guestNameHandler(e.target.checked); payloadHandler("fitur_guest_name", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Nama tamu undangan (Berikan nama untuk tamu. Misal: Kepada Yth. <em>Nama Tamu Undangan</em>)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { coverPhotoHandler(e.target.checked); payloadHandler("fitur_cover_photo", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Cover Photo (foto berukuran besar yang terlihat pertama kali saat undangan dibuka)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { rsvpOnlyHandler(e.target.checked); payloadHandler("fitur_rsvp_only", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ RSVP & Guestbook (di web undangan saja <strong>tanpa</strong> laporan Excel)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { rsvpReportHandler(e.target.checked); payloadHandler("fitur_rsvp_excel", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ RSVP & Guestbook (di web undangan <strong>ditambah</strong> laporan Excel)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { socialMediaHandler(e.target.checked); payloadHandler("fitur_media_sosial", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Link media sosial kedua mempelai (Instagram, Facebook, WhatsApp, Twitter)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { countDownHandler(e.target.checked); payloadHandler("fitur_countdown", e.target.checked) })} type="checkbox" checked /> &nbsp; Hitung mundur (countdown timer)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { videoGalleryHandler(e.target.checked); payloadHandler("fitur_galeri_video", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Galeri Video (1 video)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { backSoundHandler(e.target.checked); payloadHandler("fitur_backsound", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Musik latar belakang
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { loveStoryHandler(e.target.checked); payloadHandler("fitur_love_story", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Love Story
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { qrCodeHandler(e.target.checked); payloadHandler("fitur_cashless", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Amplop non-tunai via QR Code (kode disediakan sendiri oleh pemesan)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { googleCalendarHandler(e.target.checked); payloadHandler("fitur_gcalendar", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Simpan ke Google Calendar
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { liveStreamingHandler(e.target.checked); payloadHandler("fitur_live_stream", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Wedding Live Streaming (Zoom, Google Meet, Instagram, Youtube Live, dll)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { barCodeHandler(e.target.checked); payloadHandler("fitur_barcode", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Buku Tamu Digital (tamu cukup scan QR Code tanpa perlu menulis di buku tamu)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { photoSpaceHandler(e.target.checked); payloadHandler("fitur_kuota_galeri", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Tambah kuota galeri (+10 foto)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { sessionHandler(e.target.checked); payloadHandler("fitur_sesi", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Buat undangan dalam banyak sesi tanpa batasan (misal Sesi 1 pukul 10:00 - 11:00, Sesi 2 pukul 11:00 - 12:00)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input onClick={((e) => { customColorHandler(e.target.checked); payloadHandler("fitur_custom_warna", e.target.checked) })} type="checkbox" /> &nbsp; ⭐ Kustomisasi & Revisi Unlimited (Sesuaikan konten undangan sesuai keinginanmu tanpa batasan jumlah revisi)
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Lain-lain</h2>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Catatan
                                                                        <textarea onChange={((e) => payloadHandler("catatan", e.target.value))} rows="3" placeholder="Tulis catatan untuk kami (jika ada)"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-xl-10">
                                                                    <div className="sectionHeading -sm">
                                                                        <h2 className="sectionHeading__title">Total Biaya: <span className="text-accent">{(totalPrice === 0) ? "(Gunakan Voucher Lembayung)" : "Rp" + NumberWithComma(totalPrice)}</span></h2>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 ajax-form-alert js-ajax-form-alert">
                                                                    <div className="ajax-form-alert__content">
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">
                                                                        <input type="checkbox" required /> &nbsp; Dengan ini saya menyetujui <span className="text-accent"><a href="/terms-conditions" target="_blank" rel="noreferrer">Syarat & Ketentuan</a></span> yang berlaku.
                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <button type="submit" className="button -md -black text-white">Buat Undangan</button>
                                                                </div>

                                                                {
                                                                    (quotaFull) ?
                                                                        <>
                                                                            <div className="col-12" style={{ "padding": "50px", "backgroundColor": "#cccccc", "marginTop": "20px", "lineHeight": "1.5em" }}>
                                                                                <h4>Masukkan Kode <span className="text-accent">Voucher Lembayung</span></h4><br />
                                                                                <p>Silakan masukkan kode Voucher Lembayung yang sudah dimiliki pada kolom di bawah ini.</p>
                                                                                <br /><br />
                                                                                <p style={{ "lineHeight": "1.2em" }}>Kode voucher:</p>
                                                                                <input onChange={((e) => { kodeVoucher = e.target.value; payloadHandler("kode_voucher", e.target.value); })} type="text" name="video-galeri" placeholder="Masukkan kode voucher Lembayung" autoComplete="off" />
                                                                                <p className="text-accent">{voucherStatus}</p>
                                                                                <br /><br />
                                                                                <button className="button -md -black text-white" onClick={voucherBtnHandler}>Gunakan Voucher</button>
                                                                                <br /><br />
                                                                                <p style={{ "lineHeight": "1.2em" }}>Belum punya voucher? Dengan Rp50.000 (Voucher Lembayung) kamu bisa hilangkan batasan kuota ini selamanya dan membuat undanganmu sekarang juga.</p>
                                                                                <br />
                                                                                <p style={{ "lineHeight": "1.2em" }}><a className="button -sm -outline-black text-black" href="/voucher" target="_blank">Beli voucher sekarang!</a></p>
                                                                            </div>

                                                                        </>
                                                                        : null
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                            </div>
                        </section>

                        <Footer />
                    </main>
                </div>
            </div>
        </>
    )
}

export default CreateInvitationWithLimit;
import LoadingBar from "react-top-loading-bar";
import Tabletop from "tabletop";
import moment from 'moment';

import "./style.css";
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

let shownGuest = "";
let weddingOf = "";

function LayarSapa() {
    const [noIdError, setNoIdError] = useState(false);
    const [guestName, setGuestName] = useState("");
    const [sheetId, setSheetId] = useState("");
    const [greetingTxt, setGreetingTxt] = useState("");

    const requestGuestList = ((id) => {
        setInterval(() => {
            Tabletop.init({
                key: id,
                callback: googleData => {
                    if ("Scanner" in googleData) {
                        let data = googleData["Scanner"].elements;
                        let lastGuest = data[data.length - 1];
                        if (lastGuest.nama_tamu !== shownGuest) {
                            setGuestName(lastGuest.nama_tamu);
                            shownGuest = lastGuest.nama_tamu
                            setGreetingTxt("Selamat Datang");
                            var elem = document.getElementById('elem');

                            elem.classList.remove('show');
                            void elem.offsetWidth;
                            elem.classList.add('show');
                        }
                    }
                },
                simpleSheet: false
            })
        }, 3000);
    })

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        if ((params.get("wedding")).includes("-")) {
            weddingOf = (params.get('wedding')).split("-")[0] + " & " + (params.get('wedding')).split("-")[1];
        }
    }, [])


    useEffect(() => {
        let path = (window.location.pathname).split("/")[(window.location.pathname).split("/").length - 1];
        if ((path === "scanner") || (path === "") || (path === " ")) {
            setNoIdError(true)
        } else if (path.length <= 10) {
            setNoIdError(true)
        } else {
            setSheetId(path)
        }
    })


    return (
        <>
            <Helmet>
                <title>Layar Sapa by Vanilatte</title>
                <meta name="description" content="Gunakan layanan ini untuk menyapa tamu setelah scan QR Code!" />
            </Helmet>
            <video autoPlay muted loop id="myVideo">
                <source src="/assets/global/wedding/layarSapaBgVideo.mp4" type="video/mp4" />
            </video>
            <div className="layarsapa-title">&nbsp;<br />&nbsp;<br />&nbsp;<br /><h1>{weddingOf}</h1><br /><h3>&middot; {moment(new Date()).format("DD MMMM YYYY")} &middot;</h3></div>
            <div className="layarsapa-wrapper">
                <div className="layarsapa-content">
                    {
                        (noIdError) ?
                            <div className="error">
                                <h1>Error!</h1>
                                <h2>Layar Sapa belum dikonfigurasi.</h2>
                            </div>
                            :
                            <>
                                <button id="thisButton" onClick={() => { requestGuestList(sheetId); document.getElementById("thisButton").remove() }}><i className="fa fa-desktop"></i> &nbsp; Gunakan Layar Sapa</button>
                                <div id="elem">
                                    <h2>{greetingTxt}</h2>
                                    <h1>{guestName}</h1>
                                </div>
                            </>
                    }
                </div>
            </div>
            <p className="layarsapa-footer">Supported by &nbsp; <i className="fa fa-instagram"></i> Vanilatte.Wedding</p>
        </>
    )
}

export default LayarSapa;
import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";

function NotFound404() {
    return (
        <>
            <Helmet>
                <title>Invitation Not Found :(</title>
                <meta name="description" content="The invitation you are looking for is not found :(" />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">


                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        <Navbar showLogo={false} />

                        <section className="page-404">
                            <div className="page-404-bg">404</div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 offset-xl-1 col-md-8">
                                        <div className="page-404-content">
                                            <h1 className="page-404-title">Invitation Not Found 💔</h1>
                                            <p className="page-404-text leading-4xl mt-24 md:mt-16">The wedding invitation you were looking for doesn't exist. Have you typed the address correctly?</p>
                                            <a className="page-404-btn button -md -outline-black text-black mt-32 md:mt-20" data-barba href="/">Take me home</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </main>

                </div>
            </div>
        </>
    )
}

export default NotFound404;
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import Tabletop from "tabletop";
import moment from "moment";
import "moment/locale/id";

import Audio from "../../components/audio";
import Covidmodal from "../../components/covidModal";
import { SRLWrapper } from "simple-react-lightbox";

let guestName = "";
const gbUrl =
  "https://sheet.best/api/sheets/38ffa5b8-f680-417c-8900-127a8db4ac37";
const gbKey = "1yiO5E72VwrQ2FHRVGCOJ7cTlTgDO2ZY7D-apwy1e7-Q";

function IikHamid() {
  const [gbName, setGbName] = useState("");
  const [gbPhone, setGbPhone] = useState("-");
  const [gbAtt, setGbAtt] = useState("");
  const [gbPerson, setGbPerson] = useState("0");
  const [gbMsg, setGbMsg] = useState("");
  const [gbDisable, setGbDisable] = useState(false);
  const [gbSent, setGbSent] = useState(false);
  const [showGbMsg, setShowGbMsg] = useState(false);
  const [gbMsgList, setGbMsgList] = useState([]);
  const [gbWaiting, setGbWaiting] = useState(false);

  const requestGuestBook = () => {
    Tabletop.init({
      key: gbKey,
      callback: (googleData) => {
        let data = googleData;
        if ("Guestbook" in data) {
          data = data["Guestbook"].elements;
          data.reverse();
          setGbMsgList(data);
          setGbWaiting(false);
        }
      },
      simpleSheet: false,
    });
  };

  useState(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let kepada = params.get("kepada");
    if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
  });

  const messageList = gbMsgList.map((data, id) => {
    return (
      <div key={id}>
        <div className="comment-item">
          <p className="comment-name">
            {data.name}{" "}
            <span className="comment-time">
              {" "}
              &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp;{" "}
              {moment(data.date).fromNow()}
            </span>
          </p>
          <p className="comment-message">{data.message}</p>
        </div>
      </div>
    );
  });

  const gbSubmitHandler = (e) => {
    e.preventDefault();
    setGbDisable(true);
    const payload = {
      date: new Date(),
      name: gbName,
      phone: gbPhone,
      attendance: gbAtt,
      person: gbPerson,
      message: gbMsg,
    };

    axios.post(gbUrl, payload).then((response) => {
      setGbDisable(false);
      setGbSent(true);
    });
  };
  return (
    <>
      <Helmet>
        <title>The Wedding of Iik & Hamid</title>
        <meta
          name="description"
          content="The Wedding of Iik & Hamid | Premium Wedding Invitation by Vanilatte"
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        {/* <script src="/assets/template/artavika/js/plugins.js"></script> */}
        {/* <script src="/assets/template/artavika/js/modernizr.js"></script> */}
        {/* <script src="/assets/template/artavika/js/pace.min.js"></script> */}
        <link href="/assets/global/header-cta.css" rel="stylesheet" />
        <link href="/assets/global/cursor.css" rel="stylesheet" />
        <link rel="stylesheet" href="/assets/template/artavika/css/base.css" />
        <link
          rel="stylesheet"
          href="/assets/template/artavika/css/vendor.css"
        />
        <link rel="stylesheet" href="/assets/clients/IikHamid/css/main.css" />
        <link
          rel="stylesheet"
          href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css"
        />
        {/* <script src="/assets/template/artavika/js/main.js"></script> */}
        <script
          src="/assets/global/countdown.js"
          date="2023-2-1 11:00"
        ></script>
        <script src="/assets/global/disabler.js"></script>
        <script src="/assets/global/cursor.js"></script>
      </Helmet>
      {/* <Covidmodal /> */}
      <Audio music="zack-tabudlo.m4a" />
      <div className="cursor"></div>
      <section id="home">
        <div className="overlay"></div>
        <div className="gradient-overlay"></div>
        <div className="home-content-table">
          <div className="home-content-tablecell">
            <div className="row">
              <div className="col-twelve">
                <div className="home-bottom-text">
                  <h3 animation-delay="200" animation-type="fadeInUp">
                    The Wedding of
                  </h3>
                  <h1 animation-delay="500" animation-type="fadeInDown">
                    Iik & Hamid
                  </h1>
                  <ul className="home-section-links">
                    <li
                      animation-delay="800"
                      animation-type="zoomIn"
                      className="kepada"
                    >
                      {guestName}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#about" className="scroll-icon smoothscroll">
          <span className="icon-DownArrow"></span>
        </a>
      </section>
      <section id="about">
        <div className="about-profile-bg"></div>
        <ul className=" corner-ul">
          <li id="top-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="top-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
        </ul>
        <div className="about-wrap">
          <div className="about-profile">
            <div className="intro">
              <h3 animation-delay="200" animation-type="fadeInDown">
                Hamid
              </h3>
              <h1 animation-delay="400" animation-type="fadeInLeft">
                Hamid Abdillah, S.I.Kom.
              </h1>
              <div className="profile-pic-polaroid">
                <LazyLoadImage
                  animation-delay="300"
                  animation-type="fadeInUp"
                  src="/assets/clients/IikHamid/images/iik-hamid-groom-2.JPG"
                  alt="Groom"
                />
                <p animation-delay="200" animation-type="fadeInUp">
                  <br />
                  Putra keempat dari
                  <br />
                  <strong>Bp. Nasukha & Ibu Khusniah</strong> <br />
                  <em>Wedung Demak Jawa Tengah</em>
                </p>
              </div>
            </div>
          </div>
          <div className="about-services">
            <div className="intro">
              <h3 animation-delay="200" animation-type="fadeInDown">
                Iik
              </h3>
              <h1 animation-delay="400" animation-type="fadeInRight">
                Fajar Sri Rejeki, S.Sos.
              </h1>
              <div className="profile-pic-polaroid">
                <LazyLoadImage
                  animation-delay="600"
                  animation-type="fadeInUp"
                  src="/assets/clients/IikHamid/images/iik-hamid-bride-2.JPG"
                  alt="Bride"
                />
                <p animation-delay="200" animation-type="fadeInUp">
                  <br />
                  Putri tunggal dari
                  <br />
                  <strong>Bp. Ponijo & Ibu Ngatinem</strong> <br />
                  <em>
                    Tanuditan RT07, Dk. Kweden, Trirenggo, Bantul, Yogyakarta
                  </em>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="skills">
        <div className="overlay"></div>
        <div className="gradient-overlay"></div>
        <div className="row wide">
          <div className="col-skills">
            <p animation-delay="200" animation-type="fadeInDown">
              <em>
                Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
                pasangan-pasangan untukmu dari jenismu sendiri, agar kamu
                cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di
                antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian
                itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum
                yang berpikir. ― QS Ar-Rum:21
              </em>
            </p>
            <div className="section-intro">
              <br />
              &nbsp;
              <LazyLoadImage
                animation-delay="200"
                animation-type="zoomIn"
                className="center-thing"
                src="/assets/template/artavika/images/grass.png"
                alt="Separator"
              />
              <br />
              &nbsp;
              <h1 animation-delay="200" animation-type="fadeInUp">
                Turut Mengundang
              </h1>
              <p animation-delay="200" animation-type="fadeInUp">
                Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud
                mengundang Bapak/Ibu/Saudara/i pada pernikahan putra & putri
                kami yang akan diselenggarakan pada:
              </p>
              <h3 animation-delay="200" animation-type="fadeInDown">
                Akad Nikah
              </h3>
              <p animation-delay="200" animation-type="fadeInUp">
                Rabu, 1 Februari 2023
                <br />
                11:00 - 12:00 WIB
                <br />
                <a
                  className="map-url"
                  href="https://goo.gl/maps/e5WzNyHymRCrfuA46"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="lnr lnr-map-marker"></span> Rumah Mempelai
                  Wanita
                </a>
              </p>
              &nbsp;
              <h3 animation-delay="200" animation-type="fadeInDown">
                Resepsi
              </h3>
              <p animation-delay="200" animation-type="fadeInUp">
                Minggu, 5 Februari 2023
                <br />
                12:00 - 13:00 WIB
                <br />
                <a
                  className="map-url"
                  href="https://goo.gl/maps/bLGqe51w5NVMGN3j6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="lnr lnr-map-marker"></span> Balai Kalurahan
                  Trirenggo Bantul Yogyakarta
                </a>
              </p>
            </div>
            &nbsp;
            <a
              animation-delay="200"
              animation-type="fadeInUp"
              className="google-calendar"
              href="https://www.google.com/calendar/render?action=TEMPLATE&text=Resepsi+Pernikahan+Iik+%26+Hamid&details=Detail+undangan+bisa+dilihat+di+https%3A%2F%2Falbum.love%2FIikHamidMengundang&location=Kapanewon%2C+Jl.+Ir.+H.+Juanda+No.17%2C+Trirenggo%2C+Kec.+Bantul%2C+Kabupaten+Bantul%2C+Daerah+Istimewa+Yogyakarta+55714&dates=20230205T050000Z%2F20230205T060000Z"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-calendar-o"></i> &nbsp; Simpan di Google
              Calendar
            </a>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="row narrow section-intro">
          <div className="col-twelve">
            <h1 animation-delay="200" animation-type="fadeInUp" className="">
              Peta Lokasi
            </h1>
            <p animation-delay="200" animation-type="fadeInUp" className="lead">
              Gunakan peta di bawah ini untuk mencari rute ke lokasi resepsi
              pernikahan kami
            </p>
            <iframe
              animation-delay="400"
              animation-type="zoomIn"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.9695886100453!2d110.3411584!3d-7.898246000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a558c3498310d%3A0x43197c179ea89c9b!2sTrirenggo%20Districf%20Office!5e0!3m2!1sen!2sid!4v1672103748353!5m2!1sen!2sid"
              width="100%"
              height="600"
              style={{ border: "0;" }}
              allowfullscreen=""
              loading="lazy"
              title="Wedding Location"
            ></iframe>
            <center>
              <a
                animation-delay="200"
                animation-type="fadeInUp"
                className="google-calendar"
                href="https://goo.gl/maps/PEtt5h7Q32ZSGM2W9"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah
              </a>
            </center>
          </div>
        </div>
        <ul className=" corner-ul">
          <li id="top-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="top-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
        </ul>
      </section>
      <section id="credentials">
        {/* <LazyLoadImage animation-delay="200" animation-type="zoomIn" className="center-thing divider-cred" src="/assets/template/artavika/images/divider.png" alt="Separator" /> */}
        <br />
        &nbsp;
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3 animation-delay="200" animation-type="fadeInUp" className="">
              The Love Story of
            </h3>
            <h1 animation-delay="200" animation-type="fadeInUp" className="">
              Iik & Hamid
            </h1>
            <p animation-delay="200" animation-type="fadeInUp" className="lead">
              You come to love not by finding the perfect person, but by seeing
              an imperfect person perfectly.
            </p>
          </div>
        </div>
        <div className="row wide credentials-content">
          <div className="with-bottom-sep">
            <br />
            &nbsp;
            <br />
            &nbsp;
          </div>
          <div className="col-six tab-full left">
            <div className="timeline-wrap">
              <div className="timeline-block ">
                <div
                  animation-delay="200"
                  animation-type="zoomIn"
                  className="timeline-ico"
                >
                  <span className="lnr lnr-user"></span>
                </div>
                <div className="timeline-header">
                  <p
                    animation-delay="200"
                    animation-type="fadeInUp"
                    className="timeframe"
                  >
                    Februari 2022
                  </p>
                  <h3 animation-delay="200" animation-type="fadeInUp">
                    Keluarga Kami Bertemu
                  </h3>
                </div>
                <div
                  animation-delay="200"
                  animation-type="fadeInUp"
                  className="timeline-content"
                >
                  <p>
                    Sejak pertama kali aku memandangnya di kejauhan, ada sesuatu
                    yang mengatakan padaku bahwa dia tercipta untukku. Kuajak
                    keluargaku bertemu dengan keluarganya.
                  </p>
                </div>
              </div>
              <div className="timeline-block ">
                <div
                  animation-delay="200"
                  animation-type="zoomIn"
                  className="timeline-ico"
                >
                  <span className="lnr lnr-users"></span>
                </div>
                <div className="timeline-header">
                  <p
                    animation-delay="200"
                    animation-type="fadeInUp"
                    className="timeframe"
                  >
                    September 2022
                  </p>
                  <h3 animation-delay="200" animation-type="fadeInUp">
                    Aku Melamarnya!
                  </h3>
                </div>
                <div className="timeline-desc">
                  <p animation-delay="200" animation-type="fadeInUp">
                    Kudatangi orang tuanya dan kukatakan dengan lantang bahwa
                    aku akan menjaganya sepanjang sisa hidupku.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-six tab-full right">
            {/* <h2 className="">Education</h2> */}
            <div className="timeline-wrap">
              <div className="timeline-block ">
                <div
                  animation-delay="200"
                  animation-type="zoomIn"
                  className="timeline-ico"
                >
                  <span className="lnr lnr-heart"></span>
                </div>
                <div className="timeline-header">
                  <p
                    animation-delay="200"
                    animation-type="fadeInUp"
                    className="timeframe"
                  >
                    1 Februari 2023
                  </p>
                  <h3 animation-delay="200" animation-type="fadeInUp">
                    Hari Bahagia
                  </h3>
                </div>
                <div className="timeline-content">
                  <p animation-delay="200" animation-type="fadeInUp">
                    Kami sepakat untuk memilih hari ini sebagai hari bahagia
                    kami berdua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        {/* <LazyLoadImage animation-delay="200" animation-type="zoomIn" className="center-thing divider-cred" src="/assets/template/artavika/images/divider.png" alt="Separator" /> */}
      </section>

      <section id="contact">
        <div className="overlay"></div>
        <ul className=" corner-ul">
          <li id="top-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="top-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
        </ul>
        <div className="row narrow section-intro">
          <div className="col-twelve">
            <h1 animation-delay="200" animation-type="fadeInDown">
              Menuju Hari Bahagia
            </h1>
            <p
              animation-delay="200"
              animation-type="fadeInUp"
              className="lead "
            >
              Whenever I look at you, I see the rest of my life in front of my
              eyes.
            </p>
            <ul className="countdown text-center text-muted">
              <li animation-delay="200" animation-type="zoomIn">
                <span id="days"></span>hari
              </li>
              <li animation-delay="400" animation-type="zoomIn">
                <span id="hours"></span>jam
              </li>
              <li animation-delay="600" animation-type="zoomIn">
                <span id="minutes"></span>menit
              </li>
              <li animation-delay="800" animation-type="zoomIn">
                <span id="seconds"></span>detik
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="portfolio">
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3 animation-delay="200" animation-type="fadeInDown">
              Gallery of
            </h3>
            <h1 animation-delay="200" animation-type="fadeInUp">
              Iik & Hamid
            </h1>
            <p
              animation-delay="200"
              animation-type="fadeInUp"
              className="lead "
            >
              I hope you know that every time I tell you to get home safe, stay
              warm, have a good day, or sleep well what I am really saying is I
              love you. I love you so damn much that it is starting to steal
              other words’ meanings.
            </p>
          </div>
        </div>
        <div className="row portfolio-content">
          <div className="col-twelve">
            <div className="bricks-wrapper">
              <SRLWrapper>
                <div className="brick folio-item gallery-pic">
                  <img
                    animation-delay="200"
                    animation-type="zoomIn"
                    src="/assets/clients/IikHamid/images/iik-hamid-gallery-1.jpg"
                    alt="Gallery"
                  />
                </div>
                <div className="brick folio-item gallery-pic">
                  <img
                    animation-delay="400"
                    animation-type="zoomIn"
                    src="/assets/clients/IikHamid/images/iik-hamid-gallery-2.jpg"
                    alt="Gallery"
                  />
                </div>
                <div className="brick folio-item gallery-pic">
                  <img
                    animation-delay="200"
                    animation-type="zoomIn"
                    src="/assets/clients/IikHamid/images/iik-hamid-gallery-3.jpg"
                    alt="Gallery"
                  />
                </div>
                <div className="brick folio-item gallery-pic">
                  <img
                    animation-delay="400"
                    animation-type="zoomIn"
                    src="/assets/clients/IikHamid/images/iik-hamid-gallery-4.jpg"
                    alt="Gallery"
                  />
                </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="overlay"></div>
        <ul className=" corner-ul">
          <li id="top-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="top-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-right" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
          <li id="bottom-left" className="parent-wrapper corner-li">
            <div className="corner-flex">
              <LazyLoadImage
                src="/assets/template/artavika/images/corner.png"
                alt="corner"
                className="bg-image js-lazy"
              />
            </div>
          </li>
        </ul>
        <div className="row narrow section-intro">
          <div className="col-twelve">
            <LazyLoadImage
              animation-delay="200"
              animation-type="fadeInUp"
              src="/assets/template/artavika/images/grass.png"
              alt="Divider"
            />
            <br />
            &nbsp;
            <p
              animation-delay="200"
              animation-type="fadeInDown"
              className="lead "
            >
              Merupakan suatu kehormatan dan kebahagiaan bagi kami jika
              Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.
              <br />
              <br />
              Hormat kami,
            </p>
            <h1 animation-delay="200" animation-type="fadeInUp" className="">
              Iik & Hamid
            </h1>
            <br />
            &nbsp;
            <LazyLoadImage
              animation-delay="200"
              animation-type="fadeInDown"
              src="/assets/template/artavika/images/grass.png"
              alt="Divider"
            />
          </div>
        </div>
      </section>

      <section id="portfolio">
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3 animation-delay="200" animation-type="fadeInDown">
              We really appreciate it
            </h3>
            <h1 animation-delay="200" animation-type="fadeInUp">
              Sumbangan Digital
            </h1>
            <p animation-delay="200" animation-type="fadeInUp">
              Untuk meminimalisir kontak fisik, kami menerima sumbangan dalam
              bentuk digital yang bisa ditransfer ke rekening berikut ini
            </p>
            <h3 animation-delay="200" animation-type="fadeInUp">
              Mandiri:
            </h3>
            <h4 animation-delay="200" animation-type="fadeInUp">
              900-00-2752229-0
            </h4>
            <h3 animation-delay="200" animation-type="fadeInUp">
              BRI:
            </h3>
            <h4 animation-delay="200" animation-type="fadeInUp">
              1522-01-001529-53-3
            </h4>
          </div>
        </div>
      </section>

      <footer>
        <div className="row">
          <div className="col-twelve tab-full">
            <div className="copyright">
              <span>
                Iik &amp; Hamid &middot; Premium Wedding Invitation by{" "}
                <a
                  href="https://vanilatte.com/"
                  alt="Premium Wedding Invitation"
                >
                  Vanilatte.com
                </a>
              </span>
              <br />
              &nbsp;
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default IikHamid;

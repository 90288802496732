import { Helmet } from "react-helmet";

import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Footer from "./footer";
import Navbar from "./navbar";
import HelmetTemplate from "./helmet";

function Home() {
    return (
        <>
            <Helmet>
                <title>Vanilatte - Buat Undangan Pernikahan Hanya 1 Menit</title>
                <meta property="og:site_name" content="Vanilatte" />
                <meta property="og:title" content="Vanilatte" />
                <meta
                    property="og:description"
                    content="Klik untuk buat undangan pernikahan hanya dalam waktu 1 menit. Uji coba GRATIS!"
                />
                <meta
                    property="og:image"
                    itemprop="image"
                    content="https://vanilatte-og-image.vercel.app/api/web?text=Buat+Undangan+Nikah+Hanya+Satu+Menit"
                />
            </Helmet>
            <DynamicScrollToTop />
            <HelmetTemplate />
            <div className="preloader-visible" data-barba="wrapper">

                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>
                    <main className="">
                        <Navbar showLogo={true} showMenu={true} />
                        <section className="masthead -type-2 js-masthead js-masthead-type-2">
                            <div className="masthead-shapes -group-3">
                                <div className="masthead-shapes__item -item-1">
                                    <div className="masthead-shapes__shape -white -shadow-light js-shape"></div>
                                </div>
                                <div className="masthead-shapes__item -item-2">
                                    <div className="masthead-shapes__shape -white -shadow-light js-shape"></div>
                                </div>
                                <div className="masthead-shapes__item -item-3">
                                    <div className="masthead-shapes__shape -white -shadow-light js-shape"></div>
                                </div>
                            </div>

                            <div className="container-fluid px-24">
                                <div className="row justify-content-center">
                                    <div className="col-md-9">
                                        <div className="masthead__content z-3">
                                            <div data-split="lines" data-split-page-reveal>
                                                <h1 className="masthead__title fw-800 js-title">
                                                    <span className="text-accent">1 Menit</span><br />
                                                    Buat Undangan
                                                </h1>
                                            </div>

                                            <div data-split="lines" data-split-page-reveal>
                                                <p className="masthead__text mt-40 md:mt-32 ml-56 sm:ml-0 js-text">
                                                    Kini sudah bukan jamannya <br />
                                                    menunggu berhari-hari untuk<br />
                                                    membuat undangan pernikahan.
                                                </p>
                                            </div>

                                            <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                <div className="js-button">
                                                    <a href="/ask" className="button -md -outline-black text-black" style={{ background: '#128C7E', color: '#fff', border: 'none' }}><i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ui-element -bottom-left js-ui">
                                <div className="ui-element__social text-black">
                                    <a className="overflow-hidden" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram js-ui-item"></i></a>
                                    <a className="overflow-hidden" href="/ask"><i className="fa fa-whatsapp js-ui-item"></i></a>
                                </div>
                            </div>

                            <div className="ui-element -bottom sm:d-none js-ui">
                                <button type="button" className="ui-element__scroll text-black js-ui-scroll-button">
                                    scroll down
                                    <i className="icon" data-feather="arrow-down"></i>
                                </button>
                            </div>
                        </section>


                        <section className="layout-pt-lg layout-pb-sm">
                            <div className="container">

                                <div className="row">
                                    <div className="col-xl-5 col-lg-8 col-md-10">
                                        <div className="sectionHeading -lg">
                                            <p className="sectionHeading__subtitle">
                                                Coba Secara Gratis
                                            </p>
                                            <h2 className="sectionHeading__title">
                                                Kami sediakan untukmu <span className="text-accent">&hearts;</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row x-gap-60 y-gap-48 layout-pt-md">
                                    <div className="col-lg-20 col-lg-8">
                                        <p className="mt-16">Manfaatkan layanan uji coba pembuatan undangan pernikahan secara gratis dan cepat. Kamu bisa lakukan pembayaran setelah undanganmu jadi.</p>
                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                            <div className="js-button">
                                                <a href="/ask" className="button -md -outline-black text-black" style={{ background: '#128C7E', color: '#fff', border: 'none' }}><i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid px-0">
                                <div className="row x-gap-60 y-gap-48 layout-pt-md no-gutters align-items-center">

                                    <div className="col-lg-6 z-1">
                                        <div className="ratio ratio-1:1" data-parallax="0.7">
                                            <div data-parallax-target className="bg-image js-lazy" data-bg="/assets/home/img/backgrounds/mid-bg-ring.jpg"></div>
                                        </div>
                                    </div>


                                    <div className="col-xl-4 col-lg-5 offset-lg-1 md:mt-48 z-2">
                                        <div className="sectionHeading -sm md:container pt-16">
                                            <div className="ml-minus-col-2 md:ml-0">
                                                <h2 className="sectionHeading__title fw-700 leading-md"><span className="text-accent">Kecepatan</span> adalah nafas kami. Kami sangat menghargai <span className="text-accent">waktu</span> yang ada.</h2>
                                            </div>

                                            <div className="row x-gap-32 y-gap-60 layout-pt-md">
                                                <div className="col-lg-20 col-lg-8">
                                                    <p className="mt-16">Dengan teknologi yang sudah kami siapkan, kami tidak akan menyita <span className="text-accent">waktu & pikiranmu</span> secara berlebihan.</p>
                                                    <br />
                                                    <div className="js-button">
                                                        <a href="/ask" className="button -md -outline-black text-black" style={{ background: '#128C7E', color: '#fff', border: 'none' }}><i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <section className="layout-pt-lg layout-pb-xl">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-xl-20">
                                        <div className="sectionHeading -lg">
                                            <p className="sectionHeading__subtitle">Kenapa Vanilatte?</p>
                                            <h2 className="sectionHeading__title">
                                                Ini alasan kenapa mereka <span className="text-accent">percaya</span> pada kami
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row x-gap-60 y-gap-48 layout-pt-md"></div>

                                <div data-anim-wrap className="row x-gap-48 y-gap-48">

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="serviceCard">
                                            <div className="serviceCard__content">
                                                <div className="d-flex align-items-center ml-minus-4">
                                                    <div className="px-20 py-20 bg-white shadow-light rounded-full">
                                                        <i className="size-md str-width-md text-accent" data-feather="edit"></i>
                                                    </div>
                                                </div>

                                                <h3 className="serviceCard__title text-2xl fw-500 text-black mt-40 md:mt-32">Album of Love</h3>

                                                <p className="serviceCard__text mt-20 md:mt-12">Undanganmu bisa diakses melalui tautan cantik &amp; mudah diingat: <span className="text-accent"><strong>album.love</strong></span></p>

                                                {/* <div className="mt-24 md:mt-16">
                                                    <a data-barba href="/terms-conditions" className="button -icon text-black">
                                                        Learn More
                                                        <i className="icon size-xs str-width-md" data-feather="arrow-right"></i>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="serviceCard">
                                            <div className="serviceCard__content">
                                                <div className="d-flex align-items-center ml-minus-4">
                                                    <div className="px-20 py-20 bg-white shadow-light rounded-full">
                                                        <i className="size-md str-width-md text-accent" data-feather="sliders"></i>
                                                    </div>
                                                </div>

                                                <h3 className="serviceCard__title text-2xl fw-500 text-black mt-40 md:mt-32">Aktif Selamanya</h3>
                                                <p className="serviceCard__text mt-20 md:mt-12">Kamu bisa mengunjungi undangan pernikahanmu kapanpun, bahkan ketika kamu sudah memiliki buah hati kelak!</p>

                                                {/* <div className="mt-24 md:mt-16">
                                                    <a data-barba href="/terms-conditions" className="button -icon text-black">
                                                        Learn More
                                                        <i className="icon size-xs str-width-md" data-feather="arrow-right"></i>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="serviceCard">
                                            <div className="serviceCard__content">
                                                <div className="d-flex align-items-center ml-minus-4">
                                                    <div className="px-20 py-20 bg-white shadow-light rounded-full">
                                                        <i className="size-md str-width-md text-accent" data-feather="feather"></i>
                                                    </div>
                                                </div>

                                                <h3 className="serviceCard__title text-2xl fw-500 text-black mt-40 md:mt-32">Cepat &amp; Ringan</h3>
                                                <p className="serviceCard__text mt-20 md:mt-12">Kami merancang agar undangan bisa diakses dengan cepat &amp; ringan. Sangat berguna bagi tamu undangan yang memiliki ponsel dengan spesifikasi rendah.</p>

                                                {/* <div className="mt-24 md:mt-16">
                                                    <a data-barba href="/terms-conditions" className="button -icon text-black">
                                                        Learn More
                                                        <i className="icon size-xs str-width-md" data-feather="arrow-right"></i>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div className="serviceCard">
                                            <div className="serviceCard__content">
                                                <div className="d-flex align-items-center ml-minus-4">
                                                    <div className="px-20 py-20 bg-white shadow-light rounded-full">
                                                        <i className="size-md str-width-md text-accent" data-feather="move"></i>
                                                    </div>
                                                </div>

                                                <h3 className="serviceCard__title text-2xl fw-500 text-black mt-40 md:mt-32">1 Menit Jadi</h3>
                                                <p className="serviceCard__text mt-20 md:mt-12">Semakin cepat undangan jadi, semakin banyak waktu yang kamu miliki untuk mempersiapkan bagian lain dari pernikahanmu.</p>

                                                {/* <div className="mt-24 md:mt-16">
                                                    <a data-barba href="/terms-conditions" className="button -icon text-black">
                                                        Learn More
                                                        <i className="icon size-xs str-width-md" data-feather="arrow-right"></i>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <section>
                            <div className="container">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-6">
                                        <div className="sectionHeading -md">
                                            <p className="sectionHeading__subtitle">Katalog</p>
                                            <h2 className="sectionHeading__title"><span className="text-accent">Desain</span> <br />Undangan Pernikahan</h2>
                                            <p className="text-lg sm:text-base mt-24">Pilihan tema undangan yang bisa kamu pilih. Klik pada gambar untuk melihat contoh undangan.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row x-gap-60 y-gap-48 layout-pt-md"></div>
                            </div>
                            <div className="container-wide">
                                <div className="masonry -col-3 -gap-32 js-masonry js-masonry-no-filter">
                                    <div className="masonry__sizer"></div>

                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/larasati-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Larasati</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR1.250.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaLarasati?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>

                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/agna-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Agna</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR750.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaAgna?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>


                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/gayatri-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Gayatri</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR1.350.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaGayatri?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>
                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/kinanthi-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Kinanthi</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR1.350.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaKinanthi?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>
                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/maharani-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Maharani</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR1.350.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaMaharani?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>
                                    <div className="masonry__item ">
                                        <div className="portfolioCard -type-2 -hover ratio">
                                            <div className="portfolioCard__img">
                                                <div className="portfolioCard__img__inner" data-anim="img-right cover-dark-1">
                                                    <div className="bg-image js-lazy" data-bg="/assets/home/img/template/dalu-square.png"></div>
                                                </div>
                                            </div>

                                            <div className="portfolioCard__content">
                                                <p className="portfolioCard__category text-light mb-8">Desain Undangan Pernikahan</p>
                                                <h3 className="portfolioCard__title text-2xl fw-600 text-white">Dalu</h3>
                                                <br />&nbsp;
                                                {/* <p className="portfolioCard__category text-light mb-8">IDR1.350.000</p> */}
                                            </div>

                                            <a className="portfolioCard__link" data-barba target="_blank" rel="noreferrer" href="https://album.love/i/TemaDalu?kepada=Prof.+Dr.+Nama+Tamu+Undangan,+M.Sc."></a>
                                        </div>
                                    </div>


                                </div>
                                <div className="row justify-content-center">
                                    <div className="js-button">
                                        <br />&nbsp;
                                        <a href="/ask" className="button -md -outline-black text-black" style={{ background: '#128C7E', color: '#fff', border: 'none' }}><i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="layout-pt-lg layout-pb-lg">
                            <div className="container">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-6">
                                        <div className="sectionHeading -md">
                                            <p className="sectionHeading__subtitle">Testimonial</p>
                                            <h2 className="sectionHeading__title">Apa Kata<span className="text-accent">&nbsp;Mereka</span></h2>
                                            <p className="text-lg sm:text-base mt-24">Ungkapan cinta dari pasangan yang sudah melabuhkan cinta mereka</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row x-gap-60 y-gap-48 layout-pt-md"></div>
                            </div>
                            <div className="container">
                                <div data-cursor data-cursor-label="DRAG" className="overflow-hidden js-section-slider" data-slider-col="base-1 lg-1 md-1 sm-1" data-pagination data-loop>

                                    <div className="pagination -dark js-pagination pt-4 mb-48"></div>

                                    <div className="swiper-wrapper">

                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            Layanan 24 jam deh kayaknya. Jam berapapun fast respon adminnya🤗🤗 adminnya sabaaaar bgt
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/KAM8665d1d" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-1.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Anisa &amp; Huda</h5>
                                                            <p className="capitalize">Our happy couple</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            Bener bener ga nyangka pas swipe" ig story nemu ig ads nya vanilatte untuk bikin undangan website gratis, kebetulan lagi kepengen bikin undangan online dg budget yang minim. Aa thank u so much vanilatte :))
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/SELec618f5" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-1.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Dinda &amp; Ferry</h5>
                                                            <p className="capitalize">Our happy couple</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            bagus banget yaa setidaknya bisa membantu buat aku yg udah cetak undangan tapi kurang hehe alhasil pake vanilatte, dan tetap sopan pastinya, minvanya baik banget ramah masyaallah, sukses usahanya kaka
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/f0f1f" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-1.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Titis &amp; Apri</h5>
                                                            <p className="capitalize">Our happy couple</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            Semoga memberikan kemudahan untuk mempelai yang mempunyai minim buget
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/c236d" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-3.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Ika &amp; David</h5>
                                                            <p className="capitalize">Our happy couple</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            Sangat cepat.. Hanya perlu isi data dan "bimsalabim", undangan jadi dalam sekejap mata!! Design undangan juga beraneka ragam yang semuanya bagus2.. Suka..
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/6f2ba" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-2.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Intan &amp; Burhan</h5>
                                                            <p className="capitalize">Our happy couple</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="testimonials text-center">
                                                        <p className="testimonials__comment text-black">
                                                            Seneng banget ada fitur gratis untuk undangan digital ini, sangat membantu untuk mengirim undangan tamu dengan jarak jauh 🥰 dan mimin responnya sangat baik 🥰
                                                        </p>
                                                        <div className="masthead__button overflow-hidden mt-48 md:mt-40 sm:mt-40 ml-56 sm:ml-0">
                                                            <div className="js-button">
                                                                <a href="https://album.love/wedding/SEL0b75604" className="button -md -outline-black text-black">Lihat Undangan Mereka</a>
                                                            </div>
                                                        </div>
                                                        <div className="testimonials__author mt-48">
                                                            {/* <div className="size-xl mx-auto mb-20">
                                                                <div className="bg-image rounded-full swiper-lazy" data-background="/assets/home/img/testimonials/testimonial-2.jpg"></div>
                                                            </div> */}

                                                            <h5 className="text-lg capitalize fw-600">Andyny &amp; Bayu</h5>
                                                            <p className="capitalize">Our happy couple</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>

                        <section data-parallax="0.7" className="layout-pt-lg layout-pb-lg">
                            <div data-parallax-target className="overlay-black-md bg-image js-lazy" data-bg="/assets/home/img/backgrounds/wedding-ring-2.jpg"></div>

                            <div className="container z-5">
                                <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                        <p className="text-sm uppercase tracking-lg text-white mb-20">
                                            Masih ragu?
                                        </p>

                                        <h2 className="text-5xl sm:text-5xl xs:text-4xl leading-sm fw-700 text-white">
                                            Dapatkan Uji Coba Secara Gratis
                                        </h2>

                                        <p className="text-xl md:text-lg text-white mt-16">
                                            Dengan menghubungi kami melalui WhatsApp
                                        </p>

                                        <a href="/ask" target="_blank" rel="noreferrer" className="button -md -white text-black mt-32" style={{ background: '#128C7E', color: '#fff', border: 'none' }}>
                                            <i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="layout-pt-md layout-pb-lg">
                            <div className="container">

                                <div className="row align-items-end justify-content-between">
                                    <div className="col-xl-6">
                                        <div className="sectionHeading -md">
                                            <p className="sectionHeading__subtitle">
                                                Partner
                                            </p>
                                            <h2 className="sectionHeading__title">
                                                Kerjasama dengan yang terbaik di bidangnya
                                            </h2>
                                        </div>
                                    </div>


                                </div>
                                <div className="row y-gap-32 layout-pt-md">

                                    <div className="col-lg-3 col-sm-6">
                                        <div data-anim="slide-up delay-1" className="clientsItem -hover ratio ratio-3:2 border-dark rounded-4 py-4">
                                            <div className="clientsItem__img">
                                                <img className="col-7" src="/assets/home/img/clients/vintesia-logo.png" alt="Client" />
                                            </div>

                                            <div className="clientsItem__content">
                                                <a href="https://vintesia.id" target="_blank" rel="noreferrer">
                                                    <h5 className="clientsItem__title text-xl text-white">Vintesia Photography</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div data-anim="slide-up delay-1" className="clientsItem -hover ratio ratio-3:2 border-dark rounded-4 py-4">
                                            <div className="clientsItem__img">
                                                <img className="col-7" src="/assets/home/img/clients/saktiara-logo.png" alt="Client" />
                                            </div>

                                            <div className="clientsItem__content">
                                                <a href="https://instagram.com/saktiara_mua" target="_blank" rel="noreferrer">
                                                    <h5 className="clientsItem__title text-xl text-white">Saktiara MUA</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div data-anim="slide-up delay-1" className="clientsItem -hover ratio ratio-3:2 border-dark rounded-4 py-4">
                                            <div className="clientsItem__img">
                                                <img className="col-7" src="/assets/home/img/clients/giano-logo.png" alt="Client" />
                                            </div>

                                            <div className="clientsItem__content">
                                                <a href="http://gianophotography.com" target="_blank" rel="noreferrer">
                                                    <h5 className="clientsItem__title text-xl text-white">Giano Photography</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-6">
                                        <div data-anim="slide-up delay-1" className="clientsItem -hover ratio ratio-3:2 border-dark rounded-4 py-4">
                                            <div className="clientsItem__img">
                                                <img className="col-7" src="/assets/home/img/clients/bridestory-logo.png" alt="Client" />
                                            </div>

                                            <div className="clientsItem__content">
                                                <a href="https://www.bridestory.com/vanilatte-com" target="_blank" rel="noreferrer">
                                                    <h5 className="clientsItem__title text-xl text-white">Bridestory</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
            </div>
        </>
    )
}

export default Home;
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { SRLWrapper } from "simple-react-lightbox";
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";
import QrCodeGenerator from "../scanner/qrCodeGenerator";

let guestName = "";
let kepada = "";
let sesi = "10:30 - 11:30";
let params = "";
const gbUrl = "https://sheet.best/api/sheets/4c710e37-2a98-48c3-b55f-feaf08dd8c9f";
const gbKey = "1z9V516fi8qKX4whhUbG-lyELcVSHzVn2BkVazFnYqig";

function PanjiFarida() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showLoveStory, setShowLoveStory] = useState(false);
    const [showGaleri, setShowGaleri] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);
    const [showLiveStream, setShowLiveStream] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useEffect(() => {
        if (!showGbMsg) requestGuestBook();
    })

    useState(() => {
        let search = window.location.search;
        params = new URLSearchParams(search);

        kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
        if (kepada != null) setShowQrCode(true);

        let sesiParam = params.get('q');
        if (sesiParam === "2") sesi = "11:30 - 12:30"
        // if (sesiParam === "3") sesi = "19:30 - 20:30"
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: ((gbAtt === "yes") && (gbPerson === "0")) ? "1" : gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Panji & Farida</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                {/* <meta property="og:image" content="assets/clients/panjifarida/images/groombride.jpg" />
                <link rel="apple-touch-icon" href="assets/clients/panjifarida/images/groombride.jpg" /> */}
                <link rel="stylesheet" href="assets/clients/panjifarida/assets/css/main.css" />

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" />
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <script src="/assets/clients/panjifarida/assets/js/countdown.js" date="2021-07-04 09:00"></script>
                <script src="/assets/global/yt-no-repeat.js"></script>
                <script src="/assets/global/cursor.js"></script>
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="You_Are_The_Reason_-_Saxophone.mp3" />
            {/* <CovidModal /> */}
            <div className="cursor"></div>
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>Panji & Farida</h1>
                                    <p className="guest-name">{guestName}</p>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Undangan</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Lokasi</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLiveStream(!showLiveStream) }}>Live Stream</button></li>
                                    {/* <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Amplop Digital</button></li> */}
                                    {/* <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Galeri</button></li> */}
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Guestbook</button></li>
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                <h2 className="major">Kami yang Berbahagia</h2>
                                <span className="image main"><img src="assets/clients/panjifarida/images/2.JPG" alt="" /></span>
                                <div className="text-center">

                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing"   >Farida Nugraheni, S.Tr.Keb</h2>
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri pertama dari<br /><strong>Bp. Marsidi & <br />Ibu Mujiyem, S.Pd.,M.Pd.Si</strong> <br /><em>Ngentak Dadapan RT 05 Sidomulyo, Bambanglipuro, Bantul, D.I.Yogyakarta</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">dr. Panji Arga Bintara</h2>
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra pertama dari<br /><strong>Bp. Drs. Johan Bintara & <br />Ibu Siti Nurnaeni, S.Pd.SD</strong> <br /><em>Gumantar RT 001 RW 002 Tanjung, Juwiring, Klaten</em></p>
                                    <hr />
                                    <p className="prayer">Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.<br /><em>― QS Ar-Rum:21</em></p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                <h2 className="major">Turut Mengundang</h2>
                                <span className="image main"><img src="assets/clients/panjifarida/images/1.JPG" alt="" /></span>
                                <div className="text-center">
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Akad</h1>
                                    <h3 className="">Minggu <br />4 / Juli / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/panjifarida/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">09:00 - 10:00 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/panjifarida/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">UD. Jati Kencana, Ngentak Dadapan RT 05 Sidomulyo, Bambanglipuro, Bantul, D.I.Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Akad+Nikah+Panji+%26+Farida&details=Detail+selengkapnya+bisa+dilihat+di+https://album.love/PernikahanFaridaPanji&location=-7.969183790697065,+110.30648280409048&dates=20210704T020000Z/20210704T030000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1 >Resepsi</h1>
                                    <h3 className="">Minggu <br />4 / Juli / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/panjifarida/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">{sesi} WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/panjifarida/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">UD. Jati Kencana, Ngentak Dadapan RT 05 Sidomulyo, Bambanglipuro, Bantul, D.I.Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/panjifarida/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://www.google.com/calendar/render?action=TEMPLATE&text=Resepsi+Pernikah+Panji+%26+Farida&details=Detail+selengkapnya+bisa+dilihat+di+https%3A%2F%2Falbum.love%2FPernikahanFaridaPanji&location=-7.969183790697065%2C+110.30648280409048&dates=20210704T033000Z%2F20210704T053000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    {
                                        (showQrCode) ?
                                            <>
                                                <QrCodeGenerator
                                                    value={kepada}
                                                    bgColor="#ffffff"
                                                    fgColor="#000000"
                                                    size={256}
                                                />
                                                <hr />
                                            </>
                                            : null
                                    }
                                    <p className="prayer">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                <span className="image main"><img src="assets/clients/panjifarida/images/4.JPG" alt="" /></span>
                                <div className="text-center">
                                    <br /> &nbsp;
                                    <h2>LOKASI PERNIKAHAN</h2>
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi pernikahan kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.2861216663937!2d110.30431021477922!3d-7.969353794260195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7b00220347767d%3A0x982edeb5917a6677!2sUD.Jati%20Kencana!5e0!3m2!1sen!2sid!4v1623636714533!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x2e7b00220347767d:0x982edeb5917a6677?utm_source=mstt_1" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major">RSVP & Guestbook</h2>
                                <span className="image main"><img src="assets/clients/panjifarida/images/5.JPG" alt="" /></span>
                                <div className="text-center">
                                    {
                                        (gbSent) ?
                                            <>
                                                <br />
                                                <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                            </>
                                            :
                                            <>
                                                <p   >Kami memohon kesediaannya untuk mengisi buku tamu di bawah ini 😊</p>
                                                <form onSubmit={((e) => gbSubmitHandler(e))}>
                                                    <fieldset>
                                                        <div >
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div >
                                                                <select onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                                    <option value="" disabled selected>Apakah bisa hadir?</option>
                                                                    <option value="yes">Ya, hadir</option>
                                                                    <option value="no">Maaf, tidak bisa hadir</option>
                                                                    <option value="maybe">Belum tahu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div >
                                                            {
                                                                (gbAtt === "yes") ?
                                                                    <>
                                                                        <select onChange={((e) => setGbPerson(e.target.value))} required className="gb-input">
                                                                            <option value="1" selected>1 orang</option>
                                                                            <option value="2">2 orang</option>
                                                                        </select>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div >
                                                            <div >
                                                                <textarea disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <br />
                                                                <button disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </>
                                    }
                                    <br />&nbsp;
                                    <div className="gb-wrap">
                                        {messageList}
                                    </div>
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showLoveStory) ?
                        <Fade in={showLiveStream} mountOnEnter unmountOnExit {...(showLiveStream ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major">Live Stream</h2>
                                <span className="image main"><img src="assets/clients/panjifarida/images/6.JPG" alt="" /></span>
                                <h2 className="text-center">Menuju Hari Bahagia</h2>
                                <ul className="countdown text-center">
                                    <li  ><span id="days"></span>hari</li>
                                    <li  ><span id="hours"></span>jam</li>
                                    <li  ><span id="minutes"></span>menit</li>
                                    <li  ><span id="seconds"></span>detik</li>
                                </ul>
                                <hr />

                                <p className="text-center">Pernikahan kami akan ditayangkan secara <em>live</em>, silakan tekan tombol di bawah ini saat Hari-H untuk turut menjadi saksi pernikahan kami:</p>
                                <h1 className="text-center">
                                    <a href="https://instagram.com/helloaoru" target="_blank" rel="noreferrer" alt="Instagram Live"><i className="fa fa-instagram"></i></a> &nbsp;
                                </h1>
                                <br />&nbsp;


                                <hr />
                                <p className="prayer text-center">❤ &nbsp; True love stories never have endings. &nbsp; ❤</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLiveStream(!showLiveStream) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation" target="_blank" rel="noreferrer">Vanilatte.com</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default PanjiFarida;
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { SRLWrapper } from "simple-react-lightbox";
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";

let guestName = "";
let params = "";
const gbUrl = "https://sheet.best/api/sheets/38ffa5b8-f680-417c-8900-127a8db4ac37";
const gbKey = "1yiO5E72VwrQ2FHRVGCOJ7cTlTgDO2ZY7D-apwy1e7-Q";

function Dalu() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showLoveStory, setShowLoveStory] = useState(false);
    const [showGaleri, setShowGaleri] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useEffect(() => {
        if (!showGbMsg) requestGuestBook();
    })

    useState(() => {
        let search = window.location.search;
        params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>Dalu | Vanilatte</title>
                <meta name="description" content="Dalu Premium Wedding Invitation by Vanilatte" />
                <link rel="stylesheet" href="assets/template/dalu/assets/css/main.css" />
                {/* <script src="assets/clients/dianfariz/assets/js/jquery.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/browser.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/breakpoints.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/util.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/main.js"></script> */}

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" />
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <script src="/assets/clients/dianfariz/assets/js/countdown.js" date="2021-6-6 09:00"></script>
                <script src="/assets/global/yt-no-repeat.js"></script>
                <script src="/assets/global/cursor.js"></script>
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            {/* <CovidModal /> */}
            <div className="cursor"></div><div id="notification-bar">
                <div className="notification-container">
                    <p>Dalu</p>
                    <a className="btn-action" href="/order" target="_blank" rel="noreferrer"><span className="lnr lnr-cart">&nbsp;Order</span></a>
                </div>
            </div>
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>Bandung & Roro</h1>
                                    <p className="kepada">{guestName}</p>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Undangan</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Lokasi</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Love Story</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Galeri</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Guestbook</button></li>
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                <h2 className="major">Kami yang Berbahagia</h2>
                                <span className="image main"><img src="assets/global/img/home-img.jpg" alt="" /></span>
                                <div className="text-center">

                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">Roro Jonggrang</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri kedua dari<br /><strong>Prabu Boko & Ny. Boko</strong> <br /><em>Kerajaan Boko</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">Bandung Bondowoso</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra pertama dari<br /><strong>Prabu Damar Moyo & Ny. Damar Moyo</strong> <br /><em>Kerajaan Pengging</em></p>
                                    <hr />
                                    <p className="prayer">Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.<br /><em>QS Ar-Rum:21</em></p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                <h2 className="major">Turut Mengundang</h2>
                                <span className="image main"><img src="assets/global/img/gallery-pic-8.jpg" alt="" /></span>
                                <div className="text-center">
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Akad</h1>
                                    <h3 className="">Sabtu <br />1 / Januari / 2022</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">09:00 - 10:00 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Gd. Pertemuan Candi Prambanan, <br />Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Pernikahan+Bandung+Bondowoso+%26+Roro+Jonggrang&dates=20220101T080000/20220101T120000&details=Detail+acara+bisa+dilihat+di+https://vanilatte.com/&location=Prambanan+Temple,+Jl.+Raya+Solo+-+Yogyakarta+No.16,+Kranggan,+Bokoharjo,+Kec.+Prambanan,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55571,+Indonesia&pli=1&sf=true" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Resepsi</h1>
                                    <h3 className="">Sabtu <br />1 / Januari / 2022</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">10:30 - 12:30 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Gd. Pertemuan Candi Prambanan, <br />Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Pernikahan+Bandung+Bondowoso+%26+Roro+Jonggrang&dates=20220101T080000/20220101T120000&details=Detail+acara+bisa+dilihat+di+https://vanilatte.com/&location=Prambanan+Temple,+Jl.+Raya+Solo+-+Yogyakarta+No.16,+Kranggan,+Bokoharjo,+Kec.+Prambanan,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55571,+Indonesia&pli=1&sf=true" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <p className="prayer">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                <div className="text-center">
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.355933734317!2d110.48927871477791!3d-7.752020594412814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5ae3dbd859d1%3A0x19e7a03b25955a2d!2sPrambanan%20Temple!5e0!3m2!1sen!2sid!4v1616122382843!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://www.google.com/maps/place/Prambanan+Temple/@-7.7520206,110.4892787,17z/data=!3m1!4b1!4m5!3m4!1s0x2e7a5ae3dbd859d1:0x19e7a03b25955a2d!8m2!3d-7.7520206!4d110.4914674" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major">RSVP & Guestbook</h2>
                                <span className="image main"><img src="assets/global/img/gallery-pic-8.jpg" alt="" /></span>
                                <div className="text-center">
                                    {
                                        (gbSent) ?
                                            <>
                                                <br />
                                                <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                            </>
                                            :
                                            <>
                                                <p   >Kami memohon kesediaannya untuk mengisi buku tamu di bawah ini 😊</p>
                                                <form onSubmit={((e) => gbSubmitHandler(e))}>
                                                    <fieldset>
                                                        <div >
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div >
                                                                <select onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                                    <option value="" disabled selected>Apakah bisa hadir?</option>
                                                                    <option value="yes">Ya, hadir</option>
                                                                    <option value="no">Maaf, tidak bisa hadir</option>
                                                                    <option value="maybe">Belum tahu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div >
                                                            {
                                                                (gbAtt === "yes") ?
                                                                    <>
                                                                        <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                            <option value="1" selected>1 orang</option>
                                                                            <option value="2-4">2-4 orang</option>
                                                                            <option value=">5">{">"} 5 orang</option>
                                                                        </select>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div >
                                                            <div >
                                                                <textarea disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <br />
                                                                <button disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </>
                                    }
                                    <br />&nbsp;
                                    <div className="gb-wrap">
                                        {messageList}
                                    </div>
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showGaleri) ?
                        <Fade in={showGaleri} mountOnEnter unmountOnExit {...(showGaleri ? { timeout: 1500 } : {})}>
                            <article id="galeri">
                                <h2 className="major">Galeri</h2>
                                <div className="text-center">
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="">Video</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <span className="image main"><div className="hytPlayerWrapOuter"> <div className="hytPlayerWrap"> <iframe src="https://www.youtube.com/embed/6C1qylN2PjQ?controls=1&rel=0&enablejsapi=1" allowFullScreen frameBorder="0" title="Engagement Video" ></iframe> </div></div></span>
                                    <br />&nbsp;
                                    <hr />
                                    <br />&nbsp;
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="">Foto</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;

                                    <SRLWrapper>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-1.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-2.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-3.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-4.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-5.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-6.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-7.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/global/img/gallery-pic-8.jpg" alt="" /></span>
                                    </SRLWrapper>

                                    <hr />
                                    <p className="prayer text-center">❤ &nbsp; You are every reason, every hope and every dream I’ve ever had. &nbsp; ❤</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Close</div>
                            </article>
                        </Fade>
                        // {/* : null */}
                    }

                    {
                        // (showLoveStory) ?
                        <Fade in={showLoveStory} mountOnEnter unmountOnExit {...(showLoveStory ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major">Our Love Story</h2>
                                <span className="image main"><img src="assets/global/img/gallery-pic-7.jpg" alt="" /></span>
                                <h2 className="text-center">&middot; Menuju Hari Bahagia &middot; </h2>
                                <ul className="countdown text-center">
                                    <li  ><span id="days"></span>hari</li>
                                    <li  ><span id="hours"></span>jam</li>
                                    <li  ><span id="minutes"></span>menit</li>
                                    <li  ><span id="seconds"></span>detik</li>
                                </ul>
                                <hr />
                                <br />&nbsp;

                                <h5>&middot; Juli 2015 &middot; </h5>
                                <h4>Pertemuan Pertama</h4>
                                <blockquote>Sejak pertama kali aku memandangnya di kejauhan, ada sesuatu yang mengatakan padaku bahwa dia tercipta untukku.</blockquote>
                                <hr />
                                <h5>&middot; September 2015 &middot; </h5>
                                <h4>Dia Malu, Tapi Mau</h4>
                                <blockquote>Malam minggu itu, aku bertanya apakah dia bersedia menemaniku ke acara ulang tahun sahabatku. Saat itu hanya aku saja yang tidak punya pasangan.</blockquote>
                                <hr />
                                <h5>&middot; November 2015 &middot; </h5>
                                <h4>Awal Perjalanan</h4>
                                <blockquote>Masih kuingat dengan jelas merah pipinya ketika kutanya apakah dia mau jadi kekasihku.</blockquote>
                                <hr />
                                <h5>&middot; Januari 2016 &middot; </h5>
                                <h4>Film Bioskop Pertama</h4>
                                <blockquote>Aku tidak ingat bagaimana jalan cerita film yang kutonton waktu itu, karena pandanganku selalu tertuju padanya.</blockquote>
                                <hr />
                                <h5>&middot; Februari 2018 &middot; </h5>
                                <h4>Aku Menyiapkan Sesuatu</h4>
                                <blockquote>Sudah memikirkannya matang-matang, aku berharap semoga dia adalah tambatan terakhir bagiku.</blockquote>
                                <hr />
                                <h5>&middot; Maret 2018 &middot; </h5>
                                <h4>Dia Katakan, "Ya!"</h4>
                                <blockquote>Ketika aku bertanya, <em>"maukah kamu menikah denganku?"</em> tidak perlu waktu lebih dari sedetik baginya untuk menjawab, <em>"Ya!"</em></blockquote>
                                <hr />
                                <h5>&middot; Desember 2019 &middot; </h5>
                                <h4>Aku Melamarnya!</h4>
                                <blockquote>Kudatangi orang tuanya dan kukatakan dengan lantang bahwa aku akan menjaganya sepanjang sisa hidupku.</blockquote>
                                <hr />
                                <h5>&middot; 1 Januari 2022 &middot; </h5>
                                <h4>Hari Bahagia</h4>
                                <blockquote>Kami sepakat untuk memilih hari ini sebagai hari bahagia kami berdua.</blockquote>

                                <hr />
                                <p className="prayer text-center">❤ &nbsp; True love stories never have endings. &nbsp; ❤</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Dalu &middot; Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation" target="_blank" rel="noreferrer">Vanilatte.com</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default Dalu;
import { Helmet } from "react-helmet";
import Typewriter from "typewriter-effect";
import { useState, useEffect } from "react";
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import Rellax from "rellax";

import Audio from "../../components/audio";
import Covidmodal from "../../components/covidModal";
import { SRLWrapper } from "simple-react-lightbox";

let guestName = "";
const gbUrl = "https://sheet.best/api/sheets/38ffa5b8-f680-417c-8900-127a8db4ac37";
const gbKey = "1yiO5E72VwrQ2FHRVGCOJ7cTlTgDO2ZY7D-apwy1e7-Q";


function Nawala() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    useEffect(() => {
        new Rellax(".rellax", {})
    })
    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>Nawala | Vanilatte</title>
                <meta name="description" content="Nawala Premium Wedding Invitation by Vanilatte" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="/assets/template/nawala/css/style.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inconsolata" />

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <script src="/assets/global/countdown.js" date="2022-1-1 08:00"></script>
                <script src="/assets/global/cursor.js"></script>
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link href="/assets/template/gendhis/css/bootstrap.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            {/* <Covidmodal /> */}
            <div className="cursor"></div>
            <div id="notification-bar">
                <div className="notification-container">
                    <p>Nawala</p>
                    <a className="btn-action" href="/order" target="_blank" rel="noreferrer"><span className="lnr lnr-cart">&nbsp;Order</span></a>
                </div>
            </div>
            <header className="w3-display-container w3-grayscale-min" id="home">
                <div className="bgimg"></div>
                <div className="w3-display-middle w3-center">
                    <span animation-delay="200" animation-type="fadeInUp" className="w3-text-white" style={{ "fontSize": "20px", "letterSpacing": ".2em" }}>THE WEDDING OF</span>
                    <span className="w3-text-white" style={{ "fontSize": "50px" }}>
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString("Bandung & Roro")
                                    .start();
                            }}
                        /></span>
                    <br />&nbsp;
                    <span animation-delay="400" animation-type="fadeInUp" className="w3-text-white kepada" style={{ "fontSize": "20px" }}>{guestName}</span>
                </div>
                {/* <div className="w3-display-bottomright w3-center w3-padding-large">
                    <span animation-delay="200" animation-type="fadeInUp" className="w3-text-white">Sabtu / 1 / Januari / 2021</span>
                </div> */}
            </header>
            <div class="custom-shape-divider-bottom" style={{ "transform": "scaleY(-1)" }}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="w3-sand w3-large">
                <div className="w3-container" id="about">
                    <div className="w3-content" >
                        <br />&nbsp;
                        <p animation-delay="200" animation-type="fadeInUp" >Maha Suci Tuhan yang telah menciptakan pasangan-pasangan semuanya, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka maupun dari apa yang tidak mereka ketahui. ~ <em>QS Yasin: 36</em></p>
                    </div>
                </div>
                <div className="w3-center">
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="zoomIn" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                </div>
                <div className="w3-container">
                    <div className="w3-content" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">KAMI YANG BERBAHAGIA</span></h5>
                        <div class="row">
                            <div class="column">
                                <img animation-delay="200" animation-type="fadeInUp" className="happy-couple" src="/assets/global/img/groom.jpg" alt="groom" />
                                <br />&nbsp;
                                <br />&nbsp;
                                <a animation-delay="1000" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram" className="social-media-icon"><i className="fa fa-instagram"></i></a> &nbsp;
                                <a animation-delay="1200" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Twitter" className="social-media-icon"><i className="fa fa-twitter"></i></a> &nbsp;
                                <a animation-delay="1400" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp" className="social-media-icon"><i className="fa fa-whatsapp" ></i></a>
                                <br />&nbsp;
                                <h4 animation-delay="200" animation-type="fadeInUp">Bandung Bondowoso</h4>
                                <p animation-delay="200" animation-type="zoomIn">Putra pertama dari<br /><em>Prabu Damar Moyo & Ny. Damar Moyo</em><br />Kerajaan Pengging</p>
                            </div>
                            <div class="column">
                                <img animation-delay="400" animation-type="fadeInUp" className="happy-couple" src="/assets/global/img/bride.jpg" alt="bride" />
                                <br />&nbsp;
                                <br />&nbsp;
                                <a animation-delay="1600" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram" className="social-media-icon"><i className="fa fa-instagram"></i></a> &nbsp;
                                <a animation-delay="1800" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Twitter" className="social-media-icon"><i className="fa fa-twitter"></i></a> &nbsp;
                                <a animation-delay="2000" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp" className="social-media-icon"><i className="fa fa-whatsapp"></i></a>
                                <br />&nbsp;
                                <h4 animation-delay="200" animation-type="fadeInUp">Roro Jonggrang</h4>
                                <p animation-delay="200" animation-type="zoomIn">Putri ketiga dari<br /><em>Prabu Boko & Ny. Boko</em><br />Kerajaan Boko</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-center">
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="zoomIn" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                </div>

                <div className="w3-container">
                    <div className="w3-content w3-center" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">TURUT MENGUNDANG</span></h5>
                        <p animation-delay="200" animation-type="fadeInUp">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada acara pernikahan kami yang akan diselenggarakan pada:</p>
                        <p className=""><strong animation-delay="300" animation-type="fadeInUp" >Akad:</strong><br /> <span animation-delay="300" animation-type="fadeInUp" >Sabtu, 1 Januari 2021 </span><br /><span animation-delay="300" animation-type="fadeInUp" >09:00-09:30 WIB </span><br /> <span animation-delay="300" animation-type="fadeInUp" >Gd. Pertemuan Candi Prambanan, Yogyakarta</span></p>
                        <p className=""><strong animation-delay="300" animation-type="fadeInUp" >Resepsi:</strong><br /> <span animation-delay="300" animation-type="fadeInUp" >Sabtu, 1 Januari 2021 </span><br /><span animation-delay="300" animation-type="fadeInUp" >10:00-12:00 WIB </span><br /> <span animation-delay="300" animation-type="fadeInUp" >Gd. Pertemuan Candi Prambanan, Yogyakarta</span></p>
                        <br />
                        <a animation-delay="200" animation-type="fadeInUp" href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Pernikahan+Bandung+Bondowoso+%26+Roro+Jonggrang&dates=20220101T080000/20220101T120000&details=Detail+acara+bisa+dilihat+di+https://vanilatte.com/&location=Prambanan+Temple,+Jl.+Raya+Solo+-+Yogyakarta+No.16,+Kranggan,+Bokoharjo,+Kec.+Prambanan,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55571,+Indonesia&pli=1&sf=true" target="_blank" rel="noreferrer" className="button"><i className="fa fa-calendar"></i> &nbsp; Simpan ke Google Calendar</a>
                        <br /> &nbsp;
                        <br /> &nbsp;
                        <p animation-delay="200" animation-type="fadeInUp" className="" style={{ "fontSize": "smaller" }}>Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan tetap mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                    </div>
                </div>

                <div className="w3-center">
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="fadeInUp" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                </div>
                <div className="w3-container">
                    <div className="w3-content w3-center" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">LOKASI ACARA</span></h5>
                        <p animation-delay="200" animation-type="fadeInUp">Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami</p>
                        <iframe animation-delay="200" animation-type="fadeInUp" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.355933734317!2d110.48927871477791!3d-7.752020594412814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5ae3dbd859d1%3A0x19e7a03b25955a2d!2sPrambanan%20Temple!5e0!3m2!1sen!2sid!4v1616122382843!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                        <br />&nbsp;
                        <br />&nbsp;
                        <a animation-delay="200" animation-type="fadeInUp" href="https://www.google.com/maps/place/Prambanan+Temple/@-7.7520206,110.4892787,17z/data=!3m1!4b1!4m5!3m4!1s0x2e7a5ae3dbd859d1:0x19e7a03b25955a2d!8m2!3d-7.7520206!4d110.4914674" target="_blank" rel="noreferrer" className="button"><i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah</a>
                        <br />&nbsp;
                        <br />&nbsp;
                    </div>
                </div>
                <div className="w3-center">
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="fadeInUp" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                </div>

                <div className="w3-container">
                    <div className="w3-content w3-center" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">Menuju Hari Bahagia</span></h5>
                        <ul className="countdown text-muted">
                            <li animation-delay="300" animation-type="zoomIn"><span id="days"></span>hari</li>
                            <li animation-delay="500" animation-type="zoomIn"><span id="hours"></span>jam</li>
                            <li animation-delay="700" animation-type="zoomIn"><span id="minutes"></span>menit</li>
                            <li animation-delay="900" animation-type="zoomIn"><span id="seconds"></span>detik</li>
                        </ul>
                        <p animation-delay="200" animation-type="fadeInUp">I swear I couldn't love you more than I do right now, and yet I know I will tomorrow.</p>
                    </div>
                </div>

                <div className="w3-container">
                    <div className="w3-content" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">OUR LOVE STORY</span></h5>
                        <p animation-delay="200" animation-type="fadeInUp">One paragraph is not enough for me to tell you just how much you mean to me. It would take me thousands of pages to tell you just how much I love you. If I spent the rest of eternity writing, I still could not accurately portray how amazing you are and all of the reasons why I love you.</p>
                        <br />
                        <div class="wrapper">
                            <ul class="sessions">
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">Juli 2015</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Sejak pertama kali aku memandangnya di kejauhan, ada sesuatu yang mengatakan padaku bahwa dia tercipta untukku.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">September 2015</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Malam minggu itu, aku bertanya apakah dia bersedia menemaniku ke acara ulang tahun sahabatku. Saat itu hanya aku saja yang tidak punya pasangan.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">November 2015</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Masih kuingat dengan jelas merah pipinya ketika kutanya apakah dia mau jadi kekasihku.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">Januari 2016</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Aku tidak ingat bagaimana jalan cerita film yang kutonton waktu itu, karena pandanganku selalu tertuju padanya.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">Februari 2018</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Sudah memikirkannya matang-matang, aku berharap semoga dia adalah tambatan terakhir bagiku.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">Maret 2018</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Ketika aku bertanya, <em>"maukah kamu menikah denganku?"</em> tidak perlu waktu lebih dari sedetik baginya untuk menjawab, <em>"Ya!"‍</em></p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">Desember 2019</div>
                                    <p animation-delay="200" animation-type="fadeInUp">Kudatangi orang tuanya dan kukatakan dengan lantang bahwa aku akan menjaganya sepanjang sisa hidupku.</p>
                                </li>
                                <li className="timeline-item">
                                    <div animation-delay="200" animation-type="fadeInUp" class="time">1 Januari 2022 </div>
                                    <p animation-delay="200" animation-type="fadeInUp">Kami sepakat untuk memilih hari ini sebagai hari bahagia kami berdua.</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="w3-center">
                    <br />&nbsp;
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="fadeInUp" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                </div>
                <div className="w3-container">
                    <div className="w3-content w3-center" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">GALERI FOTO & VIDEO</span></h5>
                        <iframe src="https://www.youtube-nocookie.com/embed/6C1qylN2PjQ?controls=0" frameBorder="0" className="video-gallery" allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Pre-wedding Video"></iframe>
                        <br />&nbsp;
                        <SRLWrapper>
                            <div class="row">
                                <div class="column">
                                    <img animation-delay="200" animation-type="fadeInUp" className="gallery-photo" src="/assets/global/img/gallery-pic-3.jpg" alt="groom" />
                                </div>
                                <div class="column">
                                    <img animation-delay="200" animation-type="fadeInUp" className="gallery-photo" src="/assets/global/img/gallery-pic-4.jpg" alt="bride" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <img animation-delay="200" animation-type="fadeInUp" className="gallery-photo" src="/assets/global/img/gallery-pic-5.jpg" alt="groom" />
                                </div>
                                <div class="column">
                                    <img animation-delay="200" animation-type="fadeInUp" className="gallery-photo" src="/assets/global/img/gallery-pic-6.jpg" alt="bride" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <img animation-delay="200" animation-type="fadeInUp" className="gallery-photo" src="/assets/global/img/gallery-pic-7.jpg" alt="groom" />
                                </div>
                                <div class="column">
                                    <img className="gallery-photo" src="/assets/global/img/gallery-pic-8.jpg" alt="bride" />
                                </div>
                            </div>
                        </SRLWrapper>
                    </div>
                </div>
                <div className="w3-center">
                    <br />&nbsp;
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="fadeInUp" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                    <br />&nbsp;
                </div>
                <div className="w3-container">
                    <div className="w3-content" >
                        <p animation-delay="200" animation-type="fadeInUp">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                        <p animation-delay="200" animation-type="fadeInUp" style={{ "textAlign": "right" }}><br /><br /><br />Hormat kami,</p>
                        <h4 animation-delay="200" animation-type="fadeInUp" style={{ "textAlign": "right" }}>Bandung <small>&</small> Roro</h4>
                    </div>
                </div>
                <div className="w3-center">
                    <br />&nbsp;
                    <br />&nbsp;
                    <img animation-delay="200" animation-type="fadeInUp" className="separator-img" src="/assets/template/nawala/img/divider.png" alt="divider" />
                    <br />&nbsp;
                </div>
                <div className="w3-container">
                    <div className="w3-content w3-center" >
                        <h5 animation-delay="200" animation-type="fadeInUp" className="w3-center w3-padding-64"><span className="w3-tag w3-wide">RSVP & Guestbook</span></h5>
                        <p animation-delay="400" animation-type="fadeInUp">Leave us your wishes</p>
                        <br />
                        {
                            (gbSent) ?
                                <>
                                    <br />
                                    <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                </>
                                :
                                <>

                                    <form onSubmit={((e) => gbSubmitHandler(e))}>
                                        <fieldset>
                                            <div >
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div>
                                                <div >
                                                    <select animation-delay="300" animation-type="fadeInUp" onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                        <option value="" disabled selected>Apakah bisa hadir?</option>
                                                        <option value="yes">Ya, hadir</option>
                                                        <option value="no">Maaf, tidak bisa hadir</option>
                                                        <option value="maybe">Belum tahu</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div >
                                                {
                                                    (gbAtt === "yes") ?
                                                        <>
                                                            <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                <option value="1" selected>1 orang</option>
                                                                <option value="2-4">2-4 orang</option>
                                                                <option value=">5">{">"} 5 orang</option>
                                                            </select>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div >
                                                <div >
                                                    <textarea animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <br />
                                                    <button animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </>
                        }
                        <div>
                            <br />&nbsp;
                            <button className="button"
                                animation-delay="300" animation-type="fadeInUp"
                                onClick={((e) => {
                                    e.preventDefault();
                                    setGbWaiting(true);
                                    setShowGbMsg(!showGbMsg);
                                    if (!showGbMsg) requestGuestBook();
                                })} >{(showGbMsg) ? "Tutup" : "Lihat"} Semua Pesan</button>
                        </div>
                        {
                            (showGbMsg) ?
                                <>
                                    {
                                        (gbWaiting) ?
                                            <>
                                                <br />&nbsp;
                                                <p style={{ "textAlign": "center" }}>Mohon tunggu...</p>
                                            </>
                                            :
                                            <div className="gb-wrap">
                                                {messageList}
                                            </div>
                                    }
                                </>
                                :
                                <>
                                </>
                        }
                        <br />&nbsp;
                        <br />&nbsp;
                    </div>
                </div>
            </div>
            <div class="custom-shape-divider-top-1617331936">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                </svg>
            </div>
            <footer className="w3-center w3-padding-48 w3-large">
                <p>Nawala &middot; Premium Wedding Invitation by <a className="social-media-icon" href="https://vanilatte.com/" alt="Vanilatte Wedding e-Invitation Service">Vanilatte.com</a></p>
            </footer>

        </>
    )
}

export default Nawala;
import { useEffect, useState } from "react";
import Fade from '@material-ui/core/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";
import Covidmodal from "../../components/covidModal";


const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];

function Maharani(props) {

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/assets/global/wedding/maharani.css" />
                <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                <script src="/assets/global/wedding/countdown.js" date={props.weddingData.akad_tanggal + "__" + props.weddingData.akad_waktu_mulai}></script>
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            {/* <Covidmodal /> */}
            <Fade
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1000 } : {})}
            >
                <div className="is-loading">

                    <div id="wrapper">
                        <div id="main">
                            <div className="inner">
                                <div id="columns01" className="container columns">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <div>
                                                <h2 id="text02">THE WEDDING OF</h2>
                                                <h1 id="text01">{props.weddingData.nama_panggilan}</h1>
                                                <p id="text03">We invite you to celebrate our wedding</p>
                                            </div>
                                            <div>
                                                <br />
                                                <div id="image01" className="image"><LazyLoadImage src="assets/global/wedding/header-wedding.svg" alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr id="divider01" />
                                <div id="container01" className="container columns">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <div>
                                                <h2 id="text08">{props.weddingData.mempelai_wanita_nama}</h2>
                                                <p id="text09">putri {(props.weddingData.mempelai_wanita_anak_ke).toLowerCase()} dari</p>
                                                <p id="text10"><span>{props.weddingData.mempelai_wanita_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_wanita_ibu}</span></p>
                                                <p id="text11">{props.weddingData.mempelai_wanita_alamat}</p>
                                                <p id="desktop-hide">dengan<br />&nbsp;</p>
                                            </div>
                                            <div>
                                                <h2 id="text05">{props.weddingData.mempelai_pria_nama}</h2>
                                                <p id="text06">putra {(props.weddingData.mempelai_pria_anak_ke).toLowerCase()} dari</p>
                                                <p id="text07"><span>{props.weddingData.mempelai_pria_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_pria_ibu}</span></p>
                                                <p id="text04">{props.weddingData.mempelai_pria_alamat}</p>
                                                <p id="desktop-hide"><br />&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr id="divider02" />
                                {
                                    (props.weddingData.doa !== "x") ?
                                        <>
                                            <p id="text12">{prayer[props.weddingData.doa]}</p>
                                        </>
                                        :
                                        null
                                }
                                <hr id="divider03" />
                                <div id="container02" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <h2 id="text22">TURUT MENGUNDANG</h2>
                                            <p id="text13">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                        </div>
                                    </div>
                                </div>
                                <h2 id="text21">{props.weddingData.jenis_acara}:</h2>
                                <p id="text17"><span>{((props.weddingData.akad_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.akad_waktu_mulai} - {props.weddingData.akad_waktu_selesai}</span><br /> <span>{props.weddingData.akad_lokasi}</span></p>
                                {
                                    ((props.weddingData.akad_gmaps).includes("https://") || (props.weddingData.akad_gmaps).includes("http://")) ?
                                        <div className="center-button">
                                            <a href={props.weddingData.akad_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                        </div>
                                        : null
                                }
                                <div id="image02" className="image"><LazyLoadImage style={{ "maxWidth": "70px" }} className="grass" src="assets/global/wedding/grass.png" alt="" /></div>
                                <h2 id="text14">{props.weddingData.jenis_acara_resepsi}:</h2>
                                <p id="text15"><span>{((props.weddingData.resepsi_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.resepsi_waktu_mulai} - {props.weddingData.resepsi_waktu_selesai}</span><br /> <span>{props.weddingData.resepsi_lokasi}</span></p>
                                {
                                    ((props.weddingData.resepsi_gmaps).includes("https://") || (props.weddingData.resepsi_gmaps).includes("http://")) ?
                                        <div className="center-button">
                                            <a href={props.weddingData.resepsi_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                        </div>
                                        : null
                                }
                                <hr id="divider04" />
                                <p id="text19">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                <hr id="divider07" />
                                <h2 id="text16">MENUJU HARI BAHAGIA</h2>
                                <p id="text18">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                                <div id="timer01" className="timer light-clr">
                                    <ul />
                                </div>
                                <hr id="divider06" />
                                <p id="text20">Buat Undangan Nikah Hanya 1 Menit di <a href="/lembayung" target="_blank" rel="noreferrer">Vanilatte.com</a><br /><br /><br />
                                    <a href="https://vanilatte.com/" target="_blank" rel="noreferrer"><i className="fa fa-globe"></i></a> &nbsp;
                                    <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a> &nbsp;
                                    <a href="/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    )
}

export default Maharani
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import { useState, useEffect } from 'react';

import "./covidModal.css";

function Covidmodal(props) {
    const [covidModal, setCovidModal] = useState(true);
    const [text, setText] = useState(null)
    const modalHandler = (() => {
        setCovidModal(false);

        const myStopFunction = () => {
            clearTimeout(myTimeout);
            setCovidModal(true)
        }
        const myTimeout = setTimeout(myStopFunction, 5000);

        // myStopFunction();
    })

    useEffect(() => {
        if ((props.addText !== null) ||
            (props.addText !== undefined) ||
            (props.addText !== "")
        ) {
            setText(props.addText)
        }
    })

    return (
        <>
            <Modal
                open={covidModal}
                onClose={modalHandler}
                className="covid-wrapper"
            >
                <Slide in={covidModal} mountOnEnter unmountOnExit {...(covidModal ? { timeout: 1000 } : {})}>
                    <Paper className="covid-modal">
                        <h2 style={{ lineHeight: '1.2em' }}>Pembayaran Belum Dilakukan</h2>
                        <div className="covid-content">
                            <p>Dear {props.nama} ❤️</p>
                            <p>Ini adalah fitur uji coba yang disediakan oleh Vanilatte untuk membuat undangan secara gratis sebelum melakukan pembayaran.</p>
                            <p>Pesan ini akan terus muncul secara berkala selama masa uji coba, dan akan otomatis hilang secara permanen ketika sudah melakukan pembayaran.</p>
                            <p>Jika butuh bantuan, klik link ini untuk menghubungi kami: <a href="https://vanilatte.com/ask" target='_blank'>Hubungi Kami</a></p>
                        </div>
                        <div className="covid-button" onClick={modalHandler}>Tutup Sementara</div>
                    </Paper>
                </Slide>
            </Modal>

        </>
    )
}

export default Covidmodal
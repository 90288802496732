import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";
import Footer from "./footer";

function TnC() {
    return (
        <>
            <Helmet>
                <title>Terms & Conditions - Premium Wedding Invitation</title>
                <meta property="og:site_name" content="Vanilatte" />
                <meta property="og:title" content="Syarat dan Ketentuan" />
                <meta
                    property="og:description"
                    content="Syarat dan ketentuan pembuatan undangan pernikahan"
                />
                <meta
                    property="og:image"
                    itemprop="image"
                    content="https://vanilatte-og-image.vercel.app/api/web?text=Syarat+dan+Ketentuan"
                />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">
                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>
                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>
                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>
                <div className="barba-container" data-barba="container">
                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>
                    <main className="">
                        <Navbar showLogo={true} showMenu={false} />
                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-6">
                                        <div className="sectionHeading -md">
                                            <p className="sectionHeading__subtitle">Our</p>
                                            <h2 className="sectionHeading__title"><span className="text-accent">Terms</span> & Conditions</h2>
                                            <p className="text-lg sm:text-base mt-24">Dengan menggunakan layanan kami, Anda dianggap setuju dengan Syarat & Ketentuan berikut ini.</p>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-6" style={{ "textAlign": "justify" }}>
                                        <p>Vanilatte berhak menggunakan undangan yang dipesan sebagai media promosi atau portfolio yang sewaktu-waktu bisa dicantumkan di media sosial maupun website Vanilatte tanpa meminta persetujuan pemesan undangan.</p>
                                        <br />
                                        <p>Vanilatte tidak akan menghapus undangan yang sudah dipesan, tetapi jika setelah melewati H+15 (15 hari setelah tanggal akad/pemberkatan pernikahan) ada yang ingin menggunakan username yang sama, Vanilatte berhak mengalihkan username tersebut ke pemesan terbaru tanpa meminta persetujuan pemesan sebelumnya.</p>
                                        <br />
                                        <p>Jika setelah H+15 pemesan tidak bisa mengakses undangan (atau isi undangan berubah), silakan hubungi kami untuk mendapatkan username undangan yang baru.</p>
                                        <br />
                                        <p>Pemesan bisa melakukan permintaan pergantian username selambat-lambatnya 7 hari sebelum acara pernikahan berlangsung. Akan tetapi kami tidak menyarankan pergantian username jika pemesan sudah terlanjut menyebar undangan.</p>
                                        <br />
                                        <p>Pemesan bisa mencoba undangan gratis selama 3 hari dengan fitur penuh jika pemesan melakukan pemesanan paling lambat H-14, namun pemesan tidak bisa memilih username (username akan diberikan oleh Vanilatte). Perhitungan waktu 3 hari dihitung sejak kami mengabarkan bahwa undangan sudah bisa diakses.</p>
                                        <br />
                                        <p>Jika pemesan memilih untuk melakukan pembayaran sesuai dengan harga desain yang pemesan pilih, kami akan mengubah username sesuai permintaanmu.</p>
                                        <br />
                                        <p>Jika pemesan memilih untuk tidak meneruskan, maka undangan akan kami arsipkan dan tidak bisa diakses kembali.</p>
                                        <br />
                                        {/* <p>Undangan yang dipesan akan bisa diakses paling lambat 3x24 jam setelah pemesan melakukan pembayaran dan melengkapi data & informasi yang diperlukan, sedangkan pengerjaan revisi akan dilakukan sesuai antrian yang ada.</p> */}
                                        <br />
                                        <p>Pemesan bisa melakukan revisi sebanyak 2x dengan kuota pekerjaan maksimal 7 pekerjaan setiap kali revisi (ubah nama, lokasi, tanggal, waktu pelaksanaan, dan lain sebagainya)</p>
                                        <br />
                                        <p>Revisi dianggap selesai jika pemesan sudah melakukan konfirmasi persetujuan, atau tidak memberikan jawaban dalam waktu 12 jam setelah kami mengabarkan hasil revisi. Demi kenyamanan bersama, revisi disampaikan paling lambat H-3 sebelum acara pernikahan.</p>
                                        <br />
                                        {/* <p>Revisi tidak mencakup perubahan desain undangan. Jika pemesan menginginkan perubahan desain, maka pemesan akan dikenakan biaya sesuai dengan harga desain baru yang dipilih.</p> */}
                                        <br />
                                        {/* <p>Jika pemesan memilih mengganti desain dengan harga yang lebih rendah daripada desain yang sudah dipilih saat ini, maka selisih biaya yang sudah dibayar tidak bisa dikembalikan.</p> */}
                                        <br />
                                        <p>Jika pemesan mendapatkan promo dari partner kami, silakan sampaikan saat proses pemesanan.</p>
                                        <br />
                                        <p>Materi promo tidak bisa digabung dengan materi promo lainnya yang sedang berlaku.</p>
                                        <br />
                                        <p>Biaya yang sudah dibayarkan untuk pemesanan undangan tidak dapat dikembalikan dengan alasan apapun.</p>
                                        <br />
                                        {/* <p>Pemesan bisa melakukan permintaan pembuatan undangan dengan desain sendiri yang belum tercantum di katalog Vanilatte. Biaya dan syarat khusus akan dikenakan.</p> */}
                                        <br />
                                        <p>Kami menyediakan layanan RSVP & Guestbook, namun kami tidak memonitor konten/pesan yang dikirimkan oleh tamu undangan. Jika ada konten/pesan yang tidak berkenan, silakan hubungi kami untuk dilakukan moderasi.</p>
                                        <br />
                                        <p>Seluruh undangan sudah dibuat dengan sebaik mungkin agar dapat diakses dengan baik dari semua perangkat (smartphone, tablet, dan desktop PC) dan semua sistem operasi (MacOS, Windows, Linux, Android, iOS), namun kami tidak bertanggung jawab terhadap kesulitan akses undangan yang disebabkan oleh pihak ketiga seperti jaringan internet, keterbatasan kemampuan perangkat, dll.</p>
                                        <br />
                                        <p>Pemesan bisa dan diperbolehkan memesan undangan tanpa memilih fitur tambahan (hanya mendapatkan <em>base design/template</em> saja).</p>
                                        <br />
                                        <p>Kami menyediakan domain (alamat web) khusus untuk mengakses undangan, yaitu <strong>album.love</strong> <em>(Album of Love by Vanilatte)</em>.</p>
                                        <br />
                                        <p>Pemesan tidak perlu mengeluarkan biaya tambahan untuk bisa menggunakan layanan <em>Album of Love by Vanilatte</em>.</p>
                                        <br />
                                        <p>Undangan pernikahan akan bisa diakses di <strong>album.love/i/<em>[username]</em></strong>.</p>
                                        <br />
                                        <p>Syarat, ketentuan, harga, desain & fitur undangan bisa berubah sewaktu-waktu tanpa adanya pemberitahuan terlebih dahulu.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer /> */}
                    </main>
                </div>
            </div>
        </>
    )
}

export default TnC;
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { SRLWrapper } from "simple-react-lightbox";
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";
import QrCodeGenerator from "../scanner/qrCodeGenerator";

let guestName = "";
let kepada = "";
let sesi = "11:00 - 12:00";
let params = "";
const gbUrl = "https://sheet.best/api/sheets/34ca0c8d-ca50-4f19-b070-e1663ec88390";
const gbKey = "1RNguM4tLqhb2DHwjSp0-3TF6D4PK6y1LV6uQttjrAag";

function DianFariz() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showLoveStory, setShowLoveStory] = useState(false);
    const [showGaleri, setShowGaleri] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useEffect(() => {
        if (!showGbMsg) requestGuestBook();
    })

    useState(() => {
        let search = window.location.search;
        params = new URLSearchParams(search);
        let sesiParam = params.get('q');
        if (sesiParam != null) sesi = "12:00 - 13:00"

        kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada + "\n(" + sesi + ")";
        if (kepada != null) setShowQrCode(true);

    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: ((gbAtt === "yes") && (gbPerson === "0")) ? "1" : gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Fariz & Dian</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="assets/clients/dianfariz/images/groombride.jpg" />
                <link rel="apple-touch-icon" href="assets/clients/dianfariz/images/groombride.jpg" />
                <link rel="stylesheet" href="assets/clients/dianfariz/assets/css/main.css" />
                {/* <script src="assets/clients/dianfariz/assets/js/jquery.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/browser.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/breakpoints.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/util.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/main.js"></script> */}

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" />
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <script src="/assets/clients/dianfariz/assets/js/countdown.js" date="2021-6-6 09:00"></script>
                <script src="/assets/global/yt-no-repeat.js"></script>
                <script src="/assets/global/cursor.js"></script>
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            {/* <CovidModal /> */}
            <div className="cursor"></div>
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>Fariz & Dian</h1>
                                    <p className="guest-name">{guestName}</p>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Undangan</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Lokasi</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Love Story</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Galeri</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Guestbook</button></li>
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                <h2 className="major">Kami yang Berbahagia</h2>
                                <span className="image main"><img src="assets/clients/dianfariz/images/mempelai_id.jpg" alt="" /></span>
                                <div className="text-center">

                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing"   >Dian Fitri Utami, A.Md</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri kelima dari<br /><strong>Bp. Suroto (alm) & <br />Ibu Martiyah</strong> <br /><em>Banguntapan, Bantul</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">Muhammad Fariz Andikanugra Hadna, S.Kom.</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra pertama dari<br /><strong>Bp. Drs. Komarudin Hadna & <br />Ibu Hj. dr. Agustiani Wahdjoe Widjajanti (almh)</strong> <br /><em>Kasongan, Bantul</em></p>
                                    <hr />
                                    <p className="prayer">Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.<br /><em>QS Ar-Rum:21</em></p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                <h2 className="major">Turut Mengundang</h2>
                                <span className="image main"><img src="assets/clients/dianfariz/images/undangan.jpg" alt="" /></span>
                                <div className="text-center">
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Akad</h1>
                                    <h3 className="">Minggu <br />6 / Juni / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">09:00 - 10:00 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Gedung Dwipari Tamansiswa, <br />Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Akad+Nikah+Fariz+-+Dian&dates=20210606T020000Z/20210606T030000Z&details=Detail+undangan+ada+di:+https://album.love/DianFarizMengundang&location=Gedung+Puri+Dwipari&trp=true" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Resepsi</h1>
                                    <h3 className="">Minggu <br />6 / Juni / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">{sesi} WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Gedung Dwipari Tamansiswa, <br />Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Resepsi+Nikah+Fariz+-+Dian&dates=20210606T030000Z/20210606T050000Z&details=Detail+undangan+ada+di:+https://album.love/DianFarizMengundang&location=Gedung+Puri+Dwipari&trp=true" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    {
                                        (showQrCode) ?
                                            <>
                                                <QrCodeGenerator
                                                    value={kepada}
                                                    bgColor="#ffffff"
                                                    fgColor="#000000"
                                                    size={256}
                                                />
                                                <hr />
                                            </>
                                            : null
                                    }
                                    <p className="prayer">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                <div className="text-center">
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.8507277877625!2d110.3743536509461!3d-7.8056226943475435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5782cd2be17b%3A0x53bb95ea09957cea!2sGedung%20Puri%20Dwipari!5e0!3m2!1sen!2sid!4v1618204366905!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://goo.gl/maps/CvoXNgaKTXFTeDnc7" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major">RSVP & Guestbook</h2>
                                <span className="image main"><img src="assets/clients/dianfariz/images/guest_book.jpg" alt="" /></span>
                                <div className="text-center">
                                    {
                                        (gbSent) ?
                                            <>
                                                <br />
                                                <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                            </>
                                            :
                                            <>
                                                <p   >Terima kasih atas doa dan ucapannya 😊<br />Acara pernikahan kami berlangsung dengan khidmat dan lancar 😊</p>
                                                {/* <form onSubmit={((e) => gbSubmitHandler(e))}>
                                                    <fieldset>
                                                        <div >
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div >
                                                                <select onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                                    <option value="" disabled selected>Apakah bisa hadir?</option>
                                                                    <option value="yes">Ya, hadir</option>
                                                                    <option value="no">Maaf, tidak bisa hadir</option>
                                                                    <option value="maybe">Belum tahu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div >
                                                            {
                                                                (gbAtt === "yes") ?
                                                                    <>
                                                                        <select onChange={((e) => setGbPerson(e.target.value))} required className="gb-input">
                                                                            <option value="1" selected>1 orang</option>
                                                                            <option value="2">2 orang</option>
                                                                            <option value="3">3 orang</option>
                                                                            <option value="4">4 orang</option>
                                                                        </select>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div >
                                                            <div >
                                                                <textarea disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <br />
                                                                <button disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form> */}
                                            </>
                                    }
                                    <br />&nbsp;
                                    <div className="gb-wrap">
                                        {messageList}
                                    </div>
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showGaleri) ?
                        <Fade in={showGaleri} mountOnEnter unmountOnExit {...(showGaleri ? { timeout: 1500 } : {})}>
                            <article id="galeri">
                                <h2 className="major">Galeri</h2>
                                <div className="text-center">
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="">Video</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <span className="image main"><div className="hytPlayerWrapOuter"> <div className="hytPlayerWrap"> <iframe src="https://www.youtube.com/embed/G_jFpPgqEAU?controls=1&rel=0&enablejsapi=1" allowFullScreen frameBorder="0" title="Engagement Video" ></iframe> </div></div></span>
                                    <span className="image main"><div className="hytPlayerWrapOuter"> <div className="hytPlayerWrap"> <iframe src="https://www.youtube.com/embed/tefbfGYQWIo?controls=1&rel=0&enablejsapi=1" allowFullScreen frameBorder="0" title="Engagement Video" ></iframe> </div></div></span>
                                    <br />&nbsp;
                                    <hr />
                                    <br />&nbsp;
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="">Foto</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;

                                    <SRLWrapper>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/1.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/2.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/3.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/4.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/5.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/6.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/7.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/8.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/9.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/10.jpg" alt="" /></span>
                                        <span className="image main"><img src="assets/clients/dianfariz/images/11.jpg" alt="" /></span>
                                    </SRLWrapper>

                                    <hr />
                                    <p className="prayer text-center">❤ &nbsp; You are every reason, every hope and every dream I’ve ever had. &nbsp; ❤</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Close</div>
                            </article>
                        </Fade>
                        // {/* : null */}
                    }

                    {
                        // (showLoveStory) ?
                        <Fade in={showLoveStory} mountOnEnter unmountOnExit {...(showLoveStory ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major">Our Love Story</h2>
                                <span className="image main"><img src="assets/clients/dianfariz/images/love_story.jpg" alt="" /></span>
                                <h2 className="text-center">&middot; Menuju Hari Bahagia &middot; </h2>
                                <ul className="countdown text-center">
                                    <li  ><span id="days"></span>hari</li>
                                    <li  ><span id="hours"></span>jam</li>
                                    <li  ><span id="minutes"></span>menit</li>
                                    <li  ><span id="seconds"></span>detik</li>
                                </ul>
                                <hr />
                                <br />&nbsp;

                                <blockquote   >Berawal dari sebuah pertemuan membuat kami saling mengenal dan memahami. Seiring berjalannya waktu rasa kenal itu berakhir dengan Cinta. <br /><br />Kini, kami putuskan untuk hidup bersama dengan ikatan cinta yang tulus, murni dan indah. Pernikahan ini adalah waktu yang menandai kehidupan bersama kami telah dimulai. Kami berharap kisah ini akan abadi seperti kata “Cinta” itu sendiri. <br /><br />Dalam tautan rasa yang penuh bahagia kami berharap doa dan Restunya “Semoga kami tetap bersama dengan ikatan yang kuat sampai saat usia memisahkan kami” (Amin). </blockquote>
                                <hr />

                                <h2>Story by Dian:</h2>
                                <br />&nbsp;
                                <h5   >&middot; 2011 &middot; </h5>
                                <blockquote   >Waktu itu penerimaan siswa baru SMA kami bertemu di depan pengumuman pembagian kelas. Dia menarik perhatianku karena seragam berlabel jogja dengan name tag M. Fariz Andikanugra H. Aku terus melihatnya dengan seksama, dalam hatiku aku berkata "dia adalah tipe yg kusuka" terlihat kalem, sederhana dan setia. Bisa dibilang aku suka pada pandangan pertama. Saat kualihkan pandangan ku ke papan pengumuman aku dikelas yg berbeda dengannya.</blockquote>


                                <h5   >&middot; 2012-2014 &middot; </h5>
                                <blockquote   >Masa pergantian kelas XI aku jadi satu kelas dengannya. Selama 2 tahun sekelas aku dan dia jarang sekali bicara. Seperti orang asing. Aku hanya mengamatinya sebagai teman sekelas yg rajin dan tepat waktu. Patuh dengan guru. Seperti pada saat diminta potong rambut, di keesokan harinya pasti rambutnya sudah terpotong rapi. Pintar Bahasa Inggris. Sangat berbeda dengan aku yang kurang dalam pelajaran Bahasa Ingris dan sering telat. Sampai pada akhir tahun masa-masa lulus SMA, entah kenapa dia mulai mendekatiku mungkin karena punya kegemaran yang sama tentang anime. Pada awalnya kita hanya membicarakan anime saja namun semakin lama kita menjadi semakin dekat. Dia memintaku menemaninya ke kampus barunya, jalan-jalan ke pasar malam dan melihat anime kesukaan kami. Kami menjadi semakin dekat dan saling berkomitmen.</blockquote>


                                <h5   >&middot; 2015-2021 &middot; </h5>
                                <blockquote   >Sejak saat itu aku tidak pernah putus hubungan dengannya. Saling memberi kabar dan saling menemani. Menengokku dimanapun aku berkerja partime. Teman diskusi kuliah. Teman mengerjakan Tugas Akhir dan Skripsinya. Lebih dari yg kuperkirakan dari awal. Dia adalah orang yang sangat baik. Orang yang sangat penyayang. Selama aku kenal dia tidak pernah marah padaku. Selalu berada di pihakku. Tidak pernah mengeluhkan penampilan dan keadaanku. Menerimaku apa adanya. Orang yang paling bisa aku andalkan dalam mengurus 1 pekerjaan yg menghasilkan dollar. Sangat melegakan bisa bekerja dengan orang yg kita sayang. Dan yg paling penting dia orang yg sangat setia. Sungguh aku adalah perempuan yang beruntung dipertemukan oleh dia. Pada saat itu aku ingin menjadi lebih dari teman untuknya. Kutanyakan padanya apa dia bersedia melamarku, dia bilang ya.</blockquote>

                                <hr />

                                <h2>Story by Fariz:</h2>
                                <br />&nbsp;
                                <h5   >&middot; 2012-2014 &middot; </h5>
                                <blockquote   >Masa Pergantian kelas XI, kami satu kelas. Selama 2 tahun sekelas kami jarang sekali bicara, seperti orang asing. Aku sering curi-curi pandang ke arahnya. Di mataku, Dian adalah teman yang pendiam, namun jika sudah bersama dua sahabatnya seketika ia berubah jadi periang. Menurutku, matematika adalah salah satu mata pelajaran keahliannya. Nilainya selalu stabil di angka 8 keatas. Walaupun sering terlambat, tapi aku tahu bahwa Dian menempuh perjalanan yang jauh. Bahkan suatu kali Dian pernah berangkat sekolah menggunakan sepeda. Setiap hari, kekagumanku padanya semakin bertambah. Sampai pada akhir tahun masa-masa lulus SMA, aku memberanikan diri untuk mendekatinya. Pada awalnya kami hanya membicarakan anime saja namun setelah sekian lama kami menjadi semakin dekat. Waktu itu aku memintanya menemaniku ke kampus baru, mengajak jalan-jalan ke pasar malam dan melihat anime kesukaan kami berdua. Kami menjadi semakin dekat dan saling berkomitmen.</blockquote>


                                <h5   >&middot; 2015-2021  &middot; </h5>
                                <blockquote   >Tahun 2015-2021 merupakan tahun-tahun yang penuh warna. Senang, sedih, bahagia, dan rasa lainnya bercampur aduk. Setiap hari aku ingin selalu ketemu, sampai diingatkan untuk jangan pacaran terus. Dian adalah orang yang sangat penyayang dan selalu setia menemaniku apapun kondisiku. Saat itu ketika aku kehilangan sosok Ibu, Dian selalu setia menemaniku dan menghiburku.
                                    Hingga pada awal 2021, Aku berniat untuk melamarnya. Dian menanyakan padaku apa aku bersedia melamarnya. Ku jawab Iya, aku bersedia.</blockquote>


                                <h5   >&middot; 13 Maret 2021 &middot; </h5>
                                <blockquote   >Aku melamar Dian. Hari itu Dian sangat cantik. Aku kembali jatuh cinta padanya yang ke sekian kali. Dian menerima lamaranku! Akhirnya salah satu impianku terwujud.</blockquote>

                                <hr />

                                <h5   >&middot; 6 Juni 2021 &middot; </h5>
                                <blockquote   >Kami sepakat memilih hari ini sebagai hari bahagia kami.</blockquote>

                                <hr />
                                <p className="prayer text-center">❤ &nbsp; True love stories never have endings. &nbsp; ❤</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation" target="_blank" rel="noreferrer">Vanilatte.com</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default DianFariz;
import { Helmet } from "react-helmet";
import Audio from "../../components/audio";
import Covidmodal from "../../components/covidModal";
import { useState } from "react";

let guestName = "";

function Chandra() {
    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })
    return (
        <>
            <Helmet>
                <title>Chandra | Vanilatte</title>
                <meta name="description" content="Chandra Premium Wedding Invitation by Vanilatte" />
                <link href="/assets/template/chandra/css/main.css" rel="stylesheet" />
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <script src="/assets/template/chandra/js/jquery.min.js"></script>
                <script src="/assets/template/chandra/js/jquery.scrollex.min.js"></script>
                <script src="/assets/template/chandra/js/jquery.scrolly.min.js"></script>
                <script src="/assets/template/chandra/js/skel.min.js"></script>
                <script src="/assets/template/chandra/js/util.js"></script>
                <script src="/assets/template/chandra/js/main.js"></script>

                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <script src="/assets/global/countdown.js" date="2022-1-1 08:00"></script>
                <link href="/assets/template/gendhis/css/font-awesome/css/all.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link href="/assets/template/chandra/vendor/bootstrap/bootstrap.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <script src="/assets/global/cursor.js"></script>
                <link href="/assets/global/cursor.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <link href="https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Lato:300,400|Work+Sans:300,400,700" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Playfair+Display" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <link href="https://file.myfontastic.com/7vRKgqrN3iFEnLHuqYhYuL/icons.css" rel="stylesheet" />

                <script src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.js"></script>
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            {/* <Covidmodal /> */}
            <div className="cursor"></div>
            <div id="notification-bar">
                <div className="notification-container">
                    <p>Chandra</p>
                    <a className="btn-action" href="/order" target="_blank" rel="noreferrer"><span className="lnr lnr-cart">&nbsp;Order</span></a>
                </div>
            </div>
            <div id="wrapper" className="divided">
                {/* One */}
                <section className="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
                    <div className="content">
                        {/* <p className="major">The Wedding Of</p> */}
                        <h1>Bandung & Roro</h1>
                        <p className="major kepada">{guestName}</p>
                        <p className="major">We invite you to celebrate our wedding!</p>
                    </div>
                    <div className="image">
                        <img src="assets/global/img/home-img.jpg" alt="" />
                    </div>
                </section>
                {/* Two */}
                <section className="spotlight style1 orient-right content-align-left image-position-center fullscreen onscroll-image-fade-in" id="first">
                    <div className="content">
                        <h2>KAMI YANG BERBAHAGIA</h2>
                        <hr />
                        <h3><strong>Roro Jonggrang</strong></h3>
                        <p className="major">Putri ketiga dari<br /><strong>Prabu Boko & Ny. Boko</strong><br />Kerajaan Boko</p>
                        <h2>&</h2>
                        <h3><strong>Bandung Bondowoso</strong></h3>
                        <p className="major">Putra pertama dari<br /><strong>Prabu Damar Moyo & Ny. Damar Moyo</strong><br />Kerajaan Pengging</p>
                        <hr />
                        <p><em>Maha Suci Tuhan yang telah menciptakan pasangan-pasangan semuanya, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka maupun dari apa yang tidak mereka ketahui. ~ QS Yasin: 36</em></p>
                    </div>
                    <div className="image">
                        <img src="assets/global/img/gallery-pic-1.jpg" alt="" />
                    </div>
                </section>
                {/* Three */}
                <section className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in">
                    <div className="content">
                        <h2>TURUT MENGUNDANG</h2>
                        <hr />
                        <p>Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                        <h3>Akad</h3>
                        <p className="major">Sabtu, 1 Januari 2022<br />
                            08:30 - 09:00 WIB<br />
                            <a className="map-url" href="https://goo.gl/maps/LmbRtiKDGHrfP3RLA" target="_blank" rel="noreferrer"><span className="lnr lnr-map-marker"></span> Gd. Pertemuan Candi Prambanan, Yogyakarta</a></p>
                        <ul className="actions vertical">
                            <li><a href="#" className="button"><i className="icon style1 fa-calendar"></i> &nbsp; Simpan Ke Google Calendar</a></li>
                        </ul>
                        <br />
                        <h3>Resepsi</h3>
                        <p className="major">Sabtu, 1 Januari 2022<br />
                            10:00 - 12:00 WIB<br />
                            <a className="map-url" href="https://goo.gl/maps/LmbRtiKDGHrfP3RLA" target="_blank" rel="noreferrer"><span className="lnr lnr-map-marker"></span> Gd. Pertemuan Candi Prambanan, Yogyakarta</a></p>
                        <ul className="actions vertical">
                            <li><a href="#" className="button"><i className="icon style1 fa-calendar"></i> &nbsp; Simpan Ke Google Calendar</a></li>
                        </ul>
                    </div>
                    <div className="image">
                        <img src="assets/global/img/bride.jpg" alt="" />
                    </div>
                </section>
                {/* Four */}
                <section className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in">
                    <div className="content">
                        <h2>Pharetra etiam nulla</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id ante sed ex pharetra lacinia sit amet vel massa. Donec facilisis laoreet nulla eu bibendum. Donec ut ex risus. Fusce lorem lectus, pharetra pretium massa et, hendrerit vestibulum odio lorem ipsum dolor sit amet.</p>
                        <ul className="actions vertical">
                            <li><a href="#" className="button">Learn More</a></li>
                        </ul>
                    </div>
                    <div className="image">
                        <img src="assets/template/chandra/images/spotlight03.jpg" alt="" />
                    </div>
                </section>
                {/* Five */}
                <section className="wrapper style1 align-center">
                    <div className="inner">
                        <h2>Massa sed condimentum</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id ante sed ex pharetra lacinia sit amet vel massa. Donec facilisis laoreet nulla eu bibendum. Donec ut ex risus. Fusce lorem lectus, pharetra pretium massa et, hendrerit vestibulum odio lorem ipsum.</p>
                    </div>
                    {/* Gallery */}
                    <div className="gallery style2 medium lightbox onscroll-fade-in">
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/01.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/01.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Ipsum Dolor</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/02.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/02.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Feugiat Lorem</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/03.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/03.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Magna Amet</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/04.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/04.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Sed Tempus</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/05.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/05.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Ultrices Magna</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/06.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/06.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Sed Tempus</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/07.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/07.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Ipsum Lorem</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/08.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/08.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Magna Risus</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/09.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/09.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Tempus Dolor</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/10.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/10.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Sed Etiam</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/11.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/11.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Magna Lorem</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                        <article>
                            <a href="assets/template/chandra/images/gallery/fulls/12.jpg" className="image">
                                <img src="assets/template/chandra/images/gallery/thumbs/12.jpg" alt="" />
                            </a>
                            <div className="caption">
                                <h3>Ipsum Dolor</h3>
                                <p>Lorem ipsum dolor amet, consectetur magna etiam elit. Etiam sed ultrices.</p>
                                <ul className="actions">
                                    <li><span className="button small">Details</span></li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </section>
                {/* Six */}
                <section className="wrapper style1 align-center">
                    <div className="inner">
                        <h2>Ipsum sed consequat</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id ante sed ex pharetra lacinia sit amet vel massa. Donec facilisis laoreet nulla eu bibendum. Donec ut ex risus. Fusce lorem lectus, pharetra pretium massa et, hendrerit vestibulum odio lorem ipsum.</p>
                        <div className="items style1 medium onscroll-fade-in">
                            <section>
                                <span className="icon style2 major fa-diamond" />
                                <h3>Lorem</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-save" />
                                <h3>Ipsum</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-bar-chart" />
                                <h3>Dolor</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-wifi" />
                                <h3>Amet</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-cog" />
                                <h3>Magna</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-paper-plane" />
                                <h3>Tempus</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-desktop" />
                                <h3>Aliquam</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-refresh" />
                                <h3>Elit</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-hashtag" />
                                <h3>Morbi</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-bolt" />
                                <h3>Turpis</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-envelope" />
                                <h3>Ultrices</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                            <section>
                                <span className="icon style2 major fa-leaf" />
                                <h3>Risus</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dui turpis, cursus eget orci amet aliquam congue semper. Etiam eget ultrices risus nec tempor elit.</p>
                            </section>
                        </div>
                    </div>
                </section>
                {/* Seven */}
                <section className="wrapper style1 align-center">
                    <div className="inner medium">
                        <h2>Get in touch</h2>
                        <form method="post" action="#">
                            <div className="field half first">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" defaultValue />
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" defaultValue />
                            </div>
                            <div className="field">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows={6} defaultValue={""} />
                            </div>
                            <ul className="actions">
                                <li><input type="submit" name="submit" id="submit" defaultValue="Send Message" /></li>
                            </ul>
                        </form>
                    </div>
                </section>
                {/* Footer */}
                <footer className="wrapper style1 align-center">
                    <div className="inner">
                        <ul className="icons">
                            <li><a href="#" className="icon style2 fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href="#" className="icon style2 fa-facebook"><span className="label">Facebook</span></a></li>
                            <li><a href="#" className="icon style2 fa-instagram"><span className="label">Instagram</span></a></li>
                            <li><a href="#" className="icon style2 fa-linkedin"><span className="label">LinkedIn</span></a></li>
                            <li><a href="#" className="icon style2 fa-envelope"><span className="label">Email</span></a></li>
                        </ul>
                        <p>© Untitled. Design: <a href="https://html5up.net">HTML5 UP</a>.</p>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Chandra;
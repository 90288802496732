import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const defaultMusic = "George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3";

function Audio(props) {
    const [isPlay, setIsPlay] = useState(false);
    const [music, setMusic] = useState(defaultMusic);

    useEffect(() => {
        if ((props.music !== null) ||
        (props.music !== undefined) ||
        (props.music !== "")
        ) {
            setMusic(props.music)
        }
    })

    const playBtnHandler = (() => {
        var x = document.getElementById("audio__player");
        if (!isPlay) { x.play(); }
        if (isPlay) x.pause();
        setIsPlay(!isPlay);
    })

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/assets/global/audio/audio.css" />
            </Helmet>
            <audio id="audio__player" loop>
                <source src={"/assets/global/audio/collection/" + music} type="audio/mpeg" />
            </audio>
            <div id="audio__handler" onClick={playBtnHandler} className="audio__button">{(isPlay) ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}</div>
        </>
    )
}

export default Audio
import { useEffect, useState } from "react";
import Fade from '@material-ui/core/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";
import Covidmodal from "../../components/covidModal";
import { convertTime, formatCounter, prayer } from "../../util";
import Audio from "../../components/audio";
import Instagram from "../../components/instagram";


function Ayana(props) {

    const [guestName, setGuestName] = useState("")

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let kepada = params.get('kepada');
        if (kepada != null) setGuestName(kepada);

    }, [])

    // useEffect(() => {
    //     console.log(props);
    // }, [])
    // return <div><h1 id="text01">{convertTime(props.weddingData['16'])}</h1></div>

    if (props)
        return (
            <>
                <Helmet>
                    <link rel="stylesheet" href="/assets/global/wedding/ayana.css" />
                    <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                    <script src="/assets/global/wedding/countdown.js" date={formatCounter(props.weddingData['16']) + "__" + formatCounter(props.weddingData['17'])}></script>
                    <link rel="stylesheet" href="/assets/global/animation.css" />
                    <script src="/assets/global/animation.js"></script>
                    <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link href="https://fonts.googleapis.com/css?family=Montserrat:400,400italic,200,200italic%7CCaveat:400,400italic%7CSource+Sans+Pro:200,200italic,400,400italic" rel="stylesheet" type="text/css" />
                </Helmet>
                {
                    (props.weddingData['33'].toLowerCase() != 'active') ?
                        <Covidmodal nama={props.weddingData['1']} />
                        : null
                }
                <Audio music="Yiruma, (이루마) - River Flows in You.mp3" />
                <Fade
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: 1000 } : {})}
                >
                    <div id="wrapper">
                        <div id="main">
                            <div className="inner">
                                <div id="columns03" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <p id="text16">THE WEDDING OF</p>
                                            <h1 id="text03">{props.weddingData['1']}</h1>
                                            <hr id="divider04" />
                                            {
                                                (guestName) ?
                                                    <>
                                                        <p id="text09" style={{ color: '#fff' }}>Kepada Yth.</p>
                                                        <p id="text02" style={{ marginTop: '-10px', fontSize: '2em' }}>{guestName}</p>
                                                    </>
                                                    : null
                                            }
                                            <p id="text09" style={{ fontSize: '1em' }}>We invite you to share with us a celebration of love</p>
                                        </div>
                                    </div>
                                </div>
                                <hr id="divider05" />
                                <div id="container01" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <p id="text19" style={{ fontSize: '1.5em' }}>{props.weddingData['5']}</p>
                                            <p id="text20">Putri {(props.weddingData['6']).toLowerCase()} dari</p>
                                            <p id="text21" style={{ fontSize: '1.2em' }}><span>{props.weddingData['7']} &amp;</span><br /> <span>{props.weddingData['8']}</span></p>
                                            <p id="text22">{props.weddingData['9']}</p>
                                            <h2 id="text19">&amp;</h2>
                                        </div>
                                    </div>
                                </div>
                                <div id="columns01" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <p id="text19" style={{ fontSize: '1.5em' }}>{props.weddingData['10']}</p>
                                            <p id="text17">Putra {(props.weddingData['11']).toLowerCase()} dari</p>
                                            <p id="text21" style={{ fontSize: '1.2em' }}><span>{props.weddingData['12']} &amp;</span><br /> <span>{props.weddingData['13']}</span></p>
                                            <p id="text11">{props.weddingData['14']}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr id="divider06" />
                                <div id="columns05" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            {
                                                (props.weddingData['4'] !== "Tidak Memakai Do'a/Ayat") ?
                                                    <>
                                                        <p id="text02" style={{ fontSize: '1.2em' }}><span>{prayer[props.weddingData['4']]} </span></p>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <hr id="divider03" />
                                        </div>
                                    </div>
                                </div>
                                <h3 id="text14">Turut Mengundang</h3>
                                <hr id="divider07" />
                                <p id="text06">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                <br />
                                <p id="text07" style={{ fontSize: '1.2em' }}>{props.weddingData['15']}:</p>
                                <p id="text08" style={{ fontSize: '1em' }}><span>{convertTime(props.weddingData['16'])}</span><br /><br /> <span>{convertTime(props.weddingData['17'])} - {convertTime(props.weddingData['18'])}</span><br /><br /> <span>{props.weddingData['19']}</span></p>
                                {
                                    ((props.weddingData['20']).includes("https://") || (props.weddingData['20']).includes("http://")) ?
                                        <div className="center-button">
                                            <a href={props.weddingData['20']} target="_blank" rel="noreferrer" style={{ fontSize: '1em' }}><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                        </div>
                                        : null
                                }
                                <p style={{ "textAlign": "center", paddingTop: '1em' }}><div id="image01" className="image"><LazyLoadImage style={{ "maxWidth": "70px" }} className="grass" src="assets/global/wedding/grass.png" alt="" /></div></p>
                                <p id="text07" style={{ fontSize: '1.2em' }}>{props.weddingData['21']}:</p>
                                <p id="text13" style={{ fontSize: '1em' }}><span>{convertTime(props.weddingData['22'])}</span><br /><br /> <span>{convertTime(props.weddingData['23'])} - {convertTime(props.weddingData['24'])}</span><br /><br /> <span>{props.weddingData['25']}</span></p>
                                {
                                    ((props.weddingData['26']).includes("https://") || (props.weddingData['26']).includes("http://")) ?
                                        <div className="center-button">
                                            <a href={props.weddingData['26']} target="_blank" rel="noreferrer" style={{ fontSize: '1em' }}><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                        </div>
                                        : null
                                }
                                <hr id="divider07" />
                                <p id="text23">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                <hr id="divider02" />
                                {
                                    (props.weddingData['27'] != '-') ?
                                        <div>
                                            <h2 id="text14" style={{ marginBottom: '20px' }}>Galeri</h2>
                                            <hr id="divider07" />
                                            <p id="text06">Serangkaian perjalanan hidup yang telah membawa kami pada momen bahagia ini.</p>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
                                                <Instagram link={props.weddingData['27'].replace('utm_source=ig_web_copy_link', 'utm_source=ig_embed')} />
                                            </div>
                                            <hr id="divider02" />
                                        </div>
                                        : null
                                }
                                {
                                    (props.weddingData['32'] != '-') ?
                                        <div>
                                            <h2 id="text14" style={{ marginBottom: '20px' }}>Bingkisan</h2>
                                            <hr id="divider07" />
                                            <p id="text06">Bagi kerabat & saudara yang ingin memberikan bingkisan kepada kami, bisa dikirimkan melalui:</p>
                                            <p id="text06" style={{ whiteSpace: "pre-wrap", marginTop: '2em' }}>{props.weddingData['32']}</p>
                                            <hr id="divider02" />
                                        </div>
                                        : null
                                }
                                {
                                    (props.weddingData['30'] != '-' || (props.weddingData['31']) != '-') ?
                                        <div>
                                            <h2 id="text14" style={{ marginBottom: '20px', marginTop: '30px' }}>Ucapan & Doa</h2>
                                            <hr id="divider07" />
                                            <p id="text06">Sampaikan ucapan & doa langsung kepada kami dengan klik tombol di bawah ini.</p>
                                            {
                                                (props.weddingData['30']) ?
                                                <div className="center-button" style={{ marginTop: '20px' }}>
                                                        <a href={'https://wa.me/' + '+62' + props.weddingData['30'].slice(1)} target="_blank" rel="noreferrer" id="text06"><i className="fa fa-whatsapp"></i> &nbsp; Sampaikan ke mempelai wanita</a>
                                                    </div>
                                                    : null
                                                }
                                            {
                                                (props.weddingData['31']) ?
                                                <div className="center-button" style={{ marginTop: '20px' }}>
                                                        <a href={'https://wa.me/' + '+62' + props.weddingData['31'].slice(1)} target="_blank" rel="noreferrer" id="text06"><i className="fa fa-whatsapp"></i> &nbsp; Sampaikan ke mempelai pria</a>
                                                    </div>
                                                    : null
                                                }
                                            <hr id="divider02" />
                                        </div>
                                        : null
                                    }
                                <h3 id="text14" style={{ marginTop: '50px' }}>Menuju Hari Bahagia</h3>
                                    <hr id="divider07" />
                                <p id="text06">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                                <div id="columns04" className="container default">
                                    <div className="wrapper">
                                        <div className="inner">
                                            <div id="timer01" className="timer">
                                                <ul />
                                            </div>
                                            <hr id="divider02" />
                                            <p id="text04" style={{ color: '#fff', fontSize: '0.9em' }}>Buat Undangan Nikah Hanya 1 Menit di <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer">Vanilatte Wedding</a><br /><br /><br />
                                                {/* <a href="https://vanilatte.com/buat-undangan" target="_blank" rel="noreferrer"><i className="fa fa-globe"></i></a> &nbsp;
                                                <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a> &nbsp;
                                                <a href="https://vanilatte.com/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </>
        )
}

export default Ayana;
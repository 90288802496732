import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { SRLWrapper } from "simple-react-lightbox";
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";
import QrCodeGenerator from "../scanner/qrCodeGenerator";

let guestName = "";
let kepada = "";
let sesi = "17:00 - 18:00";
let params = "";
const gbUrl = "https://sheet.best/api/sheets/8140d3a7-e905-446d-9bee-2a2bdd2a9267";
const gbKey = "1qqKR8xLWSDy7qkFGLZ_PPeesaWSi0FZvtIK9bkE4wTI";

function LilisAna() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showLoveStory, setShowLoveStory] = useState(false);
    const [showGaleri, setShowGaleri] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);
    const [showLiveStream, setShowLiveStream] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useEffect(() => {
        if (!showGbMsg) requestGuestBook();
    })

    useState(() => {
        let search = window.location.search;
        params = new URLSearchParams(search);
        let sesiParam = params.get('q');
        if (sesiParam === "2") sesi = "18:00 - 19:30"
        if (sesiParam === "3") sesi = "19:30 - 20:30"

        kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
        if (kepada != null) setShowQrCode(true);

    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: ((gbAtt === "yes") && (gbPerson === "0")) ? "1" : gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Ana & Indra</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="assets/clients/lilisana/images/groombride.jpg" />
                <link rel="apple-touch-icon" href="assets/clients/lilisana/images/groombride.jpg" />
                <link rel="stylesheet" href="assets/clients/lilisana/assets/css/main.css" />

                {/* Global Resources */}
                <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" />
                <link rel="stylesheet" href="/assets/global/cursor.css" />
                <link rel="stylesheet" href="/assets/global/audio.css" />
                <script src="/assets/clients/lilisana/assets/js/countdown.js" date="2021-6-26 13:00"></script>
                <script src="/assets/global/yt-no-repeat.js"></script>
                <script src="/assets/global/cursor.js"></script>
                <script src="/assets/global/disabler.js"></script>
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
            </Helmet>
            <Audio music="Yura_Yunita_-_Duhai_Sayang.mp3" />
            {/* <CovidModal /> */}
            <div className="cursor"></div>
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>Ana & Indra</h1>
                                    <p className="guest-name">{guestName}</p>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Undangan</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Lokasi</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLiveStream(!showLiveStream) }}>Live Stream</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Amplop Digital</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Galeri</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Guestbook</button></li>
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                <h2 className="major">Kami yang Berbahagia</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/2.jpg" alt="" /></span>
                                <div className="text-center">

                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing"   >Yohana Sri Widiastuti</h2>
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri keempat dari<br /><strong>Bp. Yohanes Tukidjo & <br />Ibu Florentina Sri Purwanti</strong> <br /><em>Surodadi Kidul RT 02 RW 01, Donokerto, Turi, Sleman, Yogyakarta</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">Leonardus Lilis Indra Prasetya</h2>
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra ketiga dari<br /><strong>Bp. Rasamto & <br />Ibu Theresia Sumiarti</strong> <br /><em>Tlatar, Sempu, RT 01 RW 05, Wonokerto, Turi, Sleman, Yogyakarta</em></p>
                                    <hr />
                                    <p className="prayer">Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu<br /><em>Corinthians / 2 Korintus 13 : 11</em></p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                <h2 className="major">Turut Mengundang</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/3.jpg" alt="" /></span>
                                <div className="text-center">
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>Pemberkatan</h1>
                                    <h3 className="">Sabtu <br />26 / Juni / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/lilisana/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">12:00 - 13:00 WIB</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/lilisana/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Gereja Santo Yohanes Rasul Somohitan</h4>
                                    <br />
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <a href="https://www.google.com/calendar/render?action=TEMPLATE&text=Pemberkatan+Ana+%26+Indra&details=Detail+bisa+dilihat+di+https%3A%2F%2Falbum.love%2FToBeMrAndMrsPrasetya&location=-7.6357419%2C110.3873279&dates=20210626T050000Z%2F20210626T060000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1 style={{ "fontSize": "4em" }}>Resepsi</h1>
                                    <h3 className="">Sabtu <br />26 / Juni / 2021</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/lilisana/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h1 className="">{sesi} WIB</h1>
                                    <br />
                                    <p className=""><img src="assets/clients/lilisana/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">Bonbale Coffee And Farm, Jalan Balerante, RT.003/RW.009, Ledoklempong, Wonokerto, Kabupaten Sleman, Daerah Istimewa Yogyakarta</h4>
                                    <br />
                                    <img src="assets/clients/lilisana/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Resepsi+Pernikahan+Ana+%26+Indra&details=Detail+selengkapnya+dilihat+di+https://album.love/ToBeMrAndMrsPrasetya&location=-7.6166843,110.3760126&dates=20210626T100000Z/20210626T130000Z" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-calendar" ></i> &nbsp; Simpan di Google Calendar</a>
                                    <hr />
                                    {
                                        (showQrCode) ?
                                            <>
                                                <QrCodeGenerator
                                                    value={kepada}
                                                    bgColor="#ffffff"
                                                    fgColor="#000000"
                                                    size={256}
                                                />
                                                <hr />
                                            </>
                                            : null
                                    }
                                    <p className="prayer">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/6.jpg" alt="" /></span>
                                <div className="text-center">
                                    <br /> &nbsp;
                                    <h2>Pemberkatan</h2>
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi pemberkatan kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.439979048414!2d110.38513385085753!3d-7.635741894467604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5e2f2ab3de97%3A0x4d77c0d41aa0e6c9!2sGereja%20Katolik%20Santo%20Yohanes%20Rasul%2C%20Somohitan!5e0!3m2!1sen!2sid!4v1622432366222!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://goo.gl/maps/G7CJbNjAmA8ajRB79" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <hr />
                                    <h2>Resepsi</h2>
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi resepsi kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.6160962964286!2d110.37382391477708!3d-7.616684294508041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a617fea0d04d5%3A0x87b804dd5c4df552!2sBonbale%20Coffee%20And%20Farm!5e0!3m2!1sen!2sid!4v1622432405346!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://www.google.co.id/maps/place/Bonbale+Coffee+And+Farm/@-7.6166843,110.3738239,17z/data=!3m1!4b1!4m5!3m4!1s0x2e7a617fea0d04d5:0x87b804dd5c4df552!8m2!3d-7.6166843!4d110.3760126" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major">RSVP & Guestbook</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/4.jpg" alt="" /></span>
                                <div className="text-center">
                                    {
                                        (gbSent) ?
                                            <>
                                                <br />
                                                <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                            </>
                                            :
                                            <>
                                                <p   >Kami memohon kesediaannya untuk mengisi buku tamu di bawah ini 😊</p>
                                                <form onSubmit={((e) => gbSubmitHandler(e))}>
                                                    <fieldset>
                                                        <div >
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <input disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div >
                                                                <select onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                                    <option value="" disabled selected>Apakah bisa hadir?</option>
                                                                    <option value="yes">Ya, hadir</option>
                                                                    <option value="no">Maaf, tidak bisa hadir</option>
                                                                    <option value="maybe">Belum tahu</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div >
                                                            {
                                                                (gbAtt === "yes") ?
                                                                    <>
                                                                        <select onChange={((e) => setGbPerson(e.target.value))} required className="gb-input">
                                                                            <option value="1" selected>1 orang</option>
                                                                            <option value="2">2 orang</option>
                                                                        </select>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div >
                                                            <div >
                                                                <textarea disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div >
                                                                <br />
                                                                <button disabled={gbDisable} id="singlebutton" className="button" name="singlebutton" type="submit">Kirim</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </>
                                    }
                                    <br />&nbsp;
                                    <div className="gb-wrap">
                                        {messageList}
                                    </div>
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showGaleri) ?
                        <Fade in={showGaleri} mountOnEnter unmountOnExit {...(showGaleri ? { timeout: 1500 } : {})}>
                            <article id="galeri">
                                <h2 className="major">Galeri</h2>
                                <div className="text-center">

                                    <SRLWrapper>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/1.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/2.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/3.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/4.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/5.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/6.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/7.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/8.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/9.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/10.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/11.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/12.jpg" alt="Ana & Indra" /></span>
                                        <span className="image main"><img src="/assets/clients/lilisana/images/13.jpg" alt="Ana & Indra" /></span>

                                    </SRLWrapper>

                                    <hr />
                                    <p className="prayer text-center">❤ &nbsp; You are every reason, every hope and every dream I’ve ever had. &nbsp; ❤</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Close</div>
                            </article>
                        </Fade>
                        // {/* : null */}
                    }

                    {
                        // (showLoveStory) ?
                        <Fade in={showLoveStory} mountOnEnter unmountOnExit {...(showLoveStory ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major">Amplop Digital</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/9.jpg" alt="" /></span>
                                <p>Demi ikut memutus rantai penyebaran COVID-19, kami sangat merekomendasikan pemberian sumbangan/amplop dilakukan secara digital melalui pilihan berikut ini:</p>
                                <br />&nbsp;
                                <p className="text-center"><strong>Transfer Melalui Rekening Bank Mandiri</strong></p>
                                <p className="text-center">1370016132033 a/n Yohana Sriwidiastuti</p>
                                <br />&nbsp;
                                <hr />
                                <br />&nbsp;
                                <p className="text-center"><strong>ShopeePay</strong></p>
                                <p className="text-center"><em>Scan atau klik QR code di bawah ini:</em></p>
                                <p className="text-center"><a href="https://shp.ee/auyfv2v?smtt=0.0.7" target="_blank" alt="ShopeePay" rel="noreferrer"><span className="image main"><img src="/assets/clients/lilisana/images/qr.jpg" alt="" /></span></a></p>

                                <hr />
                                <p className="prayer text-center">❤ &nbsp; True love stories never have endings. &nbsp; ❤</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLoveStory) ?
                        <Fade in={showLiveStream} mountOnEnter unmountOnExit {...(showLiveStream ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major">Live Stream</h2>
                                <span className="image main"><img src="/assets/clients/lilisana/images/1.jpg" alt="" /></span>
                                <h2 className="text-center">Menuju Hari Bahagia</h2>
                                <ul className="countdown text-center">
                                    <li  ><span id="days"></span>hari</li>
                                    <li  ><span id="hours"></span>jam</li>
                                    <li  ><span id="minutes"></span>menit</li>
                                    <li  ><span id="seconds"></span>detik</li>
                                </ul>
                                <hr />

                                <p className="text-center">Pernikahan kami akan ditayangkan secara <em>live</em>, silakan tekan tombol di bawah ini saat Hari-H untuk turut menjadi saksi pernikahan kami:</p>
                                <h1 className="text-center">
                                    <a href="https://instagram.com/annayohana94" target="_blank" alt="Instagram Live"><i className="fa fa-instagram"></i></a>
                                </h1>
                                <br />&nbsp;


                                <hr />
                                <p className="prayer text-center">❤ &nbsp; True love stories never have endings. &nbsp; ❤</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLiveStream(!showLiveStream) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation" target="_blank" rel="noreferrer">Vanilatte.com</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default LilisAna;
import { Helmet } from "react-helmet";
import { useState } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import GetAppIcon from '@material-ui/icons/GetApp';
import NearMeIcon from '@material-ui/icons/NearMe';
import { CSVLink } from 'react-csv';

import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";
import Footer from "./footer";

const validUsername = [
    "dianfarizmengundang",
    "pipinyolanmenikah",
    "theweddingelyandsuryo",
    "hannavikihmengundang",
    "theweddingofyuniandriki",
    "tobemrandmrsprasetya",
    "faridapanjimengundang"
];

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));


function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, uniqueLink) {
    return { name, uniqueLink };
}


const useStyles2 = makeStyles({
    table: {
        // minWidth: 500,
    },
});


const columns = [
    { id: 'name', label: 'Nama Tamu', },
    {
        id: 'link-unik',
        label: 'Link Unik',
    },

];


function NameConverter() {

    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRow] = useState([]);

    const [nameList, setNameList] = useState("");
    const [username, setUsername] = useState("");
    const [showTable, setShowTable] = useState(false);

    const [csvData, setCsvData] = useState([])

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleConvertButton = () => {
        if (!validUsername.includes(username.toLowerCase())) {
            alert("Mohon maaf, kami tidak mengenali username kamu. Jika ini adalah kesalahan, silakan hubungi kami.")
        } else {
            const allName = nameList.split("\n");
            let tempRow = []
            let tempCsvData = [["Nama Tamu", "Link Unik"]];
            for (let i = 0; i < allName.length; i++) {
                const linkUnik = encodeURI("https://album.love/" + username + "?kepada=" + allName[i]).replaceAll("%20", "+");
                tempRow.push(createData(allName[i], <a href={linkUnik} target="_blank" rel="noreferrer">{linkUnik}</a>))

                tempCsvData.push([allName[i], [linkUnik]]);
            }
            setCsvData(tempCsvData);
            setRow(tempRow)
            setShowTable(true);
        }
    }
    return (
        <>
            <Helmet>
                <title>Konversi Nama Tamu Undangan</title>
                <meta name="description" content="Free tool by Vanilatte" />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">


                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        <Navbar showLogo={true} showMenu={true} />

                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-6">
                                        <div className="sectionHeading -md">
                                            <p className="sectionHeading__subtitle">Tool</p>
                                            <h2 className="sectionHeading__title"><span className="text-accent">Konversi</span> <br />Nama Tamu</h2>
                                            <p className="text-lg sm:text-base mt-24">Tool gratis untuk konversi nama tamu undangan menjadi link unik yang siap untuk dibagikan. Silakan digunakan dengan bijak.</p>
                                            <br />&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div className="row x-gap-60 y-gap-48 layout-pt-md"></div>
                            </div>
                            <div className="container">

                                <form noValidate autoComplete="off">
                                    <TextField
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="tool tool-username"
                                        required
                                        label="Username"
                                        // variant="outlined"
                                        fullWidth={true}
                                        helperText="Username yang kamu miliki"
                                        placeholder="BandungRoro"
                                    />

                                    <TextField
                                        onChange={(e) => setNameList(e.target.value)}
                                        className="tool tool-guest"
                                        required
                                        label="Daftar Nama Tamu"
                                        variant="outlined"
                                        multiline
                                        rows={15}
                                        fullWidth={true}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText="1 nama dalam 1 baris tanpa dipisah tanda koma (,)"
                                        placeholder="Raden Wijaya 
Jayanagara 
Tribhuwana Wijayatunggadewi 
Hayam Wuruk 
Wikramawardhana 
Suhita 
Dyah Kertawijaya 
Rajasawardhana "
                                    />
                                    <Button disabled={!((nameList.length > 0) && (username.length > 0))} className="tool-button" variant="contained" color="primary" onClick={handleConvertButton}>Buat Link Unik &nbsp; <NearMeIcon /></Button>

                                    <CSVLink filename={username + "_inv_url.csv"} data={csvData} ><Button className="tool-button" disabled={!showTable} variant="contained" color="secondary">Download CSV &nbsp; <GetAppIcon /></Button></CSVLink>
                                    {
                                        (showTable) ?
                                            <>
                                                {/* <p className="desktop-limiter">Bagikan undanganmu kepada tamu undangan yang ingin kamu tuju menggunakan link unik yang berada di kolom sebelah kanan. Undanganmu akan secara otomatis menampilkan nama tamu undangan yang dituju.</p> */}
                                                <TableContainer className="tool-table" component={Paper}>
                                                    <Table className={classes.table} aria-label="custom pagination table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                    // style={{ minWidth: column.minWidth }}
                                                                    >
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(rowsPerPage > 0
                                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                : rows
                                                            ).map((row) => (
                                                                <TableRow key={row.name}>
                                                                    <TableCell component="th" scope="row">
                                                                        {row.name}
                                                                    </TableCell>
                                                                    <TableCell style={{ "userSelect": "text" }} align="">
                                                                        {row.uniqueLink}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                            {emptyRows > 0 && (
                                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                                    <TableCell colSpan={6} />
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                        <TableFooter>
                                                            <TableRow>
                                                                <TablePagination
                                                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                                                    colSpan={3}
                                                                    count={rows.length}
                                                                    rowsPerPage={rowsPerPage}
                                                                    page={page}
                                                                    SelectProps={{
                                                                        inputProps: { 'aria-label': 'rows per page' },
                                                                        native: true,
                                                                    }}
                                                                    onChangePage={handleChangePage}
                                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                                    ActionsComponent={TablePaginationActions}
                                                                    labelRowsPerPage="Nama per halaman"
                                                                />
                                                            </TableRow>
                                                        </TableFooter>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                            :
                                            null
                                    }
                                </form>
                            </div>

                        </section>

                        <Footer />
                    </main>

                </div>
            </div>
        </>
    )
}

export default NameConverter;
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import Rellax from "rellax";

import Audio from '../../components/audio';
import Covidmodal from '../../components/covidModal';
import { SRLWrapper } from 'simple-react-lightbox';

let guestName = "";
const gbUrl = "https://sheet.best/api/sheets/5543f590-d7f9-4a07-a333-270cb822fd66";
const gbKey = "1LqANZtc-XzaPUkmaNwPMW27VBlo3Cq0UxXbjnBog4VI";

let sesi = "11:00 - 12:00";

function PipinYolan() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sesiParam = params.get('q');
        if (sesiParam != null) sesi = "12:00 - 13:00"
    })

    useEffect(() => {
        new Rellax(".rellax", {})
    })
    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                googleData.reverse();
                setGbMsgList(googleData);
                setGbWaiting(false);
            },
            simpleSheet: true
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Pipin & Yolan</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link href="/assets/clients/pipinyolan/css/main.css" rel="stylesheet" />
                <script src="/assets/global/countdown.js" date="2021-6-6 09:00"></script>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Great+Vibes&amp;display=swap" />
                <link href="https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap" rel="stylesheet" />
                {/* <link href="/assets/clients/pipinyolan/css/font-awesome/css/all.min.css" rel="stylesheet" /> */}
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link href="/assets/clients/pipinyolan/css/bootstrap.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <script src="/assets/global/cursor.js"></script>
                <link href="/assets/global/cursor.css" rel="stylesheet" />

                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />

                <script src="https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js"></script>
            </Helmet>
            <Audio music="Shane_Filan_-_Beautiful_In_White.mp3" />
            {/* <Covidmodal /> */}
            <div className="cursor"></div>
            <main className="page-content">
                <section className="ww-home-page" id="home">
                    <div className="ww-wedding-announcement d-flex align-items-center justify-content-start">
                        <div className="container ww-announcement-container">
                            <p animation-delay="200" animation-type="fadeInUp" className="wedding-of">The Wedding Of</p>
                            <p animation-delay="400" animation-type="fadeInUp" className="ww-couple-name">Pipin & Yolan</p>
                            <p animation-delay="600" animation-type="fadeInUp" className="kepada">{guestName}</p><br /><br />
                            {/* <p><strong>Sabtu, 1 Januari 2022</strong></p> */}
                        </div>
                        <div className="custom-shape-divider-bottom" style={{ "transform": "scaleY(-1) scaleX(-1)" }}>
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                            </svg>
                        </div>
                    </div>
                </section>
                <section className="ww-section bg-dark" id="couple">
                    <div className="container first-container">
                        <p className="text-center"><img animation-delay="800" animation-type="fadeInDown" src="assets/clients/pipinyolan/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="300" animation-type="zoomIn" >Kami yang Berbahagia</span>
                        </h2>
                        <p className="text-center"><img animation-delay="400" animation-type="fadeInUp" src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                        <br />&nbsp;
                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="mt-3"><img animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="/assets/clients/pipinyolan/images/pipin_resize.jpg" alt="Bride" />

                                    <br />&nbsp;
                                    <h3 animation-delay="600" animation-type="fadeInUp" className="h2 ww-title">Mutrofin, S. Pd. (Pipin)</h3>
                                    <p animation-delay="1200" animation-type="zoomIn" className="pt-3 text-center text-muted">Putri keempat dari<br /><em>Bp. Giyono (alm.) & Ibu Sukinah (almh.)</em><br />Jedingan, RT 007/-, Dk Peni, Palbapang, Bantul</p>
                                    <br />&nbsp;
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mt-3"><img animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="/assets/clients/pipinyolan/images/yolan_resize.jpg" alt="Groom" />
                                    <br />&nbsp;
                                    <h3 animation-delay="400" animation-type="fadeInUp" className="h2 ww-title">Yolanda Rosa Agusta, A. Md. Fis (Yolan)</h3>
                                    <p animation-delay="800" animation-type="zoomIn" className="pt-3 text-center text-muted">Putra pertama dari<br /><em>Bp. Sigit Waluyo & Ibu Sarinem</em><br />Jl. Pemuda, Teruman, Bantul, Bantul</p>
                                </div>
                            </div>
                        </div>
                        &nbsp;
                        <p className="text-center"><img animation-delay="1200" animation-type="zoomIn" src="assets/clients/pipinyolan/images/grass.png" className="" alt="Separator" /></p>
                        <div className="width-limiter">
                            <p animation-delay="300" animation-type="rotateInUpRight" className="pt-5 text-center text-muted prayer">Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.</p>
                            <p animation-delay="600" animation-type="rotateInUpLeft" className="ww-title text-center">QS Ar-Rum:21</p>
                        </div>
                    </div>
                </section>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder-right.png" />
                </div>
                <div class="svg-divider-triangle-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="events">
                    <div className="container ww-wedding-event">
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <h2 className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="100" animation-type="zoomIn" >Turut Mengundang</span>
                        </h2>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada resepsi pernikahan kami yang akan diselenggarakan pada:</p>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h2 text-center ww-sub-title">Akad:</p><br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h4 text-center ww-sub-title">Minggu</p>
                        <p animation-delay="300" animation-type="fadeInDown" className="h3 text-center ww-sub-title">6 / Juni / 2021</p>
                        <p animation-delay="300" animation-type="zoomIn" className="text-center"><img src="assets/clients/pipinyolan/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="zoomIn" className="h3 text-center ww-sub-title">09:00 - Selesai</p>
                        <p animation-delay="300" animation-type="fadeInUp" className="text-center"><img src="assets/clients/pipinyolan/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="fadeInUp" className="h4 text-center ww-sub-title">Jedingan, RT 007/-, Dk Peni, Palbapang, Bantul</p>
                        <br />
                        <br />
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" target="_blank" rel="noreferrer" href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Akad+Nikah+Pipin+%26+Yolan&details=Detail+acara+bisa+dilihat+di+https://album.love/PipinYolanMenikah&location=-7.9106032,110.3278018&dates=20210606T020000Z/20210606T030000Z"><i className="fa fa-calendar-o"></i> &nbsp; Simpan di Google Calendar</a>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h2 text-center ww-sub-title">Resepsi:</p><br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h4 text-center ww-sub-title">Minggu</p>
                        <p animation-delay="300" animation-type="fadeInDown" className="h3 text-center ww-sub-title">6 / Juni / 2021</p>
                        <p animation-delay="300" animation-type="zoomIn" className="text-center"><img src="assets/clients/pipinyolan/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="zoomIn" className="h3 text-center ww-sub-title">{sesi}</p>
                        <p animation-delay="300" animation-type="fadeInUp" className="text-center"><img src="assets/clients/pipinyolan/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="fadeInUp" className="h4 text-center ww-sub-title">Jedingan, RT 007/-, Dk Peni, Palbapang, Bantul</p>
                        <br />
                        <br />
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" target="_blank" rel="noreferrer" href="https://www.google.com/calendar/render?action=TEMPLATE&text=Resepsi+Pernikahan+Pipin+%26+Yolan&details=Detail+acara+bisa+dilihat+di+https%3A%2F%2Falbum.love%2FPipinYolanMenikah&location=-7.9106032%2C110.3278018&dates=20210606T040000Z%2F20210606T060000Z"><i className="fa fa-calendar-o"></i> &nbsp; Simpan di Google Calendar</a>
                        <br />&nbsp;
                        <hr />
                        <small animation-delay="300" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Tanpa mengurangi rasa hormat sedikit pun, acara resepsi pernikahan akan dilakukan dengan tetap mengikuti protokol kesehatan sesuai anjuran pemerintah.</small>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-dark" id="people">
                    <div className="container text-center">
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/pipinyolan/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >Lokasi Acara</h2>
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami.</p>
                        <br />
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1975.9273572640175!2d110.32643118851679!3d-7.910241884970743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zN8KwNTQnMzguMiJTIDExMMKwMTknNDAuMSJF!5e0!3m2!1sen!2sid!4v1619678406206!5m2!1sen!2sid" width="90%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <br />
                        <br />
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" href="https://www.google.com/maps?q=-7.9106032,110.3278018&z=17&hl=en" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah</a>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleX(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="people">
                    <div className="container text-center">

                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="zoomIn" className="h1 ww-title text-center">Menuju Hari Bahagia</p>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                        <ul className="countdown text-muted">
                            <li animation-delay="200" animation-type="zoomIn" ><span id="days"></span>hari</li>
                            <li animation-delay="400" animation-type="zoomIn" ><span id="hours"></span>jam</li>
                            <li animation-delay="600" animation-type="zoomIn" ><span id="minutes"></span>menit</li>
                            <li animation-delay="800" animation-type="zoomIn" ><span id="seconds"></span>detik</li>
                        </ul>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder-right.png" />
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-dark" id="gallery">
                    <div className="ww-photo-gallery">
                        <div className="container">
                            <p className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                            <div className="h1 text-center pb-3 ww-title" >Galeri</div>
                            <p className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                            <br />
                            <div className="ww-gallery" >
                                <div className="card-columns">
                                    <SRLWrapper>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="/assets/clients/pipinyolan/images/galeri_1.jpg" alt="Pipin & Yolan" /></div>
                                        <div animation-delay="400" animation-type="zoomIn" className="card"><LazyLoadImage className="img-fluid gallery-pic" src="/assets/clients/pipinyolan/images/galeri_2.jpg" alt="Pipin & Yolan" /></div>
                                    </SRLWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleX(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/pipinyolan/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-light" id="gallery">
                    <div className="ww-photo-gallery">
                        <div className="gb-container">
                            <p className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                            <div className="h1 text-center pb-3 ww-title" >RSVP & Guestbook</div>
                            <p className="text-center"><img src="assets/clients/pipinyolan/images/divider.png" className="divider" alt="Separator" /></p>
                            <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Terima kasih atas doa dan ucapannya 😊<br />Acara pernikahan kami berlangsung dengan khidmat dan lancar 😊</p>

                            <br />
                            {
                                (gbSent) ?
                                    <>
                                        <br />
                                        <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                    </>
                                    :
                                    <>

                                        {/* <form onSubmit={((e) => gbSubmitHandler(e))}>
                                            <fieldset>
                                                <div >
                                                    <div >
                                                        <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div >
                                                        <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div >
                                                        <select animation-delay="300" animation-type="fadeInUp" onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                            <option value="" disabled selected>Apakah bisa hadir?</option>
                                                            <option value="yes">Ya, hadir</option>
                                                            <option value="no">Maaf, tidak bisa hadir</option>
                                                            <option value="maybe">Belum tahu</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div >
                                                    {
                                                        (gbAtt === "yes") ?
                                                            <>
                                                                <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                    <option value="1" selected>1 orang</option>
                                                                    <option value="2-4">2-4 orang</option>
                                                                    <option value=">5">{">"} 5 orang</option>
                                                                </select>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </div>

                                                <div >
                                                    <div >
                                                        <textarea animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan untuk mempelai" required autoComplete="off"></textarea>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div >
                                                        <br />
                                                        <button animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} id="singlebutton" className="gb-btn" name="singlebutton" type="submit">Kirim</button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form> */}
                                    </>
                            }
                            <div>
                                <br />&nbsp;
                                <button className="gb-btn"
                                    animation-delay="300" animation-type="fadeInUp"
                                    onClick={((e) => {
                                        e.preventDefault();
                                        setGbWaiting(true);
                                        setShowGbMsg(!showGbMsg);
                                        if (!showGbMsg) requestGuestBook();
                                    })} >{(showGbMsg) ? "Tutup" : "Lihat"} Semua Pesan</button>
                            </div>
                            {
                                (showGbMsg) ?
                                    <>
                                        {
                                            (gbWaiting) ?
                                                <>
                                                    <br />&nbsp;
                                                    <p style={{ "textAlign": "center" }}>Mohon tunggu...</p>
                                                </>
                                                :
                                                <div className="gb-wrap">
                                                    {messageList}
                                                </div>
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <footer className="ww-footer bg-dark">
                    <div className="container text-center py-4">
                        <p className="my-0">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Vanilatte Wedding e-Invitation Service">Vanilatte.com</a></p>
                        &nbsp;
                    </div>
                </footer>
            </main>

        </>
    )
}

export default PipinYolan;

import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import 'moment/locale/id';

import Audio from "../../components/audio";
import Covidmodal from "../../components/covidModal";
import { SRLWrapper } from "simple-react-lightbox";
import QrCodeGenerator from "../scanner/qrCodeGenerator";

let guestName = "";
let kepada = "";
const gbUrl = "https://sheet.best/api/sheets/f3c2c57a-007f-4370-bf41-df407d87f099";
const gbKey = "1TE37JA6Oo_N-NEJfiePH7MWGYJAcOvwBRpt0rEngreo";
function ElySuryo() {

    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
        if (kepada != null) setShowQrCode(true);
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })
    return (
        <>
            <Helmet>
                <title>The Wedding of Ely & Suryo</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                {/* <script src="assets/clients/elysuryo/js/plugins.js"></script> */}
                {/* <script src="assets/clients/elysuryo/js/modernizr.js"></script> */}
                {/* <script src="assets/clients/elysuryo/js/pace.min.js"></script> */}
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link href="/assets/global/cursor.css" rel="stylesheet" />
                <link rel="stylesheet" href="assets/clients/elysuryo/css/base.css" />
                <link rel="stylesheet" href="assets/clients/elysuryo/css/vendor.css" />
                <link rel="stylesheet" href="assets/clients/elysuryo/css/main.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                {/* <script src="assets/clients/elysuryo/js/main.js"></script> */}
                <script src="/assets/global/countdown.js" date="2021-07-21 09:00"></script>
                <script src="/assets/global/disabler.js"></script>
                <script src="/assets/global/cursor.js"></script>
            </Helmet>
            {/* <Covidmodal /> */}
            <Audio music="Ely_-_Bg_Music.mp3" />
            <div className="cursor"></div>
            <section id="home">
                <div className="overlay"></div><div className="gradient-overlay"></div>
                <div className="home-content-table">
                    <div className="home-content-tablecell">
                        <div className="row">
                            <div className="col-twelve">
                                <div className="home-bottom-text">
                                    <h3 animation-delay="200" animation-type="fadeInUp">The Wedding of</h3>
                                    <h1 animation-delay="500" animation-type="fadeInDown">Ely & Suryo</h1>
                                    <ul className="home-section-links">
                                        <li animation-delay="800" animation-type="zoomIn" className="kepada">{guestName}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#about" className="scroll-icon smoothscroll">
                    <span className="icon-DownArrow"></span>
                </a>
            </section>
            <section id="about">
                <div className="about-profile-bg"></div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>
                <br className="hide-desktop" />
                <br className="hide-desktop" />
                <br className="hide-desktop" />
                <br className="hide-desktop" />
                <br className="hide-desktop" />
                <div className="about-wrap">
                    <div className="about-services">
                        <div className="intro">
                            <h3 animation-delay="200" animation-type="fadeInDown">Ely</h3>
                            <h1 animation-delay="400" animation-type="fadeInRight">Ely Novianti, S.Pd</h1>
                            <div className="profile-pic-polaroid">
                                <img animation-delay="600" animation-type="fadeInUp" src="assets/clients/elysuryo/images/ely-square.jpg" alt="Bride" />
                                <p animation-delay="200" animation-type="fadeInUp"><br />Putri kedua dari<br /><strong>Bapak Bisri A. Hamid (Alm) &<br /> Ibu Siti Nuraeni</strong> <br /><em>Dusun Luwuk RT/RW : 004/002, Desa Pekiringan Alit, Kecamatan Kajen, Kab. Pekalongan</em></p>
                            </div>
                        </div>
                    </div>
                    <div className="about-profile">
                        <div className="intro">
                            <h3 animation-delay="200" animation-type="fadeInDown">Suryo</h3>
                            <h1 animation-delay="400" animation-type="fadeInLeft">Suryo Bayu Tirto Aji, S.Ag</h1>
                            <div className="profile-pic-polaroid">
                                <img animation-delay="300" animation-type="fadeInUp" src="assets/clients/elysuryo/images/suryo-square.jpg" alt="Groom" />
                                <p animation-delay="200" animation-type="fadeInUp"><br />Putra pertama dari<br /><strong>Bapak Sugino &<br /> Ibu Ribut Makarti</strong> <br /><em>Desa Toyareka RT/RW 003/004, Kec. Kemangkon, Kab. Purbalingga</em></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="skills">
                <div className="overlay"></div>
                <div className="gradient-overlay"></div>
                <div className="row wide">
                    <div className="col-skills">
                        <p animation-delay="200" animation-type="fadeInDown"><em>Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ~ QS An-Nahl 72</em></p>
                        <div className="section-intro">
                            <br />&nbsp;
                            <img animation-delay="200" animation-type="zoomIn" className="center-thing" src="assets/clients/elysuryo/images/grass.png" alt="Separator" />
                            <br />&nbsp;
                            <h1 animation-delay="200" animation-type="fadeInUp">Turut Mengundang</h1>
                            <p animation-delay="200" animation-type="fadeInUp">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                            <h3 animation-delay="200" animation-type="fadeInDown">Akad</h3>
                            <p animation-delay="200" animation-type="fadeInUp">Rabu, 21 Juli 2021<br />
                                09:00 - 11:30 WIB<br />
                                Dusun Luwuk RT/RW : 004/002, Desa Pekiringan Alit, Kecamatan Kajen, Kab. Pekalongan</p>
                            &nbsp;
                            <h3 animation-delay="200" animation-type="fadeInDown">Tasyakuran Pernikahan</h3>
                            <p animation-delay="200" animation-type="fadeInUp">Ahad, 25 Juli 2021<br />
                                10:00 - 12:00 WIB<br />
                                Desa Toyareka RT/RW : 003/004, Kec. Kemangkon, Kab. Purbalingga</p>
                        </div>
                        &nbsp;
                        {/* <a animation-delay="200" animation-type="fadeInUp" className="google-calendar" href="https://maps.app.goo.gl/jwCkJNKUqHbufrD98" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi Akad</a> */}
                    </div>
                </div>
            </section>
            <section id="contact">
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Peta Lokasi Akad</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead">Gunakan peta di bawah ini untuk mencari rute ke lokasi acara akad kami</p>
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7919.521442977614!2d109.56034242420438!3d-7.037383829667654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6fe0213d8d6cf5%3A0x669b221176d7de23!2sLuwuk%2C%20Pekiringan%20Alit%2C%20Kajen%2C%20Pekalongan%2C%20Central%20Java!5e0!3m2!1sen!2sid!4v1621322427790!5m2!1sen!2sid" width="100%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <center><a animation-delay="200" animation-type="fadeInUp" className="google-calendar" href="https://maps.app.goo.gl/jwCkJNKUqHbufrD98" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi Akad</a></center>
                    </div>
                </div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    {/* <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li> */}
                </ul>

            </section>
            <hr />
            <section id="contact">
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Peta Lokasi Tasyakuran Pernikahan</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead">Gunakan peta di bawah ini untuk mencari rute ke lokasi acara tasyakuran pernikahan kami</p>
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.3374166060817!2d109.36367631477579!3d-7.427861994641058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwMjUnNDAuMyJTIDEwOcKwMjEnNTcuMSJF!5e0!3m2!1sen!2sid!4v1621493765944!5m2!1sen!2sid" width="100%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <center><a animation-delay="200" animation-type="fadeInUp" className="google-calendar" href="https://maps.app.goo.gl/Qu6iHytBP9n9kyZd8" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi Tasyakuran Pernikahan</a></center>
                    </div>
                </div>
                <ul className=" corner-ul">
                    {/* <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li> */}
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>

            </section>
            <section id="contact">
                <div className="overlay"></div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    {/* <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li> */}
                </ul>
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInDown">Menuju Hari Bahagia</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead ">Whenever I look at you, I see the rest of my life in front of my eyes.</p>
                        <ul className="countdown text-center text-muted">
                            <li animation-delay="200" animation-type="zoomIn" ><span id="days"></span>hari</li>
                            <li animation-delay="400" animation-type="zoomIn" ><span id="hours"></span>jam</li>
                            <li animation-delay="600" animation-type="zoomIn" ><span id="minutes"></span>menit</li>
                            <li animation-delay="800" animation-type="zoomIn" ><span id="seconds"></span>detik</li>
                        </ul>
                    </div>
                </div>
                <br />&nbsp;
                <hr />
                <br />&nbsp;
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInDown">Amplop Digital</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead ">Demi ikut memutus rantai penyebaran COVID-19, kami sangat merekomendasikan pemberian sumbangan/amplop dilakukan secara digital melalui pilihan berikut ini:</p>
                        <h2 style={{ "color": "#828282" }} animation-delay="200" animation-type="fadeInDown">Transfer ke Bank BRI:</h2>
                        <h2 style={{ "color": "#828282" }} animation-delay="200" animation-type="fadeInDown">1556-01-001418-50-9 a.n Ely Novianti</h2>
                        <br />&nbsp;
                        <h2 style={{ "color": "#828282" }} animation-delay="200" animation-type="fadeInDown">ShopeePay (Scan atau Klik QR Code ini):</h2>
                        <a href="https://shp.ee/ahxv2ek?smtt=0.0.3" target="_blank" rel="noreferrer"><img animation-delay="600" animation-type="fadeInUp" src="assets/clients/elysuryo/images/qr-code-ely.jpeg" alt="QR Code" /></a>
                        <br />&nbsp;
                    </div>
                </div>
            </section>
            {/* 
            <section id="portfolio">
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3 animation-delay="200" animation-type="fadeInDown" >Gallery of</h3>
                        <h1 animation-delay="200" animation-type="fadeInUp" >Bandung & Roro</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead ">I hope you know that every time I tell you to get home safe, stay warm, have a good day, or sleep well what I am really saying is I love you. I love you so damn much that it is starting to steal other words’ meanings.</p>
                    </div>
                </div>
                <div className="row portfolio-content">
                    <div className="col-twelve">
                        <div className="bricks-wrapper">
                            <SRLWrapper>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-1.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-3.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-4.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-5.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-6.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-8.jpg" alt="Gallery" />
                                </div>
                            </SRLWrapper>
                        </div>
                    </div>
                </div>
            </section> */}

            {
                (showQrCode) ?
                    <>
                        <section id="contact">
                            <div className="row wide">
                                <QrCodeGenerator
                                    className="section-intro"
                                    value={kepada}
                                    bgColor="#ffffff"
                                    fgColor="#000000"
                                    size={256}
                                    fontColor="#828282"
                                />
                            </div>
                        </section>
                    </>
                    : null
            }
            <section id="contact">
                <div className="overlay"></div>
                <ul className=" corner-ul">
                    {/* <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li> */}
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <img src="assets/clients/elysuryo/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <img animation-delay="200" animation-type="fadeInUp" src="assets/clients/elysuryo/images/grass.png" alt="Divider" /><br />&nbsp;
                        <p animation-delay="200" animation-type="fadeInDown" className="lead ">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.<br /><br />Hormat kami,</p>
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Ely & Suryo</h1><br />&nbsp;
                        <img animation-delay="200" animation-type="fadeInDown" src="assets/clients/elysuryo/images/grass.png" alt="Divider" />
                    </div>
                </div>
            </section>
            <section id="portfolio">

                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3 animation-delay="200" animation-type="fadeInDown" >Leave us your wishes</h3>
                        <h1 animation-delay="200" animation-type="fadeInUp" >RSVP & Guestbook</h1>
                        {
                            (gbSent) ?
                                <>
                                    <br />
                                    <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                </>
                                :
                                <>

                                    <form onSubmit={((e) => gbSubmitHandler(e))}>
                                        <fieldset>
                                            <div >
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div>
                                                <div >
                                                    <select animation-delay="300" animation-type="fadeInUp" onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                        <option value="" disabled selected>Apakah bisa hadir?</option>
                                                        <option value="yes">Ya, hadir</option>
                                                        <option value="no">Maaf, tidak bisa hadir</option>
                                                        <option value="maybe">Belum tahu</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div >
                                                {
                                                    (gbAtt === "yes") ?
                                                        <>
                                                            <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                <option value="1" selected>1 orang</option>
                                                                <option value="2-4">2-4 orang</option>
                                                                <option value=">5">{">"} 5 orang</option>
                                                            </select>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div >
                                                <div >
                                                    <textarea animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan kepada mempelai" required autoComplete="off"></textarea>
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <button animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} id="singlebutton" name="singlebutton" type="submit">Kirim</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </>
                        }
                        <div>
                            <br />&nbsp;
                            <button
                                animation-delay="300" animation-type="fadeInUp"
                                onClick={((e) => {
                                    e.preventDefault();
                                    setGbWaiting(true);
                                    setShowGbMsg(!showGbMsg);
                                    if (!showGbMsg) requestGuestBook();
                                })} >{(showGbMsg) ? "Tutup" : "Lihat"} Semua Pesan</button>
                        </div>
                        {
                            (showGbMsg) ?
                                <>
                                    {
                                        (gbWaiting) ?
                                            <>
                                                <br />&nbsp;
                                                <p>Mohon tunggu...</p>
                                            </>
                                            :
                                            <div className="gb-wrap">
                                                {messageList}
                                            </div>
                                    }
                                </>
                                :
                                <>
                                </>
                        }
                    </div>
                </div>
            </section>

            <footer>
                <div className="row">
                    <div className="col-twelve tab-full">
                        <div className="copyright">
                            <span>Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation">Vanilatte.com</a></span>
                            <br />&nbsp;
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default ElySuryo;
import { useEffect, useState } from "react";
import Tabletop from 'tabletop';
import Loader from "react-loader-spinner";
import axios from "axios";

import Kinanthi from "../free/kinanthi";
import Maharani from "../free/maharani";
import Agna from "../free/agna";
import Gayatri from "../free/gayatri";
import Larasati from "../free/larasati";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";

// const docKey = "1HOhqsqt84WwFCn-mPAFvRz6_vUTiuiaKWR9BRNTf8nI"; // old one
const docKey = "1h5HruqfmcMQXMzrReY43fDyLhOI8scp4dFo23IohV1c";

const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];

function WeddingPage() {
    const [weddingId, setWeddingId] = useState(null);
    const [invList, setInvList] = useState([]);
    const [weddingData, setWeddingData] = useState({});
    const [invitationFound, setInvitationFound] = useState(false);
    const [pageTitle, setPageTitle] = useState("Our Wedding Invitation");
    const [theme, setTheme] = useState(null);


    const requestGuestBook = (() => {
        // Old method
        // Tabletop.init({
        //     key: docKey,
        //     callback: googleData => {
        //         console.log(googleData);
        //         googleData.reverse();
        //         setInvList(googleData);
        //     },
        //     simpleSheet: true
        // })

        fetch(`https://docs.google.com/spreadsheets/d/${docKey}/gviz/tq?tqx=out:json`)
            .then(res => res.text())
            .then(text => {
                const json = JSON.parse(text.substr(47).slice(0, -2))
                // console.log(json);
                setInvList(json.table);
            })
    })

    useEffect(() => {
        let path = (window.location.pathname).split("/")[(window.location.pathname).split("/").length - 1];
        setWeddingId(path);
    })

    useEffect(() => {
        requestGuestBook();
    }, [])

    useEffect(() => {
        let ivtFound = false;
        if ((invList.rows) && (invList.rows.length > 0)) {
            let rows = invList.rows;
            let cols = invList.cols;
            for (let i = 0; i < rows.length; i++) {
                if ((rows[i].c) && (rows[i].c[2].v.toLowerCase() === weddingId.toLowerCase())) {
                    let ourWeddingData = {}
                    //             setWeddingData(invList[i])
                    //             ivtFound = true;
                    for (let k = 0; k < cols.length; k++) {
                        let rowName = ((cols[k].label).split(" "))[0]
                        let rowValue = ((rows[i].c[k])) ? rows[i].c[k].v : "-"
                        ourWeddingData[rowName] = rowValue
                    }
                    setWeddingData(ourWeddingData)
                    setInvitationFound(true)
                    break;
                }
            }
        }
        // Old method
        // if (invList.length > 0) {
        //     for (let i = 0; i < invList.length; i++) {
        //         if (invList[i].kode_unik.toLowerCase() === weddingId.toLowerCase()) {
        //             setWeddingData(invList[i])
        //             ivtFound = true;
        //         }
        //     }
        //     if (!ivtFound) window.location.href = "/404";
        // }

    }, [invList])

    useEffect(() => {
        if (Object.keys(weddingData).length > 0)
            setInvitationFound(true)

        if (weddingData.nama_panggilan !== undefined)
            setPageTitle("The Wedding of " + weddingData.nama_panggilan);

    }, [weddingData])

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content="We invite you to share with us a celebration of love!" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
            </Helmet>
            {
                (!invitationFound) ?
                    <div style={{
                        "position": "absolute",
                        "top": "50%",
                        "left": "50%",
                        "marginRight": "-50%",
                        "transform": "translate(-50%, -50%)",
                        "textAlign": "center"
                    }}>
                        <Loader
                            type="Hearts"
                            color="#ffcccc"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                        <p animation-delay="200" animation-type="fadeInUp" style={{ "color": "darkgrey" }}>Membuka undangan...</p>
                    </div>
                    :
                    <>
                        {
                            ((weddingData.tema_undangan).toLowerCase() === "kinanthi") ?
                                <Kinanthi
                                    weddingData={weddingData}
                                />
                                : (
                                    ((weddingData.tema_undangan).toLowerCase() === "maharani") ?
                                        <Maharani
                                            weddingData={weddingData}
                                        />
                                        : (
                                            ((weddingData.tema_undangan).toLowerCase() === "agna") ?
                                                <Agna
                                                    weddingData={weddingData}
                                                />
                                                : (
                                                    ((weddingData.tema_undangan).toLowerCase() === "gayatri") ?
                                                        <Gayatri
                                                            weddingData={weddingData}
                                                        />
                                                        : (
                                                            ((weddingData.tema_undangan).toLowerCase() === "larasati") ?
                                                                <Larasati
                                                                    weddingData={weddingData}
                                                                />
                                                                : <Kinanthi
                                                                    weddingData={weddingData}
                                                                />
                                                        )
                                                )
                                        )
                                )
                        }
                    </>
            }
        </>
    )
}

export default WeddingPage
import Helmet from "react-helmet";

function Bio() {
    return (
        <>
            <Helmet>
                <meta http-equiv="Content-Type" content="text/html; charset=windows-1252" />

                <title>Premium Wedding Invitation</title>

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href="assets/home/css/bio.css" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous" />
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossorigin="anonymous"></script>
            </Helmet>
            <div class="container">
                <div class="col-xs-12">
                    <div class="text-center" style={{ "paddingTop": "30px", "paddingBottom": "30px" }}>
                        <img class="backdrop linktree" />
                        <h2 style={{ "color": "#ffffff", "paddingTop": "20px" }}>Undangan Pernikahan 1 Menit</h2>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="col-xs-12">
                    <div class="text-center">
                        <div style={{ "paddingBottom": "30px" }}>
                            <a href="/buat-undangan" type="button" class="btn btn-outline-light shake" style={{ "width": "80%", "paddingTop": "10px", "paddingBottom": "10px", "fontWeight": "600" }}><i className="fa fa-gift"></i> &nbsp; Buat Undangan  Dalam 1 Menit</a>
                        </div>
                        {/* <div style={{"paddingBottom": "30px"}}>
                            <a href="/voucher" type="button" class="btn btn-outline-light" style={{"width": "80%", "paddingTop": "10px", "paddingBottom":"10px", "fontWeight": "600"}}><i className="fa fa-shopping-cart"></i> &nbsp; Beli Voucher Undangan</a>
                        </div> */}
                        {/* <div style={{"paddingBottom": "30px"}}>
                            <a href="/" type="button" class="btn btn-outline-light" style={{"width": "80%", "paddingTop":"10px", "paddingBottom":"10px", "fontWeight": "600"}}><i className="fa fa-globe"></i> &nbsp; Website</a>
                        </div> */}
                        <div style={{ "paddingBottom": "30px" }}>
                            <a href="/ask" type="button" class="btn btn-outline-light" style={{ "width": "80%", "paddingTop": "10px", "paddingBottom": "10px", "fontWeight": "600" }}><i className="fa fa-whatsapp"></i> &nbsp; WhatsApp</a>
                        </div>
                        <div style={{ "paddingBottom": "30px" }}>
                            <a href="https://bridestory.com/vanilatte-com" type="button" class="btn btn-outline-light" style={{ "width": "80%", "paddingTop": "10px", "paddingBottom": "10px", "fontWeight": "600" }}><i className="fa fa-link"></i> &nbsp; Find us on Bridestory</a>
                        </div>
                        {/* <div style={{"paddingBottom": "30px"}}>
                            <a href="/testimonial" type="button" class="btn btn-outline-light" style={{"width": "80%", "paddingTop": "10px", "paddingBottom":"10px", "fontWeight": "600"}}><i className="fa fa-comments"></i> &nbsp; Testimonial</a>
                        </div> */}
                    </div>
                </div>
            </div>

            <div class="text-center">
                Made with ❤ by <a href="https://vanilatte.com" style={{ "color": "#fff", "fontWeight": "700" }}>Vanilatte</a>
            </div>



        </>
    )
}

export default Bio;
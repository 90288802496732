import { useEffect, useState } from "react";
import Fade from '@material-ui/core/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";
import Covidmodal from "../../components/covidModal";


const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];

function Kinanthi(props) {

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/assets/global/wedding/kinanthi.css" />
                <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                <script src="/assets/global/wedding/countdown.js" date={props.weddingData.akad_tanggal + "__" + props.weddingData.akad_waktu_mulai}></script>
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            {/* <Covidmodal /> */}
            <Fade
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1000 } : {})}
            >
                <div className="is-loading">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" display="none" width={0} height={0}>
                            <symbol id="icon-903" viewBox="0 0 40 40">
                                <path d="M25.5,14.7c0-1.5-0.5-2.7-1.6-3.8c-1-1-2.3-1.6-3.8-1.6c-1.5,0-2.7,0.5-3.8,1.6c-1,1-1.6,2.3-1.6,3.8s0.5,2.7,1.6,3.8 c1,1,2.3,1.6,3.8,1.6c1.5,0,2.7-0.5,3.8-1.6C25,17.4,25.5,16.1,25.5,14.7z M30.9,14.7c0,1.5-0.2,2.8-0.7,3.7l-7.6,16.2 c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.4-1.4,0.4c-0.5,0-1-0.1-1.4-0.4c-0.4-0.3-0.8-0.6-1-1.1l-7.6-16.2c-0.5-1-0.7-2.2-0.7-3.7 c0-3,1-5.5,3.1-7.6S17.2,4,20.2,4c3,0,5.5,1,7.6,3.1S30.9,11.7,30.9,14.7L30.9,14.7z" />
                            </symbol>
                        </svg>
                        <div id="wrapper">
                            <div id="main">
                                <div className="inner">
                                    <div id="container01" className="container default full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                <div id="image01" className="image"><LazyLoadImage src="assets/global/wedding/header-wedding.svg" alt="" /></div>
                                                <h3 className="fadeIn animated" id="text03">THE WEDDING OF</h3>
                                                <h1 id="text01">{props.weddingData.nama_panggilan}</h1>
                                                <p id="text02">We invite you to share with us a celebration of love</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="container02" className="container columns full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                <div>
                                                    <h3 id="text07">{props.weddingData.mempelai_wanita_nama}</h3>
                                                    <p id="text08">putri {(props.weddingData.mempelai_wanita_anak_ke).toLowerCase()} dari</p>
                                                    <p id="text09"><span>{props.weddingData.mempelai_wanita_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_wanita_ibu}</span></p>
                                                    <p id="text05">{props.weddingData.mempelai_wanita_alamat}</p>
                                                    <p id="text22">dengan<br />&nbsp;</p>
                                                </div>
                                                <div>
                                                    <h3 id="text04">{props.weddingData.mempelai_pria_nama}</h3>
                                                    <p id="text10">putra {(props.weddingData.mempelai_pria_anak_ke).toLowerCase()} dari</p>
                                                    <p id="text06"><span>{props.weddingData.mempelai_pria_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_pria_ibu}</span></p>
                                                    <p id="text11">{props.weddingData.mempelai_pria_alamat}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div id="container04" className="container default full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                {
                                                    (props.weddingData.doa !== "x") ?
                                                        <>
                                                            <h3 id="text20"><span>{prayer[props.weddingData.doa]} </span></h3>
                                                            <br /><br />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div id="container03" className="container default full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                <h2 id="text12">TURUT MENGUNDANG</h2>
                                                <p id="text19">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                                <hr id="divider01" />
                                                <br />
                                                <h3 id="text17">{props.weddingData.jenis_acara}:</h3>
                                                <p id="text18"><span>{((props.weddingData.akad_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.akad_waktu_mulai} - {props.weddingData.akad_waktu_selesai}</span><br /> <span>{props.weddingData.akad_lokasi}</span></p>
                                                {
                                                    ((props.weddingData.akad_gmaps).includes("https://") || (props.weddingData.akad_gmaps).includes("http://")) ?
                                                        <div className="center-button">
                                                            <a href={props.weddingData.akad_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                        </div>
                                                        : null
                                                }

                                                <br />
                                                <div id="image01" className="image"><LazyLoadImage style={{ "maxWidth": "70px" }} className="grass" src="assets/global/wedding/grass.png" alt="" /></div>
                                                <br />
                                                <h3 id="text17">{props.weddingData.jenis_acara_resepsi}:</h3>
                                                <p id="text16"><span>{((props.weddingData.resepsi_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.resepsi_waktu_mulai} - {props.weddingData.resepsi_waktu_selesai}</span><br /> <span>{props.weddingData.resepsi_lokasi}</span></p>

                                                {
                                                    ((props.weddingData.resepsi_gmaps).includes("https://") || (props.weddingData.resepsi_gmaps).includes("http://")) ?
                                                        <div className="center-button">
                                                            <a href={props.weddingData.resepsi_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                        </div>
                                                        : null
                                                }

                                                <br />
                                                <hr id="divider01" />
                                                <p id="text13">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                                <hr id="divider01" />
                                                <h2 id="text12">MENUJU HARI BAHAGIA</h2>
                                                <div className="parent-timer light-clr">
                                                    <div id="timer01" className="timer">
                                                        <ul />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="container05" className="container default full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                <p id="text21">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="container06" className="container default full">
                                        <div className="wrapper">
                                            <div className="inner">
                                                <p id="text14">Buat Undangan Nikah Hanya 1 Menit di <a href="/lembayung" target="_blank" rel="noreferrer">Vanilatte.com</a><br /><br /><br />
                                                    <a href="https://vanilatte.com/" target="_blank" rel="noreferrer"><i className="fa fa-globe"></i></a> &nbsp;
                                                    <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a> &nbsp;
                                                    <a href="/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    )
}

export default Kinanthi
export const convertTime = (time) => {
    let type = ""
    let formatted = ""
    let newTime = time.replace("Date(", "")
    newTime = newTime.replace(")", "")
    const comaLen = (newTime.match(/,/g) || []).length

    if (comaLen == 2) type = "date"
    else if (comaLen == 5) type = "time"

    const month = {
        1: 'Januari',
        2: 'Februari',
        3: 'Maret',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Agustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Desember'
    }

    if (type == "date") {
        newTime = newTime.split(",")
        formatted = newTime[2] + " " + month[Number(newTime[1]) + 1] + " " + newTime[0]
    }

    else if (type == "time") {
        newTime = newTime.split(",")
        if (newTime[3].length == 1) newTime[3] = "0" + newTime[3]
        if (newTime[4].length == 1) newTime[4] = "0" + newTime[4]
        formatted = newTime[3] + ":" + newTime[4]
    }

    return formatted
}

export const formatCounter = (time) => {
    let type = ""
    let formatted = ""
    let newTime = time.replace("Date(", "")
    newTime = newTime.replace(")", "")
    const comaLen = (newTime.match(/,/g) || []).length

    if (comaLen == 2) type = "date"
    else if (comaLen == 5) type = "time"


    if (type == "date") {
        newTime = newTime.split(",")
        if (newTime[1].length == 1) newTime[1] = "0" + newTime[1]
        if (newTime[2].length == 1) newTime[2] = "0" + newTime[2]
        formatted = newTime[0] + "-" + (Number(newTime[1]) + 1) + "-" + newTime[2]
    }

    else if (type == "time") {
        newTime = newTime.split(",")
        if (newTime[3].length == 1) newTime[3] = "0" + newTime[3]
        if (newTime[4].length == 1) newTime[4] = "0" + newTime[4]
        formatted = newTime[3] + ":" + newTime[4]
    }

    return formatted
}

export const prayer = {
    "QS Yasin: 36": "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "QS An-Nahl: 72": "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "QS Ar-Rum: 21": "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "QS Az-Dzariyat: 49": "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "QS An-Nur: 32": "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Yakobus 2:2": "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Matius 19:6": "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Kolese 3:14": "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Markus 10:9": "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "2 Korintius 13:11": "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    // "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    // "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    // "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    // "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    // "I will love you until the stars go out, and the tides no longer turn."
};
import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";
import Footer from "./footer";

const year = (new Date().getFullYear());

function Lembayung() {
    return (
        <>
            <Helmet>
                <title>Bikin Undangan Nikah 1 Menit Jadi</title>
                <meta name="description" content="Pelajari bagaimana membuat undangan nikah hanya dalam 1 menit jadi" />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">
                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>
                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>
                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>
                <div className="barba-container" data-barba="container">
                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>
                    <main className="">
                        {/* <Navbar showLogo={true} showMenu={true} /> */}
                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-12">
                                        <div className="sectionHeading -md">
                                            <h2 className="sectionHeading__title">Hal Yang <br /><span className="text-accent">Calon Pengantin</span> <br />Wajib Tahu!</h2>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-lg sm:text-base mt-24" style={{ "textAlign": "left" }}>
                                        <p>Sebelum membaca tulisan ini sampai selesai, coba resapi dulu yuk kak 😊</p>
                                        <br />
                                        <p>Sudah setahun lebih pandemi COVID-19 menghantui kita semua, tapi belum ada tanda-tanda penurunan yang signifikan.. </p>
                                        <br />
                                        <p>Yang ada malah makin banyak kasusnya, ya kan? 😰</p>
                                        <br />
                                        <p>Tapi masalahnya, tanggal pernikahan sudah ditentukan, semakin dekat, dan ngga bisa ditunda 😰</p>
                                        <br />
                                        <p>Mau ngundang banyak-banyak, ngga bisa...</p>
                                        <br />
                                        <p>Mau ngundang yang jauh, ngga berani...</p>
                                        <br />
                                        <p>Tapi kalau ngga ngabari, ngga enak...</p>
                                        <br />
                                        <p>Jadi bingung 😭</p>
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <p>Pernah kepikiran nggak kak kalau ternyata di tahun {year} ini undangan pernikahan itu sudah menyesuaikan jaman? 😱</p>
                                        <br />
                                        <p>Cukup dikirim lewat WhatsApp, LINE, Instagram, Facebook, Twitter, Telegram, ataupun media sosial lainnya..</p>
                                        <br />
                                        <p>Cukup sambil rebahan di kamar.. 😱</p>
                                        <br />
                                        <p>Eh, tapi ini bukan bukan undangan cetak yang difoto, lalu dikirimkan lewat chat lho ya 😛</p>
                                        <br />
                                        <p>Di {year} ini kakak sudah bisa bikin <strong>undangan pernikahan web/link</strong> yang bisa dishare dengan mudah. </p>
                                        <br />
                                        <p>Contohnya seperti ini..</p>
                                        <br />
                                        <img src="/assets/home/img/general/wa.jpg" alt="Undangan chat" style={{ "maxWidth": "85vw" }} />
                                        <br />
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <h4>Apa Itu Undangan Nikah Web/Link?</h4>
                                        <br />
                                        <p>Singkatnya, undangan nikah web/link adalah undangan pernikahan yang berupa website, dan bisa disebarkan dengan cara mengirim link web undangan tersebut 😍</p>
                                        <br />
                                        <h4>Super Praktis</h4>
                                        <br />
                                        <p>Cukup sambil rebahan di kamar, kakak sudah bisa membuat undangan pernikahan serta langsung membagikannya ke semua orang yang mau diundang lewat aplikasi di HP. Ngga perlu panas-panasan lagi di luar rumah 🥰</p>
                                        <br />
                                        <h4>Alamat Cantik 😍</h4>
                                        <br />
                                        <p>Alamat (domain) undangan kakak akan diawali dengan <strong className="text-accent">album.love</strong> (misal album.love/wedding/maharani) yang berarti Album of Love (album cinta) 🥰</p>
                                        <br />
                                        <p>Selain sangat mudah diingat dan dieja (siapa sih yang ngga tahu kata 'album' dan kata 'love' hari gini?), <strong className="text-accent">album.love</strong> ini diartikan sebagai tempat untuk mengabadikan cinta kakak bersama pasangan 🥰</p>
                                        <br />
                                        <h4>Murah Kebangetan</h4>
                                        <br />
                                        <p>Dengan <strong className="text-accent">HANYA Rp50ribu</strong> kakak sudah bisa membuat undangan pernikahan web/link yang bisa dishare ke siapapun. Duit Rp50ribu ini ngga ada apa-apanya kalau dibandingkan dengan sekali nongkrong di café, betul ngga kak? 😍</p>
                                        <br />
                                        <h4>Cukup 1x Buat</h4>
                                        <br />
                                        <p>Bandingkan dengan undangan cetak. Minimal order harus 200 lembar, dan per lembar harganya Rp5.000,-. Cuma buat undangan cetak aja harus keluar uang Rp1.000.000, itu juga cuma bisa maksimal untuk 200 tamu undangan. Kalau lebih gimana? 😰</p>
                                        <br />
                                        <p>Dengan undangan nikah web/link, kakak cukup bikin undangan 1x aja untuk semua tamu undangan 🥰</p>
                                        <br />
                                        <p>Eh, 1 undangan untuk semua tamu? 😰</p>
                                        <br />
                                        <h4>Untuk Semua Tamu</h4>
                                        <br />
                                        <p>Ya, cukup 1x buat, undangan nikah web/link bisa diperuntukkan untuk semua tamu undangan kakak 😍 Tidak akan ada lagi yang namanya kekurangan atau salah cetak jumlah undangan.</p>
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <h4>1 Menit Langsung Jadi</h4>
                                        <br />
                                        <p>Undangan pernikahan bisa dibuat secara mandiri. Cukup masukkan data-data pernikahan, undangan pernikahan langsung jadi saat itu juga 😍</p>
                                        <br />
                                        <p>Tidak perlu repot-repot menghubungi Admin untuk mengaktifkan atau membuat undangan 😍</p>
                                        <br />
                                        <h4>Sistem Voucher</h4>
                                        <br />
                                        <p>Dengan membeli voucher seharga <strong className="text-accent">Rp50ribu (Voucher Lembayung)</strong>, kakak akan mendapatkan sebuah kode unik yang tidak akan sama dengan calon pengantin lain.</p>
                                        <br />
                                        <p>Fungsi kode tersebut adalah untuk mendapatkan akses ke form pengisian data pernikahan. Tanpa kode voucher ini, data undangan tidak bisa disimpan dan undangan tidak bisa dibuat.</p>
                                        <br />
                                        <p>Kode voucher ini tidak akan expired/kadaluarsa, dan bisa digunakan untuk membuat banyak undangan (misalnya untuk mencoba semua tema yang disupport, atau karena mengadakan resepsi atau pesta pernikahan dalam beberapa sesi) 😍</p>
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <br />
                                        <h4>Tema Cantik 😍</h4>
                                        <br />
                                        <img src="/assets/home/img/template/maharani-square.png" alt="Tema Maharani" style={{ "maxWidth": "85vw" }} />
                                        <br />
                                        <p>Tidak hanya cepat dan murah, tapi kami juga menyediakan tema desain undangan yang cantik-cantik 😍 Buka halaman <a className="button -underline" target="_blank" href="/catalogue"><strong>katalog</strong></a> kami untuk melihat contoh tema desain undangan selengkapnya.</p>
                                        <br />
                                        <img src="/assets/home/img/template/gayatri-square.png" alt="Tema Maharani" style={{ "maxWidth": "85vw" }} />
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <h4>Tunggu Apa Lagi? 😍</h4>
                                        <br />
                                        <p>Ratusan pasangan calon pengantin lain sudah membuat undangan pernikahannya menggunakan layanan ini 🥰</p>
                                        <br />
                                        <p>Kini giliran kami membantu kakak untuk menyempurnakan hari bahagia kakak 🥰</p>
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <h4>Harga Rp50ribu tidak berlaku selamanya 😰</h4>
                                        <br />
                                        <h4>Kami tidak berani jamin minggu depan harga masih sama 😰</h4>
                                        <br />
                                        <h4>Jangan sampai menyesal hanya karena terlambat order 😰</h4>
                                        <br />
                                        <h4>Mumpung masih Rp50ribu kak 😍</h4>
                                        <br />
                                        <div className="col-12">
                                            <a target="_blank" href="/ask?utm_source=instagram&utm_medium=ig_post&utm_campaign=lembayung&text=Hai+Vanilatte%2C+saya+sudah+membaca+tulisan+tentang+voucher+Lembayung+dan+ingin+membeli+voucher+tersebut+%2A%28Kode%3A+Lembayung%29%2A" className="button -md -black text-white"><i className="fa fa-whatsapp"></i> &nbsp; Yuk Order Vouchernya</a>
                                        </div>
                                        <br />
                                        <br />
                                        <h4>Yuk kak, buruan order kode vouchernya 🥰</h4>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <Footer /> */}
                    </main>
                </div>
            </div>
        </>
    )
}

export default Lembayung;
import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import 'moment/locale/id';

import Audio from "../../components/audio";
import Covidmodal from "../../components/covidModal";
import { SRLWrapper } from "simple-react-lightbox";

let guestName = "";
const gbUrl = "https://sheet.best/api/sheets/38ffa5b8-f680-417c-8900-127a8db4ac37";
const gbKey = "1yiO5E72VwrQ2FHRVGCOJ7cTlTgDO2ZY7D-apwy1e7-Q";
function Artavika() {

    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })
    return (
        <>
            <Helmet>
                <title>Artavika | Vanilatte</title>
                <meta name="description" content="Artavika Premium Wedding Invitation by Vanilatte" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                {/* <script src="/assets/template/artavika/js/plugins.js"></script> */}
                {/* <script src="/assets/template/artavika/js/modernizr.js"></script> */}
                {/* <script src="/assets/template/artavika/js/pace.min.js"></script> */}
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link href="/assets/global/cursor.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/template/artavika/css/base.css" />
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <link rel="stylesheet" href="/assets/template/artavika/css/main.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                {/* <script src="/assets/template/artavika/js/main.js"></script> */}
                <script src="/assets/global/countdown.js" date="2022-1-1 08:00"></script>
                <script src="/assets/global/disabler.js"></script>
                <script src="/assets/global/cursor.js"></script>
            </Helmet>
            {/* <Covidmodal /> */}
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            <div className="cursor"></div>

            <div id="notification-bar">
                <div className="notification-container">
                    <p>Artavika</p>
                    <a className="btn-action" href="/order" target="_blank" rel="noreferrer"><span className="lnr lnr-cart">&nbsp;Order</span></a>
                </div>
            </div>
            <section id="home">
                <div className="overlay"></div><div className="gradient-overlay"></div>
                <div className="home-content-table">
                    <div className="home-content-tablecell">
                        <div className="row">
                            <div className="col-twelve">
                                <div className="home-bottom-text">
                                    <h3 animation-delay="200" animation-type="fadeInUp">The Wedding of</h3>
                                    <h1 animation-delay="500" animation-type="fadeInDown">Bandung & Roro</h1>
                                    <ul className="home-section-links">
                                        <li animation-delay="800" animation-type="zoomIn" className="kepada">{guestName}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#about" className="scroll-icon smoothscroll">
                    <span className="icon-DownArrow"></span>
                </a>
            </section>
            <section id="about">
                <div className="about-profile-bg"></div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>
                <div className="about-wrap">
                    <div className="about-profile">
                        <div className="intro">
                            <h3 animation-delay="200" animation-type="fadeInDown">Bandung</h3>
                            <h1 animation-delay="400" animation-type="fadeInLeft">Bandung Bondowoso</h1>
                            <div className="profile-pic-polaroid">
                                <LazyLoadImage animation-delay="300" animation-type="fadeInUp" src="/assets/global/img/groom.jpg" alt="Groom" />
                                <a animation-delay="300" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-instagram"></i></a> &nbsp;
                                <a animation-delay="500" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-twitter"></i></a> &nbsp;
                                <a animation-delay="700" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp"><i className="fa fa-whatsapp"></i></a>
                                <p animation-delay="200" animation-type="fadeInUp"><br />Putra pertama dari<br /><strong>Prabu Damar Moyo & Ny. Damar Moyo</strong> <br /><em>Kerajaan Pengging</em></p>
                            </div>
                        </div>
                    </div>
                    <div className="about-services">
                        <div className="intro">
                            <h3 animation-delay="200" animation-type="fadeInDown">Roro</h3>
                            <h1 animation-delay="400" animation-type="fadeInRight">Roro Jonggrang</h1>
                            <div className="profile-pic-polaroid">
                                <LazyLoadImage animation-delay="600" animation-type="fadeInUp" src="/assets/global/img/bride.jpg" alt="Bride" />
                                <a animation-delay="300" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-instagram"></i></a> &nbsp;
                                <a animation-delay="500" animation-type="zoomIn" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" title="Instagram"><i className="fa fa-twitter"></i></a> &nbsp;
                                <a animation-delay="700" animation-type="zoomIn" href="/ask" target="_blank" rel="noreferrer" title="Whatsapp"><i className="fa fa-whatsapp"></i></a>
                                <p animation-delay="200" animation-type="fadeInUp"><br />Putri kedua dari<br /><strong>Prabu Boko & Ny. Boko</strong> <br /><em>Kerajaan Boko</em></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="skills">
                <div className="overlay"></div>
                <div className="gradient-overlay"></div>
                <div className="row wide">
                    <div className="col-skills">
                        <p animation-delay="200" animation-type="fadeInDown"><em>Maha Suci Tuhan yang telah menciptakan pasangan-pasangan semuanya, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka maupun dari apa yang tidak mereka ketahui. ~ QS Yasin: 36</em></p>
                        <div className="section-intro">
                            <br />&nbsp;
                            <LazyLoadImage animation-delay="200" animation-type="zoomIn" className="center-thing" src="/assets/template/artavika/images/grass.png" alt="Separator" />
                            <br />&nbsp;
                            <h1 animation-delay="200" animation-type="fadeInUp">Turut Mengundang</h1>
                            <p animation-delay="200" animation-type="fadeInUp">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan putra & putri kami yang akan diselenggarakan pada:</p>
                            <h3 animation-delay="200" animation-type="fadeInDown">Akad Nikah</h3>
                            <p animation-delay="200" animation-type="fadeInUp">Sabtu, 1 Januari 2022<br />
                                08:30 - 09:00 WIB<br />
                                <a className="map-url" href="https://goo.gl/maps/LmbRtiKDGHrfP3RLA" target="_blank" rel="noreferrer"><span className="lnr lnr-map-marker"></span> Gd. Pertemuan Candi Prambanan, Yogyakarta</a></p>
                            &nbsp;
                            <h3 animation-delay="200" animation-type="fadeInDown">Resepsi</h3>
                            <p animation-delay="200" animation-type="fadeInUp">Sabtu, 1 Januari 2022<br />
                                10:00 - 12:00 WIB<br />
                                <a className="map-url" href="https://goo.gl/maps/LmbRtiKDGHrfP3RLA" target="_blank" rel="noreferrer"><span className="lnr lnr-map-marker"></span> Gd. Pertemuan Candi Prambanan, Yogyakarta</a></p>
                        </div>
                        &nbsp;
                        <a animation-delay="200" animation-type="fadeInUp" className="google-calendar" href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Pernikahan+Bandung+Bondowoso+%26+Roro+Jonggrang&dates=20220101T080000/20220101T120000&details=Detail+acara+bisa+dilihat+di+https://vanilatte.com/&location=Prambanan+Temple,+Jl.+Raya+Solo+-+Yogyakarta+No.16,+Kranggan,+Bokoharjo,+Kec.+Prambanan,+Kabupaten+Sleman,+Daerah+Istimewa+Yogyakarta+55571,+Indonesia&pli=1&sf=true" target="_blank" rel="noreferrer"><i className="fa fa-calendar-o"></i> &nbsp; Simpan di Google Calendar</a>
                    </div>
                </div>
            </section>
            <section id="contact">
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Peta Lokasi</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead">Gunakan peta di bawah ini untuk mencari rute ke lokasi acara kami</p>
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.355933734574!2d110.48927335094578!3d-7.752020594385398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5ae3dbd859d1%3A0x19e7a03b25955a2d!2sPrambanan%20Temple!5e0!3m2!1sen!2sid!4v1615047731484!5m2!1sen!2sid" width="100%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <center><a animation-delay="200" animation-type="fadeInUp" className="google-calendar" href="https://goo.gl/maps/2KLipF7YvmBmwwba6" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah</a></center>
                    </div>
                </div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>

            </section>
            <section id="credentials">
                {/* <LazyLoadImage animation-delay="200" animation-type="zoomIn" className="center-thing divider-cred" src="/assets/template/artavika/images/divider.png" alt="Separator" /> */}
                <br />&nbsp;
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3 animation-delay="200" animation-type="fadeInUp" className="">The Love Story of</h3>
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Bandung & Roro</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead">You come to love not by finding the perfect person, but by seeing an imperfect person perfectly.</p>
                    </div>
                </div>
                <div className="row wide credentials-content">
                    <div className="with-bottom-sep">
                        <iframe animation-delay="200" animation-type="fadeInUp" className="video-embed" src="https://www.youtube-nocookie.com/embed/MgLG1_fZ2KQ?controls=0" frameBorder="0" title="Pre Wedding" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <br />&nbsp;
                        <br />&nbsp;
                    </div>
                    <div className="col-six tab-full left">
                        <div className="timeline-wrap">
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-user"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">Juli 2015</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp">Pertemuan Pertama</h3>
                                </div>
                                <div animation-delay="200" animation-type="fadeInUp" className="timeline-content">
                                    <p>Sejak pertama kali aku memandangnya di kejauhan, ada sesuatu yang mengatakan padaku bahwa dia tercipta untukku.</p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-home"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">September 2015</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Dia Malu, Tapi Mau</h3>
                                </div>
                                <div className="timeline-desc">
                                    <p animation-delay="200" animation-type="fadeInUp" >Malam minggu itu, aku bertanya apakah dia bersedia menemaniku ke acara ulang tahun sahabatku. Saat itu hanya aku saja yang tidak punya pasangan.</p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-diamond"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">November 2015</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Awal Perjalanan</h3>
                                </div>
                                <div className="timeline-content">
                                    <p animation-delay="200" animation-type="fadeInUp" >Masih kuingat dengan jelas merah pipinya ketika kutanya apakah dia mau jadi kekasihku.</p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-film-play"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">Januari 2016</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Film Bioskop Pertama</h3>
                                </div>
                                <div className="timeline-content">
                                    <p animation-delay="200" animation-type="fadeInUp" >Aku tidak ingat bagaimana jalan cerita film yang kutonton waktu itu, karena pandanganku selalu tertuju padanya.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-six tab-full right">
                        {/* <h2 className="">Education</h2> */}
                        <div className="timeline-wrap">
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-moon"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">Februari 2018</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Aku Menyiapkan Sesuatu</h3>
                                </div>
                                <div className="timeline-content">
                                    <p animation-delay="200" animation-type="fadeInUp" >Sudah memikirkannya matang-matang, aku berharap semoga dia adalah tambatan terakhir bagiku.</p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-bubble"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">Maret 2018</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Dia Katakan, "Ya!"</h3>
                                </div>
                                <div className="timeline-content">
                                    <p animation-delay="200" animation-type="fadeInUp" >Ketika aku bertanya, <em>"maukah kamu menikah denganku?"</em> tidak perlu waktu lebih dari sedetik baginya untuk menjawab, <em>"Ya!"</em></p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-users"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">Desember 2019</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Aku Melamarnya!</h3>
                                </div>
                                <div className="timeline-desc">
                                    <p animation-delay="200" animation-type="fadeInUp" >Kudatangi orang tuanya dan kukatakan dengan lantang bahwa aku akan menjaganya sepanjang sisa hidupku.</p>
                                </div>
                            </div>
                            <div className="timeline-block ">
                                <div animation-delay="200" animation-type="zoomIn" className="timeline-ico">
                                    <span className="lnr lnr-heart"></span>
                                </div>
                                <div className="timeline-header">
                                    <p animation-delay="200" animation-type="fadeInUp" className="timeframe">1 Januari 2022</p>
                                    <h3 animation-delay="200" animation-type="fadeInUp" >Hari Bahagia</h3>
                                </div>
                                <div className="timeline-content">
                                    <p animation-delay="200" animation-type="fadeInUp" >Kami sepakat untuk memilih hari ini sebagai hari bahagia kami berdua.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />&nbsp;
                <br />&nbsp;
                <br />&nbsp;
                {/* <LazyLoadImage animation-delay="200" animation-type="zoomIn" className="center-thing divider-cred" src="/assets/template/artavika/images/divider.png" alt="Separator" /> */}
            </section>

            <section id="contact">
                <div className="overlay"></div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h1 animation-delay="200" animation-type="fadeInDown">Menuju Hari Bahagia</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead ">Whenever I look at you, I see the rest of my life in front of my eyes.</p>
                        <ul className="countdown text-center text-muted">
                            <li animation-delay="200" animation-type="zoomIn" ><span id="days"></span>hari</li>
                            <li animation-delay="400" animation-type="zoomIn" ><span id="hours"></span>jam</li>
                            <li animation-delay="600" animation-type="zoomIn" ><span id="minutes"></span>menit</li>
                            <li animation-delay="800" animation-type="zoomIn" ><span id="seconds"></span>detik</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="portfolio">
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3 animation-delay="200" animation-type="fadeInDown" >Gallery of</h3>
                        <h1 animation-delay="200" animation-type="fadeInUp" >Bandung & Roro</h1>
                        <p animation-delay="200" animation-type="fadeInUp" className="lead ">I hope you know that every time I tell you to get home safe, stay warm, have a good day, or sleep well what I am really saying is I love you. I love you so damn much that it is starting to steal other words’ meanings.</p>
                    </div>
                </div>
                <div className="row portfolio-content">
                    <div className="col-twelve">
                        <div className="bricks-wrapper">
                            <SRLWrapper>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-1.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-3.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-4.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-5.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="200" animation-type="zoomIn" src="/assets/global/img/gallery-pic-6.jpg" alt="Gallery" />
                                </div>
                                <div className="brick folio-item gallery-pic">
                                    <img animation-delay="400" animation-type="zoomIn" src="/assets/global/img/gallery-pic-8.jpg" alt="Gallery" />
                                </div>
                            </SRLWrapper>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div className="overlay"></div>
                <ul className=" corner-ul">
                    <li id="top-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                    <li id="top-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-right" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>

                    </li>
                    <li id="bottom-left" className="parent-wrapper corner-li">
                        <div className="corner-flex">
                            <LazyLoadImage src="/assets/template/artavika/images/corner.png" alt="corner" className="bg-image js-lazy" />
                        </div>
                    </li>
                </ul>
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <LazyLoadImage animation-delay="200" animation-type="fadeInUp" src="/assets/template/artavika/images/grass.png" alt="Divider" /><br />&nbsp;
                        <p animation-delay="200" animation-type="fadeInDown" className="lead ">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.<br /><br />Hormat kami,</p>
                        <h1 animation-delay="200" animation-type="fadeInUp" className="">Bandung & Roro</h1><br />&nbsp;
                        <LazyLoadImage animation-delay="200" animation-type="fadeInDown" src="/assets/template/artavika/images/grass.png" alt="Divider" />
                    </div>
                </div>
            </section>
            <section id="portfolio">

                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3 animation-delay="200" animation-type="fadeInDown" >Leave us your wishes</h3>
                        <h1 animation-delay="200" animation-type="fadeInUp" >RSVP & Guestbook</h1>
                        {
                            (gbSent) ?
                                <>
                                    <br />
                                    <p className="lead ">Terima kasih telah berkenan untuk mengisi buku tamu kami 😊 <br />Jika sempat, kami akan membalas melalui nomor telp/WA yang sudah dimasukkan.</p>
                                </>
                                :
                                <>

                                    <form onSubmit={((e) => gbSubmitHandler(e))}>
                                        <fieldset>
                                            <div >
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbName(e.target.value))} className="gb-input" id="nameInput" name="nameInput" type="text" placeholder="Nama" required autoComplete="off" />
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <input animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbPhone(e.target.value))} className="gb-input" id="phoneInput" name="phoneInput" type="text" placeholder="Telp/WA" autoComplete="off" />
                                                </div>
                                            </div>
                                            <div>
                                                <div >
                                                    <select animation-delay="300" animation-type="fadeInUp" onChange={((e) => setGbAtt(e.target.value))} className="gb-input" required>
                                                        <option value="" disabled selected>Apakah bisa hadir?</option>
                                                        <option value="yes">Ya, hadir</option>
                                                        <option value="no">Maaf, tidak bisa hadir</option>
                                                        <option value="maybe">Belum tahu</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div >
                                                {
                                                    (gbAtt === "yes") ?
                                                        <>
                                                            <select onChange={((e) => setGbPerson(e.target.value))} className="gb-input">
                                                                <option value="1" selected>1 orang</option>
                                                                <option value="2-4">2-4 orang</option>
                                                                <option value=">5">{">"} 5 orang</option>
                                                            </select>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div >
                                                <div >
                                                    <textarea animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} onChange={((e) => setGbMsg(e.target.value))} className="gb-input" id="msgInput" name="msgInput" placeholder="Pesan kepada mempelai" required autoComplete="off"></textarea>
                                                </div>
                                            </div>

                                            <div>
                                                <div >
                                                    <button animation-delay="300" animation-type="fadeInUp" disabled={gbDisable} id="singlebutton" name="singlebutton" type="submit">Kirim</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </>
                        }
                        <div>
                            <br />&nbsp;
                            <button
                                animation-delay="300" animation-type="fadeInUp"
                                onClick={((e) => {
                                    e.preventDefault();
                                    setGbWaiting(true);
                                    setShowGbMsg(!showGbMsg);
                                    if (!showGbMsg) requestGuestBook();
                                })} >{(showGbMsg) ? "Tutup" : "Lihat"} Semua Pesan</button>
                        </div>
                        {
                            (showGbMsg) ?
                                <>
                                    {
                                        (gbWaiting) ?
                                            <>
                                                <br />&nbsp;
                                                <p>Mohon tunggu...</p>
                                            </>
                                            :
                                            <div className="gb-wrap">
                                                {messageList}
                                            </div>
                                    }
                                </>
                                :
                                <>
                                </>
                        }
                    </div>
                </div>
            </section>

            <footer>
                <div className="row">
                    <div className="col-twelve tab-full">
                        <div className="copyright">
                            <span>Artavika &middot; Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Premium Wedding Invitation">Vanilatte.com</a></span>
                            <br />&nbsp;
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Artavika;
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import Rellax from "rellax";

import Audio from '../../components/audio';
import Covidmodal from '../../components/covidModal';
import { SRLWrapper } from 'simple-react-lightbox';

let guestName = "";
const gbUrl = "https://sheet.best/api/sheets/5543f590-d7f9-4a07-a333-270cb822fd66";
const gbKey = "1LqANZtc-XzaPUkmaNwPMW27VBlo3Cq0UxXbjnBog4VI";

let sesi = "11:00 - 12:00";

function WidiFajar() {
    const [gbName, setGbName] = useState("");
    const [gbPhone, setGbPhone] = useState("-");
    const [gbAtt, setGbAtt] = useState("");
    const [gbPerson, setGbPerson] = useState("0")
    const [gbMsg, setGbMsg] = useState("");
    const [gbDisable, setGbDisable] = useState(false);
    const [gbSent, setGbSent] = useState(false);
    const [showGbMsg, setShowGbMsg] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);
    const [gbWaiting, setGbWaiting] = useState(false);

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sesiParam = params.get('q');
        if (sesiParam != null) sesi = "12:00 - 13:00"
    })

    useEffect(() => {
        new Rellax(".rellax", {})
    })
    const requestGuestBook = (() => {
        Tabletop.init({
            key: gbKey,
            callback: googleData => {
                let data = googleData;
                if ("Guestbook" in data) {
                    data = data["Guestbook"].elements;
                    data.reverse();
                    setGbMsgList(data);
                    setGbWaiting(false);
                }
            },
            simpleSheet: false
        })
    })

    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let kepada = params.get('kepada');
        if (kepada != null) guestName = "Kepada Yth. \n" + kepada;
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            <div className="comment-item">
                <p className="comment-name">{data.name} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-clock-o"></i> &nbsp; {moment(data.date).fromNow()}</span></p>
                <p className="comment-message">{data.message}</p>
            </div>
        </div>
    })


    const gbSubmitHandler = ((e) => {
        e.preventDefault();
        setGbDisable(true);
        const payload = {
            date: new Date(),
            name: gbName,
            phone: gbPhone,
            attendance: gbAtt,
            person: gbPerson,
            message: gbMsg
        }

        axios.post(gbUrl, payload)
            .then(response => {
                setGbDisable(false);
                setGbSent(true);
            })
    })

    return (
        <>
            <Helmet>
                <title>The Wedding of Widi & Fajar</title>
                <meta name="description" content="We invite you to celebrate our wedding!" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <link href="/assets/global/header-cta.css" rel="stylesheet" />
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link href="/assets/clients/widifajar/css/main.css" rel="stylesheet" />
                <script src="/assets/global/countdown.js" date="2021-8-1 09:00"></script>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Great+Vibes&amp;display=swap" />
                <link href="https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap" rel="stylesheet" />
                {/* <link href="/assets/clients/widifajar/css/font-awesome/css/all.min.css" rel="stylesheet" /> */}
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link href="/assets/clients/widifajar/css/bootstrap.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <script src="/assets/global/cursor.js"></script>
                <link href="/assets/global/cursor.css" rel="stylesheet" />

                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />

                <script src="https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js"></script>
            </Helmet>
            <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" />
            <Covidmodal />
            <div className="cursor"></div>
            <main className="page-content">
                <section className="ww-home-page" id="home">
                    <div className="ww-wedding-announcement d-flex align-items-center justify-content-start">
                        <div className="container ww-announcement-container">
                            <p animation-delay="200" animation-type="fadeInUp" className="wedding-of">The Wedding Of</p>
                            <p animation-delay="400" animation-type="fadeInUp" className="ww-couple-name">Widi & Fajar</p>
                            <p animation-delay="600" animation-type="fadeInUp" className="kepada">{guestName}</p><br /><br />
                            {/* <p><strong>Sabtu, 1 Januari 2022</strong></p> */}
                        </div>
                        <div className="custom-shape-divider-bottom" style={{ "transform": "scaleY(-1) scaleX(-1)" }}>
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                            </svg>
                        </div>
                    </div>
                </section>
                <section className="ww-section bg-dark" id="couple">
                    <div className="container first-container">
                        <p className="text-center"><img animation-delay="800" animation-type="fadeInDown" src="assets/clients/widifajar/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="300" animation-type="zoomIn" >Kami yang Berbahagia</span>
                        </h2>
                        <p className="text-center"><img animation-delay="400" animation-type="fadeInUp" src="assets/clients/widifajar/images/divider.png" className="divider" alt="Separator" /></p>
                        <br />&nbsp;
                        <div className="row text-center">
                            <div className="col-md-6">
                                <div className="mt-3"><img animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="assets/clients/widifajar/images/bride.jpg" alt="Bride" />

                                    <br />&nbsp;
                                        <h3 animation-delay="600" animation-type="fadeInUp" className="h2 ww-title">Widiatmi Ningsih, S.T.</h3>
                                    <p animation-delay="1200" animation-type="zoomIn" className="pt-3 text-center text-muted">Putri pertama dari<br /><em>Bapak (Purn) Satijo & Ibu Suniryani</em><br />Senden 1 Rt.02/Rw.01, Selomartani, Kalasan, Sleman, Yogyakarta</p>
                                    <br />&nbsp;
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mt-3"><img animation-delay="400" animation-type="zoomIn" className="img-fluid wedding-person" src="assets/clients/widifajar/images/groom.jpg" alt="Groom" />
                                    <br />&nbsp;
                                        <h3 animation-delay="400" animation-type="fadeInUp" className="h2 ww-title">Fajar Wahyu Styawan, S.Pd.</h3>
                                    <p animation-delay="800" animation-type="zoomIn" className="pt-3 text-center text-muted">Putra pertama dari<br /><em>Bapak Jumeno & Ibu Ngatinah</em><br />Candisari Rt.01/Rw.01, Hargosari, Tanjungsari, Gunungkidul, Yogyakarta</p>
                                </div>
                            </div>
                        </div>
                            &nbsp;
                            <p className="text-center"><img animation-delay="1200" animation-type="zoomIn" src="assets/clients/widifajar/images/grass.png" className="" alt="Separator" /></p>
                        <div className="width-limiter">
                            <p animation-delay="300" animation-type="rotateInUpRight" className="pt-5 text-center text-muted prayer">Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah</p>
                            <p animation-delay="600" animation-type="rotateInUpLeft" className="ww-title text-center">QS Adz-Dzariyat:49</p>
                        </div>
                    </div>
                </section>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder-right.png" />
                </div>
                <div class="svg-divider-triangle-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="events">
                    <div className="container ww-wedding-event">
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <h2 className="h1 text-center pb-3 ww-title" >
                            <span animation-delay="100" animation-type="zoomIn" >Turut Mengundang</span>
                        </h2>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h2 text-center ww-sub-title">Akad:</p><br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h4 text-center ww-sub-title">Minggu</p>
                        <p animation-delay="300" animation-type="fadeInDown" className="h3 text-center ww-sub-title">1 / Agustus / 2021</p>
                        <p animation-delay="300" animation-type="zoomIn" className="text-center"><img src="assets/clients/widifajar/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="zoomIn" className="h3 text-center ww-sub-title">09:00 - 10:00</p>
                        <p animation-delay="300" animation-type="fadeInUp" className="text-center"><img src="assets/clients/widifajar/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="fadeInUp" className="h4 text-center ww-sub-title">Senden 1 Rt.02/Rw.01, Selomartani, Kalasan, Sleman, Yogyakarta</p>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h2 text-center ww-sub-title">Tasyakuran Pernikahan:</p><br />
                        <p animation-delay="300" animation-type="fadeInDown" className="h4 text-center ww-sub-title">Minggu</p>
                        <p animation-delay="300" animation-type="fadeInDown" className="h3 text-center ww-sub-title">1 / Agustus / 2021</p>
                        <p animation-delay="300" animation-type="zoomIn" className="text-center"><img src="assets/clients/widifajar/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="zoomIn" className="h3 text-center ww-sub-title">11:00 - 13:00</p>
                        <p animation-delay="300" animation-type="fadeInUp" className="text-center"><img src="assets/clients/widifajar/images/grass.png" className="event-separator" alt="Separator" /></p>
                        <p animation-delay="300" animation-type="fadeInUp" className="h4 text-center ww-sub-title">Senden 1 Rt.02/Rw.01, Selomartani, Kalasan, Sleman, Yogyakarta</p>
                        <br />
                        <br />&nbsp;
                        <hr />
                        <small animation-delay="300" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Tanpa mengurangi rasa hormat sedikit pun, acara resepsi pernikahan akan dilakukan dengan tetap mengikuti protokol kesehatan sesuai anjuran pemerintah.</small>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-dark" id="people">
                    <div className="container text-center">
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/widifajar/images/divider-flip.png" className="divider" alt="Separator" /></p>
                        <h2 animation-delay="400" animation-type="zoomIn" className="h1 text-center pb-3 ww-title" >Lokasi Acara</h2>
                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami.</p>
                        <br />
                        <iframe animation-delay="400" animation-type="zoomIn" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.580795961487!2d110.4767389147778!3d-7.72804399442969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zN8KwNDMnNDEuMCJTIDExMMKwMjgnNDQuMSJF!5e0!3m2!1sen!2sid!4v1626660787746!5m2!1sen!2sid" width="90%" height="600" style={{ "border": "0;" }} allowfullscreen="" loading="lazy" title="Wedding Location"></iframe>
                        <br />
                        <br />
                        <a animation-delay="200" animation-type="zoomInUp" className="gb-btn" href="https://goo.gl/maps/qEJamEctTBhbiCmS7" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Petunjuk Arah</a>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleX(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <section className="ww-section bg-light" id="people">
                    <div className="container text-center">

                        <p animation-delay="400" animation-type="fadeInDown" className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="zoomIn" className="h1 ww-title text-center">Menuju Hari Bahagia</p>
                        <p animation-delay="400" animation-type="fadeInUp" className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider" alt="Separator" /></p>
                        <p animation-delay="400" animation-type="fadeInUp" className="pt-4 text-center text-muted width-limiter">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                        <ul className="countdown text-muted">
                            <li animation-delay="200" animation-type="zoomIn" ><span id="days"></span>hari</li>
                            <li animation-delay="400" animation-type="zoomIn" ><span id="hours"></span>jam</li>
                            <li animation-delay="600" animation-type="zoomIn" ><span id="minutes"></span>menit</li>
                            <li animation-delay="800" animation-type="zoomIn" ><span id="seconds"></span>detik</li>
                        </ul>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom" style={{ "transform": "scaleY(-1)" }}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder-right.png" />
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder-right.png" />
                </div>
                <div className="sidewinder-wrapper-left">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder.png" />
                </div>
                <div className="sidewinder-wrapper-right">
                    <img className="rellax sidewinder" data-rellax-speed="7" alt="sider" src="assets/clients/widifajar/images/sidewinder-right.png" />
                </div>
                <section className="ww-section bg-dark" id="gallery">
                    <div className="ww-photo-gallery">
                        <div className="gb-container">
                            <p className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider flip-divider" alt="Separator" /></p>
                            <div className="h1 text-center pb-3 ww-title" >Our Love Story</div>
                            <p className="text-center"><img src="assets/clients/widifajar/images/divider.png" className="divider" alt="Separator" /></p>
                            <br />
                            <br />
                            <p className="text-center">Awal dari momen baik dan pertemuan yang singkat seperti kedua insan dipertemukan tulang rusuknya yang terpisahkan secara takdir, sebagai jodoh pelengkap untuk memulai kehidupan baru oleh Sang Kholiq.
                            <br />
                            <br />
                            Pertemuan persetujuan keluarga pada waktu dan hari baik mengikatkan dan menguatkan kami sebagai pasangan sejoli yang berbahagia.
                            <br />
                            <br />
                            Atas ijin Allah SWT dan takdir yang mempertemukan kami sebagai insan-Nya, kami mengikat janji suci untuk pelengkap dan beribadah kepada-Nya memulai kehidupan yang baru. InsyaAllah tepat pada Tanggal 1 Agustus 2021 kami memutuskan untuk hidup bersama dengan ikatan cinta yang tulus, murni, dan indah.
                            <br />
                            <br />
                            Dalam tautan rasa yang penuh bahagia kami berharap doa dan restunya "Semoga hari yang baik ini dapat menjadikan pengesahan yang suci baik dari Agama maupun Negara" (Aamiin).</p>
                        </div>
                    </div>
                </section>
                <div class="svg-divider-triangle-bottom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="shape-fill"></path>
                    </svg>
                </div>
                <footer className="ww-footer bg-light">
                    <div className="container text-center py-4">
                        <p className="my-0">Premium Wedding Invitation by <a href="https://vanilatte.com/" alt="Vanilatte Wedding e-Invitation Service">Vanilatte.com</a></p>
                            &nbsp;
                        </div>
                </footer>
            </main>

        </>
    )
}

export default WidiFajar;

import { useEffect, useState } from "react";
import Fade from '@material-ui/core/Fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Helmet } from "react-helmet";
import Covidmodal from "../../components/covidModal";


const prayer = [
    "Mahasuci (Allah) yang telah menciptakan semuanya berpasang-pasangan, baik dari apa yang ditumbuhkan oleh bumi dan dari diri mereka sendiri, maupun dari apa yang tidak mereka ketahui. ― QS Yasin: 36",
    "Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah? ― QS An-Nahl 72",
    "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. ― QS Ar-Rum:21",
    "Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah ― QS Adz-Dzariyat:49",
    "Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya), Maha Mengetahui. ― QS An-Nur: 32",
    "Kamu lihat, bahwa iman bekerjasama dengan perbuatan-perbuatan dan oleh perbuatan-perbuatan itu iman menjadi sempurna. ― Yakobus 2:2",
    "Demikian mereka bukan lagi dua melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Matius 19:6",
    "Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan. ― Kolese 3:14",
    "Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia. ― Markus 10:9",
    "Sehati sepikirlah kamu, dan hiduplah dalam damai sejahtera; maka Allah, sumber kasih dan damai sejahtera akan menyertai kamu! ― 2 Korintus 13:11",
    "The water shines only by the sun. And it is you who are my sun. ― Charles de Leusse",
    "I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow. ― Leo Christopher",
    "'I love you' begins by I, but it ends up by you. ― Charles de Leusse",
    "The best thing to hold onto in life is each other. ― Audrey Hepburn",
    "I will love you until the stars go out, and the tides no longer turn."
];

function Gayatri(props) {

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="/assets/global/wedding/gayatri.css" />
                <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                <script src="/assets/global/wedding/countdown.js" date={props.weddingData.akad_tanggal + "__" + props.weddingData.akad_waktu_mulai}></script>
                <link rel="stylesheet" href="/assets/global/animation.css" />
                <script src="/assets/global/animation.js"></script>
                <link rel="stylesheet" href="/assets/template/artavika/css/vendor.css" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link href="https://fonts.googleapis.com/css?family=Caveat:400,400italic%7CPlayfair+Display:400,400italic%7CInter:300,300italic" rel="stylesheet" type="text/css" />
            </Helmet>
            {/* <Covidmodal /> */}
            <Fade
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1000 } : {})}
            >
                <div id="wrapper">
                    <div id="main">
                        <div className="inner">
                            <div id="container01" className="style1 container default">
                                <div className="wrapper">
                                    <div className="inner">
                                        <p className="meta-header">The Wedding Of</p>
                                        <h1 id="text01" className="style1">{props.weddingData.nama_panggilan}</h1>
                                    </div>
                                </div>
                            </div>
                            <p id="text02" className="meta-header"><span>We invite you to share with us a celebration of love</span><br /> <span>···</span></p>
                            <hr id="divider02" className="style1" />
                            <div id="container03" className="style2 container default">
                                <div className="wrapper">
                                    <div className="inner">
                                        <h2 id="text05" className="style3">{props.weddingData.mempelai_wanita_nama}</h2>
                                        <p id="text15" className="child-of">putri {(props.weddingData.mempelai_wanita_anak_ke).toLowerCase()} dari</p>
                                        <p id="text16" className="style4"><span>{props.weddingData.mempelai_wanita_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_wanita_ibu}</span></p>
                                        <p id="text15" className="child-of">{props.weddingData.mempelai_wanita_alamat}</p>
                                        <h2 id="text06" className="style3">&amp;</h2>
                                        <h2 id="text14" className="style3">{props.weddingData.mempelai_pria_nama}</h2>
                                        <p id="text12" className="child-of">putra {(props.weddingData.mempelai_pria_anak_ke).toLowerCase()} dari</p>
                                        <p id="text13" className="style4"><span>{props.weddingData.mempelai_pria_ayah} &amp;</span><br /> <span>{props.weddingData.mempelai_pria_ibu}</span></p>
                                        <p id="text15" className="child-of">{props.weddingData.mempelai_pria_alamat}</p>
                                        <hr id="divider07" className="style1" />
                                        {
                                            (props.weddingData.doa !== "x") ?
                                                <>
                                                    <p id="text07" className="style2"><span>{prayer[props.weddingData.doa]} </span></p>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr id="divider05" className="style2" />
                            <hr id="divider01" className="style1" />
                            <div id="container02" className="style2 container default">
                                <div className="wrapper">
                                    <div className="inner">
                                        <h2 id="text18" className="style3">Turut Mengundang</h2>
                                        <p id="text19" className="style4">Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                        <br />
                                        <p id="text20" className="style5">{props.weddingData.jenis_acara}:</p>
                                        <p id="text21" className="style4"><span>{((props.weddingData.akad_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.akad_waktu_mulai} - {props.weddingData.akad_waktu_selesai}</span><br /> <span>{props.weddingData.akad_lokasi}</span></p>
                                        {
                                            ((props.weddingData.akad_gmaps).includes("https://") || (props.weddingData.akad_gmaps).includes("http://")) ?
                                                <div className="center-button-dark">
                                                    <a href={props.weddingData.akad_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                </div>
                                                : null
                                        }
                                        <div id="image01" className="image"><LazyLoadImage style={{ "maxWidth": "70px" }} className="grass" src="assets/global/wedding/grass_dark.png" alt="" /></div>
                                        <p id="text03" className="style5">{props.weddingData.jenis_acara_resepsi}:</p>
                                        <p id="text09" className="style4"><span>{((props.weddingData.resepsi_tanggal).split("-")).reverse().join(" / ")}</span><br /> <span>{props.weddingData.resepsi_waktu_mulai} - {props.weddingData.resepsi_waktu_selesai}</span><br /> <span>{props.weddingData.resepsi_lokasi}</span></p>
                                        {
                                            ((props.weddingData.resepsi_gmaps).includes("https://") || (props.weddingData.resepsi_gmaps).includes("http://")) ?
                                                <div className="center-button-dark">
                                                    <a href={props.weddingData.resepsi_gmaps} target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i> &nbsp; Cek Lokasi</a>
                                                </div>
                                                : null
                                        }
                                        <hr id="divider08" className="style1" />
                                        <p id="text17" className="style2">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    </div>
                                </div>
                            </div>
                            <hr id="divider06" className="style2" />
                            <hr id="divider03" className="style1" />
                            <div id="container04" className="style2 container default">
                                <div className="wrapper">
                                    <div className="inner">
                                        <h2 id="text08" className="style3">Menuju Hari Bahagia</h2>
                                        <div id="timer01" className="timer">
                                            <ul />
                                        </div>
                                        <p id="text04" className="style4">Merupakan suatu kehormatan dan kebahagiaan bagi kami jika Bapak/Ibu/Saudara/i berkenan untuk hadir pada acara ini.</p>
                                    </div>
                                </div>
                            </div>
                            <hr id="divider04" className="style1" />
                            <div id="container05" className="style4 container default">
                                <div className="wrapper">
                                    <div className="inner">
                                        <p id="text10" className="meta-header">Buat Undangan Nikah Hanya 1 Menit di <a href="/lembayung" target="_blank" rel="noreferrer">Vanilatte.com</a><br /><br /><br />
                                            <a href="https://vanilatte.com/" target="_blank" rel="noreferrer"><i className="fa fa-globe"></i></a> &nbsp;
                                            <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a> &nbsp;
                                            <a href="/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    )
}

export default Gayatri
import { useState, useEffect } from "react";
import axios from "axios";
import Tabletop from 'tabletop';
import moment from 'moment';
import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";
import Navbar from "./navbar";
import Footer from "./footer";
import { RWebShare } from "react-web-share";

const formUrl = "https://sheet.best/api/sheets/3907784a-2e90-46d7-b07e-1acc1e4d7436";
const testimonialKey = "1xIAGNP2n1GmsNK2Vi3ahnQQcPAQ5OHHfceMU7_MGktY";

let payload = {
    "tanggal_pembuatan": new Date(),
    "nama": "",
    "alamat_undangan": "",
    "ditampilkan": "0",
    "tanggal_menikah": moment().add(1, 'days').format("yyyy-MM-DD"),
    "testimonial": "",
    "feedback": "",
    "rate": ""
}

function Testimonial(props) {
    const [testimoniFormFilled, setTestimoniFormFilled] = useState(false);
    const [testimoniFormIsSending, setTestimoniFormIsSending] = useState(false);
    const [showCompleteForm, setShowCompleteForm] = useState(false);
    const [gbMsgList, setGbMsgList] = useState([]);

    const requestGuestBook = (() => {
        // Old method
        // Tabletop.init({
        //     key: testimonialKey,
        //     callback: googleData => {
        //         googleData.reverse();
        //         setGbMsgList(googleData);
        //         console.log(googleData);
        //     },
        //     simpleSheet: true
        // })

        fetch(`https://docs.google.com/spreadsheets/d/${testimonialKey}/gviz/tq?tqx=out:json`)
            .then(res => res.text())
            .then(text => {
                const json = JSON.parse(text.substr(47).slice(0, -2))
                console.log(json);
                // setGbMsgList(json.table);
            })
    })

    const messageList = gbMsgList.map((data, id) => {
        return <div key={id}>
            {
                (data.ditampilkan === "1") ?
                    <div className="comment-item">
                        <p className="comment-name">{((data.alamat_undangan).includes("http://") || ((data.alamat_undangan).includes("https://"))) ? <a href={data.alamat_undangan} target="_blank" rel="noreferrer">{data.nama}</a> : data.nama} <span className="comment-time"> &nbsp; — &nbsp; <i className="fa fa-heart"></i> &nbsp; Menikah pada {moment(data.tanggal_menikah).format("dddd, Do MMMM YYYY")}</span></p>
                        <p className="comment-message">{data.testimonial}</p>
                    </div>
                    : null
            }
        </div>
    })

    useEffect(() => {
        requestGuestBook();
    })

    useEffect(() => {
        if (props.weddingData === undefined) {

            // if (Object.keys(props.weddingData).length === 0)
            setShowCompleteForm(true)
        }
    })

    const testimoniSubmitHandler = ((e) => {

        if (!showCompleteForm) {
            testimoniPayloadHandler("nama", props.weddingData.nama_panggilan);
            testimoniPayloadHandler("alamat_undangan", "https://album.love/wedding/" + props.weddingData.kode_unik);
            testimoniPayloadHandler("tanggal_menikah", props.weddingData.akad_tanggal);
        }

        setTestimoniFormIsSending(true)
        e.preventDefault();
        axios.post(formUrl, payload)
            .then(response => {
                setTestimoniFormIsSending(false)
                setTestimoniFormFilled(!testimoniFormFilled);
            })
    })

    const testimoniPayloadHandler = ((name, value) => {
        payload[name] = value;
    })

    const copyCode = (() => {
        /* Get the text field */
        let copyText = document.getElementById("kode_undangan");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        // alert("Kode berhasil disalin: " + copyText.value);
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Alamat web undangan tersalin!";
    })


    return (
        <>
            <Helmet>
                <title>Testimonials - Premium Wedding Invitation</title>
                <meta name="description" content="Read what our happy couples say!" />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />

            <div className="preloader-visible" data-barba="wrapper">
                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        {(!showCompleteForm) ? null :
                            <>
                                <Navbar showLogo={true} showMenu={true} />

                            </>
                        }
                        <section className="layout-pt-xl layout-pb-lg">
                            <div className="container">
                                {(testimoniFormFilled) ?
                                    <>
                                        {(showCompleteForm) ?
                                            <>
                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-6">
                                                        <div className="sectionHeading -md">
                                                            <p className="sectionHeading__subtitle">Selesai</p>
                                                            <h2 className="sectionHeading__title"><span className="text-accent">Terima</span> Kasih</h2>
                                                            <p className="text-lg sm:text-base mt-24">Informasi yang sudah dikirimkan sangat berharga bagi kami 😊 Sampaikan salam kami pada pasanganmu, ya! Semoga pernikahan kalian langgeng dan selalu dipenuhi kebahagiaan yang berlimpah <span className="text-accent">❤</span></p>
                                                            <br />&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-12">
                                                        <div className="teamCard__social mt-12 justify-content-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className="row justify-content-center text-center">
                                                    <div className="col-lg-6">
                                                        <div className="sectionHeading -md">
                                                            <p className="sectionHeading__subtitle">Langkah 3/3</p>
                                                            <h2 className="sectionHeading__title"><span className="text-accent">Selamat!</span> <br />Undangan Sudah Aktif</h2>
                                                            <p className="text-lg sm:text-base mt-24">Gunakan alamat web di bawah ini untuk membagikan undangan pernikahanmu. Jangan lupa screenshot dan simpan alamat web undangan pernikahan yang sudah dibuat. Jika lupa alamat web undangan, silakan hubungi kami atau kamu bisa buat undanganmu kembali.</p>
                                                            <br />&nbsp;
                                                            <h2 className="sectionHeading__title">Alamat Undangan:</h2>
                                                            <br />&nbsp;
                                                            <input type="text" value={String(props.url).toLowerCase()} className="uniqueCodeInput invitation-address" id="kode_undangan" readOnly rows="3"></input>
                                                            <br />&nbsp;
                                                            <br />&nbsp;
                                                            <br />&nbsp;
                                                            <div className="teamCard__social mt-12 justify-content-center">
                                                                <div className="teamCard__social__item">
                                                                    <div class="tooltip"><button className="button -sm -outline-black text-black" onClick={copyCode}><i className="fa fa-copy"></i> &nbsp; Salin Alamat <span class="tooltiptext" id="myTooltip">Salin Alamat Web</span></button></div>
                                                                </div>
                                                                <div className="teamCard__social__item">
                                                                    <div><a className="button -sm -outline-black text-black" href={String(props.url).toLowerCase()} target="_blank" rel="noreferrer"><i className="fa fa-eye"></i> &nbsp; Buka Undangan</a></div>
                                                                </div>
                                                                <div className="teamCard__social__item">
                                                                    <div><RWebShare
                                                                        data={{
                                                                            text: "Hai, datang ya ke pernikahanku! Lihat di sini ya detail undangannya 😊",
                                                                            url: String(props.url).toLowerCase(),
                                                                            title: "Undangan Pernikahan",
                                                                        }}
                                                                        sites={[
                                                                            "whatsapp",
                                                                            "telegram",
                                                                            "twitter",
                                                                            "facebook",
                                                                        ]}
                                                                    >
                                                                        <button className="button -sm -outline-black text-black"><i className="fa fa-chain"></i> &nbsp; Bagikan</button>
                                                                    </RWebShare></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                    :
                                    (
                                        (testimoniFormIsSending) ?
                                            <div className="row justify-content-center text-center">
                                                <div className="col-lg-6">
                                                    <div className="sectionHeading -md">
                                                        <p className="sectionHeading__subtitle">Please Wait</p>
                                                        <h2 className="sectionHeading__title"><span className="text-accent">Sending</span> Data</h2>
                                                        <p className="text-lg sm:text-base mt-24">Mohon tunggu, kami sedang memproses data yang dikirim.</p>
                                                        <br />&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    (showCompleteForm) ?
                                                        <>
                                                            <div className="row justify-content-center text-center">
                                                                <div className="col-lg-6">
                                                                    <div className="sectionHeading -md">
                                                                        <p className="sectionHeading__subtitle">Lihat</p>
                                                                        <h2 className="sectionHeading__title"><span className="text-accent">Semua</span> Testimonial</h2>
                                                                        <p className="text-lg sm:text-base mt-24">Berikut ini adalah semua testimonial dari pasangan bahagia yang sudah membuat undangan pernikahannya di Vanilatte.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-xl-10 mt-48 sm:mt-32">
                                                                    <div className="col-lg-12">
                                                                        <div className="gb-wrap">
                                                                            {messageList}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />&nbsp;
                                                            <br />&nbsp;
                                                            <br />&nbsp;
                                                            <div className="row justify-content-center text-center">
                                                                <div className="col-lg-6">
                                                                    <div className="sectionHeading -md">
                                                                        <p className="sectionHeading__subtitle">sudah buat tapi</p>
                                                                        <h2 className="sectionHeading__title"><span className="text-accent">Belum</span> Kasih Testimoni?</h2>
                                                                        <p className="text-lg sm:text-base mt-24">Yuk, ceritakan pada semesta tentang kebahagiaan kalian, dan kabarkan pada calon mempelai lain tentang pengalaman kalian membuat undangan di Vanilatte <span className="text-accent">❤</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                        :
                                                        <div className="row justify-content-center text-center">
                                                            <div className="col-lg-6">
                                                                <div className="sectionHeading -md">
                                                                    <p className="sectionHeading__subtitle">Langkah 2/3</p>
                                                                    <h2 className="sectionHeading__title"><span className="text-accent">Tinggal</span> Selangkah Lagi</h2>
                                                                    <p className="text-lg sm:text-base mt-24">Undanganmu sudah siap diakses 😊 Silakan isi testimonial ini untuk mengaktifkan undanganmu.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                <br />&nbsp;
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-10 mt-48 sm:mt-32">
                                                        <div className="contact-form -type-1">
                                                            <form onSubmit={((e) => testimoniSubmitHandler(e))} className="row x-gap-40 y-gap-32 js-ajax-form" data-message-success="Your message has been sent! We will reply you as soon as possible." data-message-error="Something went wrong. Please contact us directly.">
                                                                {
                                                                    (showCompleteForm) ?
                                                                        <>
                                                                            <div className="col-12">
                                                                                <label className="js-input-group">Namamu & Pasangan
                                                                                    <input onChange={((e) => testimoniPayloadHandler("nama", e.target.value))} type="text" name="callsign" required placeholder="Bandung & Roro" autoComplete="off" />
                                                                                    <span className="form__error"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <label className="js-input-group">Alamat Web/Link Undanganmu
                                                                                    <input onChange={((e) => testimoniPayloadHandler("alamat_undangan", e.target.value))} type="text" name="callsign" required placeholder="Misal: https://album.love/wedding/maharani" autoComplete="off" />
                                                                                    <span className="form__error"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <label className="js-input-group">Tanggal Menikah
                                                                                    <input onChange={((e) => testimoniPayloadHandler("tanggal_menikah", e.target.value))} type="date" name="akad-date" defaultValue={moment().add(1, 'days').format("yyyy-MM-DD")} required autoComplete="off" />
                                                                                    <span className="form__error"></span>
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Testimonial
                                                                        <textarea onChange={((e) => testimoniPayloadHandler("testimonial", e.target.value))} name="groom-address" rows="2" required placeholder="Berikan testimonialmu terhadap layanan kami"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Saran & Masukan
                                                                        <textarea onChange={((e) => testimoniPayloadHandler("feedback", e.target.value))} name="groom-address" rows="2" required placeholder="Sampaikan masukan, saran, kritik, maupun komentar untuk kami"></textarea>
                                                                        <span className="form__error"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="col-12">
                                                                    <label className="js-input-group">Bagaimana Penilaianmu Terhadap Layanan Kami?
                                                                    </label>
                                                                    <ul class="rate-now">
                                                                        <li onClick={((e) => testimoniPayloadHandler("rate", "good"))}>
                                                                            <input type="radio" id="good" name="rating" />
                                                                            <label for="good">👍</label>
                                                                        </li>
                                                                        <li onClick={((e) => testimoniPayloadHandler("rate", "bad"))}>
                                                                            <input type="radio" id="bad" name="rating" />
                                                                            <label for="bad">👎</label>
                                                                        </li>

                                                                    </ul>
                                                                    <br />&nbsp;
                                                                    <br />&nbsp;
                                                                </div>

                                                                <div className="col-12 ajax-form-alert js-ajax-form-alert">
                                                                    <div className="ajax-form-alert__content">
                                                                    </div>
                                                                </div>

                                                                <br />&nbsp;

                                                                <div className="col-12">
                                                                    <button type="submit" className="button -md -black text-white"><i className="fa fa-save"></i> &nbsp; Simpan</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                            </div>
                        </section>
                    </main>
                    {
                        (showCompleteForm) ?
                            <Footer />
                            : null
                    }
                </div>
            </div>
        </>
    )
}

export default Testimonial;
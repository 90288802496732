import Tabletop from 'tabletop';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const sheetId = "1HOhqsqt84WwFCn-mPAFvRz6_vUTiuiaKWR9BRNTf8nI";

const columns = [
    {
        id: 'name',
        label: 'Name',
        // minWidth: 170,
    },
    {
        id: 'wedding_date',
        label: 'Wedding Date',
        // minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('id-ID'),
    },
    {
        id: 'due_date',
        label: 'Due Date',
        // minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('id-ID'),
    },
    {
        id: 'ordered_by',
        label: 'Ordered By',
        // minWidth: 170,
        align: 'center',
        format: (value) => value.toLocaleString('id-ID'),
    },
    {
        id: 'ordered_at',
        label: 'Ordered at',
        // minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('id-ID'),
    },
    {
        id: 'follow_up',
        label: 'Follow Up',
        // minWidth: 170,
        // align: 'right',
        format: (value) => value.toLocaleString('id-ID'),
    },
    {
        id: 'view_invitation',
        label: 'View Invitation',
        // minWidth: 170,
        // align: 'right',
        format: (value) => value.toLocaleString('id-ID'),
    },
];

function createData(name, wedding_date, due_date, ordered_by, ordered_at, follow_up, view_invitation) {
    return { name, wedding_date, due_date, ordered_by, ordered_at, follow_up, view_invitation };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        height: '90vh',
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#ffcccc",
        color: theme.palette.common.black,
        fontWeight: 600
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    body: {
        color: theme.palette.common.black
    }
}))(TableRow);

const msgTxt1 = "Hai kak! \n\nKami dari @vanilatte.wedding \n\nTerima kasih sudah membuat undangan pernikahan gratis di Vanilatte.com :)\n\nBerdasarkan data yang sudah dimasukkan, tanggal pernikahan sudah semakin dekat ya? Kami ikut berbahagia ya kak, semoga acara pernikahannya lancar dan selalu dilimpahi kebahagiaan yang tak terbatas :)\n\nDari undangan pernikahan gratis yang sudah dibuat, kakak masih punya kesempatan lho untuk upgrade undangannya menjadi undangan premium :) Kami akan berikan *DISKON 15%* khusus untuk kakak karena sudah membuat undangan gratis :) \n\nUndangan premium memiliki banyak sekali fitur tambahan yang tidak terdapat di undangan gratis, misalnya: \n\n1. Memberi nama tamu undangan (kepada yth.) \n\n2. Musik \n\n3. Multi sesi dalam 1 undangan (mis: Sesi 1 pukul 10:00 - 11:00, Sesi 2 pukul 11:00 - 12:00, dst.) \n\n4. Foto mempelai \n\n5. Galeri foto dan video\n\n6. RSVP dan Guestbook (agar tamu yang membaca undangan bisa langsung mengirimkan doa dan pesan kepada mempelai langsung lewat undangan) \n\n7. Simpan acara ke Google Calendar (agar tamu undangan dapat notifikasi di Hari-H pernikahan)\n\n8. Pilihan tema yang jauh lebih banyak dan cantik dibanding undangan gratis\n\nDan masih banyak lagi :)\n\nYuk kak, undangan premiumnya mau pilih tema yang mana?\n\nKami tunggu kabar bahagianya ya kak :)"

function Dashboard() {
    const [codeFound, setCodeFound] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [weddingData, setWeddingData] = useState([]);
    const [rows, setRows] = useState([]);

    const classes = useStyles();

    const requestGuestBook = (() => {
        Tabletop.init({
            key: sheetId,
            callback: googleData => {
                googleData.reverse();
                setWeddingData(googleData);
            },
            simpleSheet: true
        })
    })

    useEffect(() => {
        let tempData = [];
        let tempDataSorted = [];
        let newRows = []
        for (let i = 0; i < weddingData.length; i++) {
            let phoneNo = (String(weddingData[i].pemesan_telp).startsWith("08")) ? "628" + String(weddingData[i].pemesan_telp).slice(2) : String(weddingData[i].pemesan_telp)
            tempData.push({
                "nama_panggilan": weddingData[i].nama_panggilan,
                "wedding_date": (weddingData[i].akad_tanggal),
                "due_date": (weddingData[i].akad_tanggal),
                "ordered_by": weddingData[i].pemesan_nama,
                "ordered_at": weddingData[i].tanggal_pembuatan,
                "follow_up": <> <a href={"https://wa.me/send?phone=" + phoneNo + "&text=" + encodeURI(msgTxt1)} target="_blank" rel="noreferrer">Follow Up</a></>,
                "view_invitation": <> <a href={"https://album.love/wedding/" + weddingData[i].kode_unik} target="_blank" rel="noreferrer">View</a></>
            })
        }
        tempDataSorted = tempData.slice().sort((a, b) => new Date(a.wedding_date) - new Date(b.wedding_date));

        for (let i = 0; i < tempDataSorted.length; i++) {
            if (!(moment(tempDataSorted[i].due_date).fromNow()).includes("yang lalu")) {
                newRows.push(createData(
                    tempDataSorted[i].nama_panggilan,
                    moment(tempDataSorted[i].wedding_date).format("Do MMMM YYYY"),
                    moment(tempDataSorted[i].due_date).fromNow(),
                    tempDataSorted[i].ordered_by,
                    moment((tempDataSorted[i].ordered_at).substring(0,10)).fromNow(),
                    tempDataSorted[i].follow_up,
                    tempDataSorted[i].view_invitation,
                ))
            }
        }
        setRows(newRows)
    }, [weddingData])

    useEffect(() => {
        requestGuestBook();
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (key !== "pinyol") window.location.href = "/404";
        else setCodeFound(true);
    })

    return (
        <>
            {
                (codeFound) ?
                    <>
                        <Helmet>
                            <title>🤵👰‍♀️ Dashboard</title>
                            <meta name="description" content="Wedding schedule" />
                        </Helmet>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                    : null
            }
        </>
    )
}

export default Dashboard
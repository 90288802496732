import React from "react";
import { useState } from "react";
import QRCode from "react-qr-code";
import CryptoJS from "crypto-js";

import "./style.css";

function QrCodeGenerator(props) {
    const [code, setCode] = useState("");
    const [fontColor, setFontColor] = useState("")

    useState(() => {
        let encrypted = CryptoJS.AES.encrypt(props.value, 'vanilatte').toString();
        setCode(encrypted);

        if (props.fontColor !== "") {
            setFontColor({"color":props.fontColor})
        }
    })

    return (
        <>
            <div className="qr-wrapper">
                <div className="qr-content">
                    <p style={fontColor}>Scan QR Code berikut ini saat memasuki venue.</p>
                    <br />
                    <div
                        style={{ "backgroundColor": props.bgColor || "#fff" }}
                        className="qr-item"
                    >
                        <QRCode
                            value={code || "Kode gagal dibuat, harap hubungi Vanilatte.com"}
                            size={props.size || 128}
                            bgColor={props.bgColor || "#FFFFFF"}
                            fgColor={props.fgColor || "#000000"}
                            level={props.level || "L"}
                        />
                    </div>
                    <br />&nbsp;
                    <p style={fontColor}>QR Code ini berfungsi sebagai pengganti buku tamu. Dengan menunjukkan QR Code ini, Anda bisa langsung masuk tanpa perlu mengisi buku tamu.</p>
                </div>
            </div>
        </>
    )
}

export default QrCodeGenerator;
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Tracker from "./tracker";

/** Main Pages */
import Home from "./pages/home";
import Home2 from "./pages/home/index2";
import Ask from "./pages/home/ask";
import Catalogue from "./pages/home/catalogue";
import NotFound404 from "./pages/home/404";
import Order from "./pages/home/order";
import Bio from "./pages/home/bio";
import TnC from "./pages/home/tnc";
import NameConverter from "./pages/home/nameConverter";
import Promo from "./pages/home/promo";
import FreeInvitationOrder from "./pages/home/freeInvitationOrder";
import WeddingPage from "./pages/home/weddingPage";
import WeddingPageV2 from "./pages/home/weddingv2";
import Testimonial from "./pages/home/testimonial";
import Scanner from "./pages/scanner";
import LayarSapa from "./pages/scanner/layarSapa";
import QrCodeGenerator from "./pages/scanner/qrCodeGenerator";
import Dashboard from "./pages/home/dashboard";
import CreateInvitation from "./pages/home/createInvitation";
import CreateInvitationWithLimit from "./pages/home/createInvitationWithLimit";
import Redirecttoform from "./pages/home/redirectToForm";
import Voucher from "./pages/home/voucher";
import Lembayung from "./pages/home/lembayung";
import StepFormRegister from "./pages/home/stepFormRegister";

/** Templates */
import Artavika from "./pages/template/artavika";
import Gendhis from "./pages/template/gendhis";
import Nawala from "./pages/template/nawala";
import Dalu from "./pages/template/dalu";
import Chandra from "./pages/template/chandra";
// import Kinanthi from "./pages/template/kinanthi";

/** Clients */
import DianFariz from "./pages/clients/DianFariz";
import PipinYolan from "./pages/clients/PipinYolan";
import WidiFajar from "./pages/clients/WidiFajar";
import VikihHanna from "./pages/clients/VikihHanna";
import ElySuryo from "./pages/clients/ElySuryo";
import YuniRiki from "./pages/clients/YuniRiki";
import LilisAna from "./pages/clients/LilisAna";
import PanjiFarida from "./pages/clients/PanjiFarida";
import IikHamid from "./pages/clients/IikHamid";

function App() {
  return (
    <Router>
      <Switch>
        {/* Vanilatte Pages */}
        <Route path="/" exact component={Tracker(Home)} />
        {/* <Route path="/home2" exact component={Tracker(Home2)} /> */}
        <Route path="/ask" exact component={Tracker(Ask)} />
        {/* <Route path="/catalogue" exact component={Tracker(Catalogue)} /> */}
        <Route path="/bio" exact component={Tracker(Bio)} />
        <Route path="/syarat-ketentuan" exact component={Tracker(TnC)} />
        <Route
          path="/tool/converter"
          exact
          component={Tracker(NameConverter)}
        />
        {/* <Route path="/promo" exact component={Tracker(Promo)} />
        <Route path="/testimonial" exact component={Tracker(Testimonial)} />
        <Route path="/order" exact component={Tracker(Order)} />
        <Route
          path="/undangan-premium"
          exact
          component={Tracker(Redirecttoform)}
        />
        <Route
          path="/undangan-gratis"
          exact
          component={Tracker(Redirecttoform)}
        /> */}
        {/* <Route path="/undangan-premium" exact component={Tracker(Order)} />
        <Route path="/undangan-gratis" exact component={Tracker(FreeInvitationOrder)} /> */}
        <Route path="/wedding/:id" exact component={Tracker(WeddingPage)} />
        <Route path="/i/:id" exact component={Tracker(WeddingPageV2)} />
        {/* <Route path="/dashboard" exact component={Tracker(Dashboard)} /> */}
        <Route
          path="/buat-undangan"
          exact
          component={Tracker(StepFormRegister)}
        />
        {/* <Route path="/limit" exact component={Tracker(CreateInvitationWithLimit)} /> */}
        {/* <Route path="/voucher" exact component={Tracker(Voucher)} />
        <Route path="/paket" exact component={Tracker(Voucher)} />
        <Route path="/lembayung" exact component={Tracker(Lembayung)} /> */}
        {/* <Route path="/xxx" exact component={Tracker(StepFormRegister)} /> */}

        {/* Scanner & QR Code*/}
        {/* <Route path="/scanner/" exact component={Tracker(Scanner)} />
        <Route path="/scanner/:id" exact component={Tracker(Scanner)} />
        <Route path="/qrcode/" exact component={Tracker(QrCodeGenerator)} />
        <Route path="/qrcode/:id" exact component={Tracker(QrCodeGenerator)} />
        <Route path="/layarsapa/" exact component={Tracker(LayarSapa)} />
        <Route path="/layarsapa/:id" exact component={Tracker(LayarSapa)} /> */}

        {/* Templates */}
        <Route path="/template/artavika" exact component={Tracker(Artavika)} />
        <Route path="/template/gendhis" exact component={Tracker(Gendhis)} />
        <Route path="/template/nawala" exact component={Tracker(Nawala)} />
        <Route path="/template/dalu" exact component={Tracker(Dalu)} />
        <Route path="/template/chandra" exact component={Tracker(Chandra)} />

        {/* Clients */}
        <Route
          path="/Dianfarizmengundang"
          exact
          component={Tracker(DianFariz)}
        />
        <Route
          path="/PipinYolanMenikah"
          exact
          component={Tracker(PipinYolan)}
        />
        <Route
          path="/PernikahanWidiDanFajar"
          exact
          component={Tracker(WidiFajar)}
        />
        <Route
          path="/HannaVikihMengundang"
          exact
          component={Tracker(VikihHanna)}
        />
        <Route
          path="/TheWeddingElyAndSuryo"
          exact
          component={Tracker(ElySuryo)}
        />
        <Route
          path="/TheWeddingOfYuniAndRiki"
          exact
          component={Tracker(YuniRiki)}
        />
        <Route
          path="/ToBeMrAndMrsPrasetya"
          exact
          component={Tracker(LilisAna)}
        />
        <Route
          path="/FaridaPanjiMengundang"
          exact
          component={Tracker(PanjiFarida)}
        />
        <Route path="/IikHamidMengundang" exact component={Tracker(IikHamid)} />

        <Route component={Tracker(NotFound404)} />
      </Switch>
    </Router>
  );
}

export default App;

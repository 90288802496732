function Navbar(props) {
    return (
        <>
            <header className="header -light -sticky-light js-header">
                <div className="header__bar">
                    <div className="header__logo js-header-logo">
                        {(props.showLogo) ?
                            <>
                                <a data-barba href="/">
                                    <img className="header__logo__light js-lazy" src="/assets/home/img/logo/logo-new.png" alt="Logo" />
                                </a>
                                <a data-barba href="/">
                                    <img className="header__logo__dark js-lazy" src="/assets/home/img/logo/logo-new.png" alt="Logo" />
                                </a>
                            </>
                            : null
                        }
                    </div>

                    {(props.showMenu) ?
                        <>

                            <div className="header__menu js-header-menu">
                                <button type="button" className="nav-button-open js-nav-open">
                                    <i className="icon" data-feather="menu"></i>
                                </button>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                <nav className="nav js-nav">
                    <div className="nav__inner js-nav-inner">
                        <div className="nav__bg js-nav-bg"></div>

                        <div className="nav__container">
                            <div className="nav__header">
                                <button type="button" className="nav-button-back js-nav-back">
                                    <i className="icon" data-feather="arrow-left-circle"></i>
                                </button>

                                <button type="button" className="nav-btn-close js-nav-close pointer-events-none">
                                    <i className="icon" data-feather="x"></i>
                                </button>
                            </div>

                            <div className="nav__content">
                                <div className="nav__content__left">
                                    <div className="navList__wrap">
                                        <ul className="navList js-navList">
                                            <li><a href="/">Halaman Depan</a></li>
                                            <li><a href="/buat-undangan">Buat Undangan Pernikahan</a></li>
                                            {/* <li><a href="/paket">Pilih Paket Undangan</a></li> */}
                                            {/* <li><a href="/testimonial">Testimonial</a></li> */}
                                            {/* <li><a href="/catalogue">Catalogue & Pricelist</a></li> */}
                                            {/* <li><a href="/promo">Promo & Discount</a></li> */}
                                            {/* <li><a href="/terms-conditions">Terms & Conditions</a></li> */}

                                        </ul>
                                    </div>
                                </div>

                                <div className="nav__content__right">
                                    <div className="nav__info">
                                        <div className="nav__info__item js-navInfo-item">
                                            <h5 className="text-sm tracking-none fw-500">
                                                Tentang Kami
                                            </h5>
                                            <div className="nav__info__content text-lg text-white mt-16">
                                                <p>Buat undangan pernikahan hanya dalam waktu satu menit. Dapatkan uji coba secara gratis!</p>
                                            </div>
                                        </div>

                                        <div className="nav__info__item js-navInfo-item">
                                            <h5 className="text-sm tracking-none fw-500">
                                                Hubungi Kami
                                            </h5>

                                            <div className="nav__info__content text-lg text-white mt-16">
                                                <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer">@vanilatte.wedding</a>
                                                <a href="/ask" target="_blank" rel="noreferrer">+62 813-2514-0323</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar;
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';

import Audio from "../../components/audio";
import CovidModal from "../../components/covidModal";
import { convertTime, formatCounter, prayer } from "../../util";
import Instagram from "../../components/instagram";


function Dalu(props) {
    const [showCover, setShowCover] = useState(true);
    const [showMempelai, setShowMempelai] = useState(false);
    const [showUndangan, setShowUndangan] = useState(false);
    const [showLokasi, setShowLokasi] = useState(false);
    const [showLoveStory, setShowLoveStory] = useState(false);
    const [showGaleri, setShowGaleri] = useState(false);
    const [showGuestbook, setShowGuestbook] = useState(false);

    const [guestName, setGuestName] = useState("")


    useState(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let kepada = params.get('kepada');
        if (kepada != null) setGuestName(kepada);

    }, [])

    // useEffect(() => {
    //     console.log(props);
    // }, [])


    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="assets/template/dalu/assets/css/main.css" />
                {/* <script src="assets/clients/dianfariz/assets/js/jquery.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/browser.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/breakpoints.min.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/util.js"></script> */}
                {/* <script src="assets/clients/dianfariz/assets/js/main.js"></script> */}

                {/* Global Resources */}
                {/* <link rel="stylesheet" href="/assets/global/yt-no-repeat.css" /> */}
                {/* <link rel="stylesheet" href="/assets/global/cursor.css" /> */}
                {/* <link rel="stylesheet" href="/assets/global/audio.css" /> */}
                {/* <script src="/assets/clients/dianfariz/assets/js/countdown.js" date="2021-6-6 09:00"></script> */}
                {/* <script src="/assets/global/yt-no-repeat.js"></script> */}
                {/* <script src="/assets/global/cursor.js"></script> */}
                {/* <link href="/assets/global/header-cta.css" rel="stylesheet" /> */}
                {/* <script src="/assets/global/disabler.js"></script> */}
                <link rel="stylesheet" href="/assets/global/fa.css" />
                <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css" />
                <link rel="stylesheet" href="/assets/global/wedding/countdown.css" />
                <script src="/assets/global/wedding/countdown.js" date={formatCounter(props.weddingData['16']) + "__" + formatCounter(props.weddingData['17'])}></script>
                {/* <script src="//www.instagram.com/embed.js"></script> */}
            </Helmet>
            {/* <Audio music="George_Benson_-_Nothing's_Gonna_Change_My_Love_For_You.mp3" /> */}
            {
                (props.weddingData['33'].toLowerCase() != 'active') ?
                    <CovidModal nama={props.weddingData['1']} />
                    : null
            }
            <Audio music="Yiruma, (이루마) - River Flows in You.mp3" />
            <div id="wrapper">
                {
                    // (showCover) ?
                    <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                        <header id="header">
                            <div className="logo">
                                <span className="icon fa-heart"></span>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>The Wedding Of</p>
                                    <h1>{props.weddingData['1']}</h1>
                                    {
                                        (guestName) ?
                                            <div>
                                                <p id="text11" style={{ marginTop: '3em', fontSize: '1em' }}>Kepada Yth.</p>
                                                <p id="text11" style={{ marginTop: '-2em', fontSize: '1.2em' }}>{guestName}</p>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Mempelai</button></li>
                                    <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Acara</button></li>
                                    {/* <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Hapus</button></li> */}
                                    {
                                        (props.weddingData['27'] != '-') ?
                                            <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Galeri</button></li>
                                            : null
                                    }
                                    {
                                        (props.weddingData['32'] != '-') ?
                                            <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Bingkisan</button></li>
                                            : null
                                    }
                                    {
                                        (props.weddingData['30'] != '-' || (props.weddingData['31']) != '-') ?
                                            <li><button className="btn-no-border" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Ucapan & Doa</button></li>
                                            : null
                                    }
                                </ul>
                            </nav>
                        </header>
                    </Fade>
                    // : null
                }

                <div id="main">
                    {
                        // (showMempelai) ?
                        <Fade in={showMempelai} mountOnEnter unmountOnExit {...(showMempelai ? { timeout: 1500 } : {})}>
                            <article id="mempelai">
                                {/* <span className="image main"><img src="assets/global/img/home-img.jpg" alt="" /></span> */}
                                <div className="text-center">
                                    <h2 className="major" style={{ marginLeft: 'auto', marginRight: 'auto' }}>Kami yang Berbahagia</h2>

                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">{props.weddingData['5']}</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putri {props.weddingData['6'].toLowerCase()} dari<br /><strong>{props.weddingData['7']} & {props.weddingData['8']}</strong> <br /><em>{props.weddingData['9']}</em></p>
                                    <p><small className="dengan">Dengan</small></p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <h2 className="no-spacing">{props.weddingData['10']}</h2>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <p className="animate-this">Putra {props.weddingData['11'].toLowerCase()} dari<br /><strong>{props.weddingData['12']} & {props.weddingData['13']}</strong> <br /><em>{props.weddingData['14']}</em></p>
                                    {
                                        (props.weddingData['4'] !== "Tidak Memakai Do'a/Ayat") ?
                                            <>
                                                <hr />
                                                <p className="prayer">{prayer[props.weddingData['4']]}</p>
                                            </>
                                            :
                                            null
                                    }
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowMempelai(!showMempelai) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showUndangan) ?

                        <Fade in={showUndangan} mountOnEnter unmountOnExit {...(showUndangan ? { timeout: 1500 } : {})}>
                            <article id="undangan">
                                {/* <span className="image main"><img src="assets/global/img/gallery-pic-8.jpg" alt="" /></span> */}
                                <div className="text-center">
                                    <h2 className="major" style={{ marginLeft: 'auto', marginRight: 'auto' }}>Turut Mengundang</h2>
                                    <p   >Dengan menyebut nama Tuhan Yang Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i pada pernikahan kami yang akan diselenggarakan pada:</p>
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>{props.weddingData['15']}</h1>
                                    <h3 className="">{convertTime(props.weddingData['16'])}</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">{convertTime(props.weddingData['17'])} - {convertTime(props.weddingData['18'])}</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">{props.weddingData['19']}</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    {
                                        ((props.weddingData['20']).includes("https://") || (props.weddingData['20']).includes("http://")) ?
                                            <a href={props.weddingData['20']} target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Lokasi Acara</a>
                                            : null
                                    }
                                    <hr />
                                    <br /> &nbsp;
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider divider-flip" />
                                    <br /> &nbsp;
                                    <h1>{props.weddingData['21']}</h1>
                                    <h3 className="">{convertTime(props.weddingData['22'])}</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h3 className="">{convertTime(props.weddingData['23'])} - {convertTime(props.weddingData['24'])}</h3>
                                    <br />
                                    <p className=""><img src="assets/clients/dianfariz/images/grass.png" className="event-separator" alt="Separator" /></p>
                                    <h4 className="">{props.weddingData['25']}</h4>
                                    <br />
                                    <img src="assets/clients/dianfariz/images/divider.png" alt="divider" className="divider " />
                                    {/* <hr/> */}
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    {
                                        ((props.weddingData['26']).includes("https://") || (props.weddingData['26']).includes("http://")) ?
                                            <a href={props.weddingData['26']} target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Lokasi Acara</a>
                                            : null
                                    }
                                    <hr />
                                    <p className="prayer">Tanpa mengurangi rasa hormat sedikit pun, acara pernikahan akan dilakukan dengan mengikuti protokol kesehatan sesuai anjuran pemerintah.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowUndangan(!showUndangan) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showLokasi) ?
                        <Fade in={showLokasi} mountOnEnter unmountOnExit {...(showLokasi ? { timeout: 1500 } : {})}>

                            <article id="lokasi">
                                <h2 className="major">Petunjuk Arah</h2>
                                {/* <div className="text-center">
                                    <p   >Silakan gunakan peta di bawah ini untuk mencari rute terdekat ke lokasi acara kami</p>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.355933734317!2d110.48927871477791!3d-7.752020594412814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5ae3dbd859d1%3A0x19e7a03b25955a2d!2sPrambanan%20Temple!5e0!3m2!1sen!2sid!4v1616122382843!5m2!1sen!2sid" allowFullScreen="" title="Petunjuk Arah" loading="lazy" className="peta-lokasi"></iframe>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <a href="https://www.google.com/maps/place/Prambanan+Temple/@-7.7520206,110.4892787,17z/data=!3m1!4b1!4m5!3m4!1s0x2e7a5ae3dbd859d1:0x19e7a03b25955a2d!8m2!3d-7.7520206!4d110.4914674" target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-map-marker" ></i> &nbsp; Cek Petunjuk Arah</a>
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </div> */}
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLokasi(!showLokasi) }}>Close</div>
                            </article>
                        </Fade>

                        // : null
                    }

                    {
                        // (showGuestbook) ?
                        <Fade in={showGuestbook} mountOnEnter unmountOnExit {...(showGuestbook ? { timeout: 1500 } : {})}>

                            <article id="guestbook">
                                <h2 className="major" style={{ marginLeft: 'auto', marginRight: 'auto' }}>Doa & Ucapan</h2>
                                {/* <span className="image main"><img src="assets/global/img/gallery-pic-8.jpg" alt="" /></span> */}
                                <div className="text-center">
                                    <p id="text14">Sampaikan ucapan & doa langsung kepada kami dengan klik tombol di bawah ini.</p>
                                    {
                                        (props.weddingData['30']) ?
                                            <div style={{ marginTop: '20px' }}>
                                                <a href={'https://wa.me/' + '+62' + props.weddingData['30'].slice(1)} target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-whatsapp"></i> &nbsp; Mempelai wanita</a>
                                            </div>
                                            : null
                                    }
                                    {
                                        (props.weddingData['31']) ?
                                            <div style={{ marginTop: '20px' }}>
                                                <a href={'https://wa.me/' + '+62' + props.weddingData['31'].slice(1)} target="_blank" rel="noreferrer" className="button primary"><i className="fa fa-whatsapp"></i> &nbsp; Mempelai pria</a>
                                            </div>
                                            : null
                                    }
                                    <br />&nbsp;
                                    {/* <div className="gb-wrap">
                                    </div> */}
                                </div>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGuestbook(!showGuestbook) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }

                    {
                        // (showGaleri) ?
                        <Fade in={showGaleri} mountOnEnter unmountOnExit {...(showGaleri ? { timeout: 1500 } : {})}>
                            <article id="galeri">
                                <div className="text-center">
                                    <h2 className="major" style={{ marginLeft: 'auto', marginRight: 'auto' }}>Galeri</h2>
                                    <p>Serangkaian perjalanan hidup yang telah membawa kami pada momen bahagia ini.</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
                                        <Instagram link={props.weddingData['27'].replace('utm_source=ig_web_copy_link', 'utm_source=ig_embed')} />
                                    </div>
                                    <hr />
                                    <p className="prayer text-center">You are every reason, every hope and every dream I’ve ever had.</p>
                                    <br />&nbsp;
                                </div>
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowGaleri(!showGaleri) }}>Close</div>
                            </article>
                        </Fade>
                        // {/* : null */}
                    }

                    {
                        // (showLoveStory) ?
                        <Fade in={showLoveStory} mountOnEnter unmountOnExit {...(showLoveStory ? { timeout: 1500 } : {})}>
                            <article id="love">
                                <h2 className="major" style={{ marginLeft: 'auto', marginRight: 'auto' }}>Bingkisan</h2>
                                <p>Bagi kerabat & saudara yang ingin memberikan bingkisan kepada kami, bisa dikirimkan melalui:</p>

                                <p style={{ whiteSpace: "pre-wrap", marginTop: '2em' }}>{props.weddingData['32']}</p>
                                <br />&nbsp;
                                <div className="close" onClick={() => { setShowCover(!showCover); setShowLoveStory(!showLoveStory) }}>Close</div>
                            </article>
                        </Fade>
                        // : null
                    }


                    {
                        // (showCover) ?
                        <Fade in={showCover} mountOnEnter unmountOnExit {...(showCover ? { timeout: 1500 } : {})}>
                            <footer id="footer">
                                <p className="copyright">Buat Undangan Nikah Hanya 1 Menit di <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer">Vanilatte Wedding</a></p>
                            </footer>
                        </Fade>
                        // : null
                    }
                </div>

            </div>

            <div id="bg"></div>
        </>
    )
}

export default Dalu;
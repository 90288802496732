
function Footer() {
    return (
        <>

            <footer className="footer -type-1 bg-dark-1">
                <div className="container">

                    <div className="footer__top">
                        <div className="row y-gap-48 justify-content-between">

                            <div className="col-lg-3 col-sm-6">
                                <h4 className="text-xl fw-500 text-white">Tentang Kami</h4>
                                <div className="footer__content text-base text-light mt-16 sm:mt-12">
                                    <p>Buat undangan pernikahan hanya dalam waktu satu menit. Dapatkan uji coba secara gratis!</p>
                                    <br />&nbsp;
                                    <a href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer" className="button -underline">@vanilatte.wedding</a>
                                    <br />
                                    <a href="/ask" target="_blank" rel="noreferrer" className="button -underline">+62 813-2514-0323</a>
                                </div>
                            </div>

                            <div className="col-lg-auto col-sm-4">
                                <h4 className="text-xl fw-500 text-white">Tautan</h4>
                                <div className="footer__content text-base text-light mt-16 sm:mt-12">
                                    <div><a data-barba href="/" className="button -underline">Halaman Depan</a></div>
                                    <div><a data-barba href="/buat-undangan" className="button -underline">Buat Undangan Pernikahan</a></div>
                                    {/* <div><a data-barba href="/paket" className="button -underline">Pilih Paket Undangan</a></div> */}
                                    {/* <div><a data-barba href="/testimonial" className="button -underline">Testimonial</a></div> */}
                                    {/* <div><a data-barba href="/catalogue" className="button -underline">Catalogue & Pricelist</a></div> */}
                                    {/* <div><a data-barba href="/promo" className="button -underline">Promo & Discount</a></div> */}
                                    <div><a data-barba href="/syarat-ketentuan" className="button -underline">Syarat &amp; Ketentuan</a></div>
                                </div>
                            </div>

                            <div className="col-lg-auto col-auto">
                                <h4 className="text-xl fw-500 text-white">Ikuti Kami</h4>
                                <div className="social -bordered mt-16 sm:mt-12">
                                    <a className="social__item text-white border-light" href="https://instagram.com/vanilatte.wedding" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                                    <a className="social__item text-white border-light" href="/ask" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="footer__bottom -light">
                        <div className="row">
                            <div className="col">
                                <div className="footer__copyright">
                                    <p className="text-light">Copyrights &copy; Vanilatte {new Date().getFullYear()} &mdash; All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;
import { useEffect } from "react";
import Navbar from "./navbar";
import { Helmet } from "react-helmet";
import HelmetTemplate from "./helmet";
import DynamicScrollToTop from "../../util/DynamicScrollToTop";

function Contact() {

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let template = params.get('template');
        let text = params.get("text");

        let targetURL = "https://wa.me/send?phone=6281325140323&text="
        let textMessage = (template != null) ? "Hai, Vanilatte!\n\nSaya sudah melihat desain undangan *" + template + "* dan saat ini tertarik untuk menggunakannya." :
            (
                (text !== null) ?
                    text :
                    "Hai, Vanilatte!\n\nSaya ingin bertanya lebih lanjut tentang layanan undangan digital dari Vanilatte."
            );
        window.location.href = encodeURI(targetURL + textMessage);
    })
    return (
        <>
            <Helmet>
                <title>Hubungi Kami</title>
                <meta property="og:site_name" content="Vanilatte" />
                <meta property="og:title" content="Hubungi Kami" />
                <meta
                    property="og:description"
                    content="Menghubungkan ke WhatsApp..."
                />
                <meta
                    property="og:image"
                    itemprop="image"
                    content="https://vanilatte-og-image.vercel.app/api/web?text=Hubungi+Kami"
                />
            </Helmet>
            <HelmetTemplate />
            <DynamicScrollToTop />
            <div className="preloader-visible" data-barba="wrapper">


                <div className="preloader js-preloader">
                    <div className="preloader__bg"></div>

                    <div className="preloader__progress">
                        <div className="preloader__progress__inner"></div>
                    </div>
                </div>


                <div className="cursor js-cursor">
                    <div className="cursor__wrapper">
                        <div className="cursor__follower js-follower"></div>
                        <div className="cursor__label js-label"></div>
                        <div className="cursor__icon js-icon"></div>
                    </div>
                </div>


                <div className="barba-container" data-barba="container">

                    <div data-cursor className="backButton js-backButton">
                        <span className="backButton__bg"></span>
                        <div className="backButton__icon__wrap">
                            <i className="backButton__button js-top-button" data-feather="arrow-up"></i>
                        </div>
                    </div>


                    <main className="">
                        <Navbar showLogo={false} />

                        <section className="page-404">
                            <div className="page-404-bg"><i className="fa fa-whatsapp"></i></div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 offset-xl-1 col-md-8">
                                        <div className="page-404-content">
                                            <h1 className="page-404-title"><i className="fa fa-whatsapp"></i> &nbsp; Hai, kak 😊</h1>
                                            <p className="page-404-text leading-4xl mt-24 md:mt-16">Kami sedang menyambungkan kakak ke WhatsApp kami 😊 Mohon ditunggu sebentar ya...</p>
                                            <p>If nothing happened, please <a href="https://wa.me/send?phone=6281325140323&text=Hai, Vanilatte! Saya ingin bertanya lebih lanjut tentang layanan undangan digital dari Vanilatte."><strong>click here</strong></a>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </main>

                </div>
            </div>
        </>
    )
}

export default Contact;